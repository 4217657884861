import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Axios from "axios";
import moment from "moment";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import FCLabel from "../../../theme/FCLabel";
import { FormControlLabel, Switch } from "@material-ui/core";
import { toast } from "react-toastify";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import { flushSync } from "react-dom";

import Loader from "../../../assets/images/loader.gif";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Unbooked = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  const [deleteId, setDeleteId] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [filterExpanded, setFilterExpanded] = useState(false);
  const [start, setStart] = useState(moment().subtract(30, "days").format("YYYY-MM-DD"));
  const [end, setEnd] = useState(moment().format("YYYY-MM-DD"));

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    total_page: null,
    page: 1,
    onChange: (page) => getOpenedReceipts(page)
  });
  const [data, setData] = useState([]);

  const getOpenedReceipts = async (page, customer) => {
    const urlPage = parseInt(
      new URLSearchParams(location.search).get("page")
    );
    setLoading(true);
    await Axios.get(
      `${context.api_endpoint}/company/appointments/transaction`,
      filterExpanded
        ? {
            params: {
              page: page ? page : urlPage ? urlPage : 1,
              ...{
                start: moment(start).format("YYYY-MM-DD"),
                end: moment(end).format("YYYY-MM-DD"),
                isbetween: true,
                customerid: customer ? customer.id : null,
              },
            },
          }
        : {
            params: {
              page: page ? page : urlPage ? urlPage : 1,
            },
          }
    )
      .then((response) => {
        const { data } = response;
        setLoading(false);
        setLoaded(true);
        setData([
          ...data.data.map((record) => {
            return {
              ...record,
              process_date: moment(record.process_date).format("DD/MM/YYYY"),
              custname: record.receipt.customer.full_name,
              info: record.receipt.info ? record.receipt.info : "",
              created_at: moment(record.created_at)
                .local()
                .format("DD/MM/YYYY HH:mm"),
            };
          })
        ]);
        setPagination({
          ...pagination,
          page: pagination.page,
          total_page: data.data.length
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getCustomers = async (key) => {
    await Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    }).then(({ data }) => {
      setCustomers([...data.data.records])
    });

    return customers;
  };

  useEffect(() => {
    getOpenedReceipts();
    // getCustomers();

    const unlisten = history.listen((location, action) => {
      pagination.page !== null &&
        action === "POP" &&
        getOpenedReceipts();
    });

    return () => unlisten();
  }, []);

    return (
      <AuthContainer
        makePlanUpgrade={[2]}
        authorities={[0, 1]}
        limited_permission="sales"
      >
        <Grid className="mb-2">
          <Column className={`xs-12 sm-12`}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={filterExpanded}
                  onChange={async (e) => {
                    setLoaded(false);
                    setFilterExpanded(e.target.checked);
                    await getOpenedReceipts();
                  }}
                />
              }
              label={
                <FCLabel>
                  <i className="material-icons">filter_list</i>{" "}
                  <span>{context.t(`receipts.filter.title`)}</span>
                </FCLabel>
              }
            />
          </Column>
          {filterExpanded && (
            <>
              <Column className="xs-12 sm-12 md-12 lg-4">
                <DatePickerInput
                  className="mb-0"
                  label={context.t(`receipts.filter.startInputLabel`)}
                  value={new Date(moment(start).toISOString())}
                  maxDate={end}
                  onChange={async (start2) => {
                    setLoaded(false);
                    setStart(start2);
                    await getOpenedReceipts();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-4">
                <DatePickerInput
                  className="mb-0"
                  label={context.t(`receipts.filter.endInputLabel`)}
                  value={end}
                  minDate={new Date(moment(start).toISOString())}
                  onChange={async (end2) => {
                    setLoaded(false);
                    setEnd(end2);
                    await getOpenedReceipts();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-4">
                <AutocompleteInput
                  label={context.t(
                    `[receipts/add].searchCustomerInputLabel`
                  )}
                  labelKey="full_name"
                  valueKey="id"
                  returnFullObject
                  onRemove={() => {
                    setSelectedCustomer(null);
                    getOpenedReceipts();
                  }}
                  selected={selectedCustomer || null}
                  selectedHandler={(selected_customer) => {
                    flushSync(() => {
                      setSelectedCustomer(selected_customer);
                    });
                    getOpenedReceipts(pagination.page, selected_customer);
                  }}
                  asyncDataService={async (keyword) =>
                    await getCustomers(keyword)
                  }
                />
              </Column>
            </>
          )}
        </Grid>

        <Grid>
          {loading ? (
            <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img src={Loader} width="80" height="80" alt="loading" />
            <>Paketler yükleniyor. Lütfen bekleyiniz...</>
          </div>
          ) : (
            <Column className="xs-12 sm-12">
              <Table
                headings={{
                  id: {
                    label: context.t(`receipts.headings.id`),
                    style: { width: 60 },
                  },
                  created_at: {
                    label: context.t(`receipts.headings.createdAt`),
                    style: { width: 200 },
                    sortable: {
                      0: [{ field: "`receipts`.`created_at`", order: "ASC" }],
                      1: [{ field: "`receipts`.`created_at`", order: "DESC" }],
                    },
                  },
                  process_date: {
                    label: context.t(`receipts.headings.processDate`),
                    style: { width: 200 },
                  },
                  custname: {
                    label: context.t(`receipts.headings.customerFullName`),
                    // with_photo: "customer.profile_photo",
                    style: { width: 300 },
                    limited_line: 1,
                    sortable: {
                      0: [
                        { field: "`customers`.`name`", order: "ASC" },
                        { field: "`customers`.`surname`", order: "ASC" },
                      ],
                      1: [
                        { field: "`customers`.`name`", order: "DESC" },
                        { field: "`customers`.`surname`", order: "DESC" },
                      ],
                    },
                  },
                  info: {
                    label: context.t(`receipts.headings.info`),
                    style: { width: 400 },
                    limited_line: 2,
                  },
                  _: { label: context.t(`component.actionHeadingText`) },
                }}
                rows={data}
                loaded={loaded}
                replacements={{
                  custname: {
                    null: context.t(
                      `receipts.replacements.undefinedCustomer`
                    ),
                    "": context.t(`receipts.replacements.undefinedCustomer`),
                  },
                }}
                pagination={pagination}
                buttons={[
                  {
                    title: context.t(`receipts.showButtonTitle`),
                    icon: "launch",
                    textColor: "primary",
                    transitionEffect: true,
                    onClick: (row) => 
                      history.push({ pathname: `/receipts/detail/${row.receipt.id}`, state: { row: row.receipt.id } }),
                  },
                ]}
              />
            </Column>
          )}
        </Grid>
      </AuthContainer>
    );
}

export default Unbooked;
