import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import { Grid, Column } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";
import Select from "../../../theme/CustomMUI/Select";
import Axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";
import Validate from "../../../functions/Validate";
import { InputAdornment } from "@material-ui/core";
import Loader from "../../../assets/images/loader.gif";
import { Group, LocalMall } from "@material-ui/icons";
import RemainingDebtRed from "../../../assets/images/svg/remaining_debt_red.svg";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import { flushSync } from "react-dom";
import CustomerAddDialog from "../../../components/Dialogs/CustomerAddDialog";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import moment from "moment";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import AutoCompleteInputWithoutEndPoint from "../../../theme/CustomMUI/AutoCompleteInputWithoutEndPoint";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

let typingTimeout = null;
const PackageAdd = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [services, setServices] = useState([]);
  const [staffs, setStaffs] = useState([]);

  const [pickCustomerAndPackageSaleDialog, setPickCustomerAndPackageSaleDialog] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerAddModal, setCustomerAddModal] = useState(false);

  const [newPackage, setNewPackage] = useState({
    name: null,
    all_amount: 200,
    services: {}
  });

  const [total, setTotal] = useState(0);
  const [totalLoader, setTotalLoader] = useState(false);

  const [__generatedPackage, set__GeneratedPackage] = useState({});

  useEffect(() => {
    getReqArrays();
  }, []);

  const CustomerDialog = () => {
    useEffect(() => {
      flushSync(() => {
        setCustomerAddModal(customerAddModal);
      });
    }, [customerAddModal]);
    return (
      <CustomerAddDialog
        disabledAdvanedOptions={true}
        open={customerAddModal}
        onClick={(data) => {
          setSelectedCustomer({ ...data });
        }}
        createdCustomerHandler={(data) => {
          setSelectedCustomer({
            ...data,
            full_name: `${data.name} ${data.surname}`,
          });
        }}
        closeHandler={() => {
          setCustomerAddModal(false);
        }}
        setOpen={setCustomerAddModal}
      />
    );
  };

  const searchCustomerByKey = async (key) => {
    let arr = [];
    await Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key },
    })
      .then(({ data }) => (arr = [...data.data.records]))
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
    return arr.map((item) => {
      return {
        id: item.id,
        full_name: `${item.full_name} (*** ${item.phone.slice(6, 11)})`,
      };
    });
  };

  // Hizmet Arama
  const handlerServiceSelect = (_, value) => {
    Object.keys(newPackage.services)
      .filter((key) => newPackage.services[key] !== undefined)
        .map((item, index) => {
          console.log(value);
          fieldChanger("services", item, {
            service_id: value.id,
            amount: value.amount,
          });
          console.log(newPackage.services);
      })
  };

  const getReqArrays = async () => {
    await Axios.get(`${context.api_endpoint}/company/receipt/index`)
      .then(({ data }) => {
        setStaffs([...data.data.staffs]);
        setServices([...data.data.services]);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const addServiceInPackage = () => {
    setNewPackage({
      ...newPackage,
      services: {
        ...newPackage.services,
        [Object.keys(newPackage.services).length + 1]: {
          service_id: null,
          amount: null,
          period: 1,
          period_start: moment(new Date()).format("YYYY-MM-DD"),
        },
      }
    });
  };

  const deleteServiceInPackage = (key) => {
    setNewPackage({
      ...newPackage,
      services: {
        ...newPackage.services,
        [key]: undefined,
      }
    });
  };

  const createPackage = () => {
    validatePackage() &&
      Axios.post(`${context.api_endpoint}/company/packet/insert`, {
        ...newPackage,
        services: undefined,
        all_amount: Math.round(getTotal().amount),
        packet_details: [
          ...Object.keys(newPackage.services)
            .filter((key) => newPackage.services[key] !== undefined)
            .map((item) => Object.assign(newPackage.services[item], {})),
        ],
      })
        .then((response) => {
          if (response.status === 201) {
            toast.success(context.t(`['packages/add'].insertToast`));
            history.push("/packages/list");
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
  };

  const createAndContinuie = () => {
    validatePackage() &&
      Axios.post(`${context.api_endpoint}/company/packet/insert`, {
        ...newPackage,
        services: undefined,
        all_amount: Math.round(getTotal().amount),
        packet_details: [
          ...Object.keys(newPackage.services)
            .filter((key) => newPackage.services[key] !== undefined)
            .map((item) => Object.assign(newPackage.services[item], {})),
        ],
      })
        .then((response) => {
          if (response.status === 201) {
            toast.success(context.t(`['packages/add'].insertToast`));
            history.push({
              pathname: "/packages/sales/add",
              state: {
                packet_id: response.data.data.id,
              },
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
  };
  // Create

  const createPackageSale = () => {
    validatePackage() &&
      Axios.post(`${context.api_endpoint}/company/packet/open/receipt`, {
        customer_id: selectedCustomer.id,
        receipt_id: null,
        services: [
          ...Object.keys(newPackage.services)
            .filter((key) => newPackage.services[key] !== undefined)
            .map((item) =>
              Object.assign({ ...newPackage.services[item] }, {})
            ),
        ],
      })
        .then((response) => {
          const {
            data: { data },
          } = response;

          if (response.status === 201) {
            history.push(`/receipts/detail/${data.id}`);
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
  };

  const PickCustomerAndPackageSaleDialog = () => {
    return (
      <AlertDialog
        containerStyle={{
          overflow: "visible",
        }}
        title={"Paket Satışı Yap"}
        maxWidth="md"
        fullWidth
        className="package-sale-dialog"
        open={pickCustomerAndPackageSaleDialog}
        closeHandler={() => setPickCustomerAndPackageSaleDialog(false)}
      >
        <Grid className="no-gutters-row">
          <Column className="xs-12 sm-12 md-12 lg-9">
            <AutocompleteInput
              onRemove={() => {
                setSelectedCustomer(null);
              }}
              style={{ zIndex: 1000 }}
              label={context.t(
                `['packages/sales/add'].customerInputLabel`
              )}
              placeholder={context.t(
                `['packages/sales/add'].customerInputPlaceholder`
              )}
              labelKey="full_name"
              valueKey="id"
              selected={
                selectedCustomer !== null
                  ? selectedCustomer
                  : ""
              }
              returnFullObject
              selectedHandler={(selected_customer) => setSelectedCustomer(selected_customer)}
              asyncDataService={async (keyword) => searchCustomerByKey(keyword)}
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-3">
            <Button
              icon={<Group />}
              title={context.t(
                `['appointments/add'].createNewCustomerButtonTitle`
              )}
              backgroundColor="primary-opacity"
              textColor="primary"
              fullWidth={true}
              size="lg"
              onClick={() => {
                setCustomerAddModal(true);
              }}
            />
          </Column>
        </Grid>

        {Object.keys(newPackage.services).filter(
          (key) => newPackage.services[key] !== undefined
        ).length > 0 ? (
          Object.keys(newPackage.services)
            .filter((key) => newPackage.services[key] !== undefined)
            .map((item, index) => (
              <Grid key={index} className="no-gutters-row">
                <Column className="xs-12 sm-12 md-12 lg-4">
                  <Select
                    label={context.t(
                      `['packages/sales/add'].staffInputLabel`
                    )}
                    items={[...staffs]}
                    labelKey="full_name"
                    valueKey="id"
                    selected={
                      newPackage.services[item].staff_id ?? null
                    }
                    handler={(staff_id) =>
                      fieldChanger("services", item, {
                        staff_id: parseInt(staff_id),
                      })
                    }
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-4">
                  <DatePickerInput
                    label={context.t(
                      `['packages/sales/add'].startInputLabel`
                    )}
                    error={false}
                    value={moment(
                      newPackage.services[item]?.period_start
                    )}
                    onChange={(period_start) =>
                      fieldChanger("services", item, {
                        period_start: moment(period_start).format("YYYY-MM-DD"),
                      })
                    }
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-4">
                  <Select
                    label={context.t(
                      `['packages/sales/add'].periodBetweenInputLabel`
                    )}
                    items={[...new Array(31).keys()].map((i) => ({
                      value: i === 0 ? -1 : i,
                      label: `${i} Gün`,
                    }))}
                    labelKey="label"
                    valueKey="value"
                    selected={newPackage.services[item].period_between ?? null}
                    handler={(period_between) => {
                      period_between === -1 &&
                        fieldChanger("services", item, {
                          period_between: 0,
                        });
                      period_between !== -1 &&
                        fieldChanger("services", item, {
                          period_between: parseInt(period_between),
                        });
                    }}
                  />
                </Column>
              </Grid>
            ))
        ) : (
          <NoDataContainer>
            <img
              src={require("../../../assets/images/manypixels/click_action.svg")}
              alt="Malesef bulunamadı!"
            />
            <p>{context.t(`['packages/sales/add'].noDataText`)}</p>
          </NoDataContainer>
        )}

        <Grid className="no-gutters-row mb-2 mt-1">
          <Column className="xs-12 sm-12 md-12 lg-auto">
            <Button
              disabled={
                Object.values(newPackage.services)
                  .filter((item) => item !== undefined)
                  .filter((item) => item?.staff_id === undefined).length !==
                  0 ||
                Object.values(newPackage.services)
                  .filter((item) => item !== undefined)
                  .filter((item) => item?.period_start === undefined).length !==
                  0 ||
                Object.values(newPackage.services)
                  .filter((item) => item !== undefined)
                  .filter((item) => item?.period_between === undefined)
                  .length !== 0 ||
                selectedCustomer === null
              }
              icon="check"
              title={context.t(
                `['packages/add'].CompletePackageSaleButtonTitle`
              )}
              backgroundColor="teal-opacity"
              textColor="teal"
              // size="lg"
              fullWidth
              onClick={() => {
                createPackageSale();
              }}
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-auto">
            <Button
              icon="close"
              title={context.t(`['packages/add'].discardButtonTitle`)}
              backgroundColor="grey-opacity"
              textColor="grey"
              // size="lg"
              fullWidth
              onClick={() => {
                setPickCustomerAndPackageSaleDialog(false);
              }}
            />
          </Column>
        </Grid>
      </AlertDialog>
    );
  };

  const validatePackage = () => {
    let undefined_service_count = 0;
    Object.keys(newPackage.services).filter(
      (key) =>
        newPackage.services[key] !== undefined &&
        newPackage.services[key].service_id === null &&
        ++undefined_service_count
    );

    if (undefined_service_count > 0) {
      toast.warning(context.t(`['packages/add'].validateServiceToast`));
      return false;
    }

    return Validate([
      {
        field: "Paket adı",
        value: newPackage.name,
        condition: /\w+/,
      },
    ]);
  };

  const _total = () => {
    let total_amount = Object.keys(newPackage.services)
      .filter((key) => newPackage.services[key] !== undefined)
      .map((key) =>
        newPackage.services[key].amount !== null
          ? newPackage.services[key].amount * newPackage.services[key].period
          : 0
      )
      .reduce((a, b) => a + b)
      .toFixed(2);

    return {
      amount: total_amount,

      getRatioList: () => {
        const SingleRatio = {};
        Object.keys(newPackage.services)
          .filter((key) => newPackage.services[key] !== undefined)
          .map(
            (key) =>
              (SingleRatio[key] =
                newPackage.services[key].amount !== null
                  ? (newPackage.services[key].amount *
                      newPackage.services[key].period) /
                    _total().amount
                  : 0)
          );
        return SingleRatio;
      },
    };
  };

  const editTotal = () => {
    // console.log(_total().getRatioList());

    if (total !== 0 || total !== null) {
      let services = { ...newPackage.services };

      [...Object.keys(_total().getRatioList())].map(
        (key) =>
          (services = {
            ...services,
            [key]: {
              ...newPackage.services[key],
              amount: parseFloat(
                (
                  (total * _total().getRatioList()[key]) /
                  newPackage.services[key].period
                ).toFixed(2)
              ),
            },
          })
      );

      setTotalLoader(false);
      setNewPackage({
        ...newPackage,
        services,
      });
    }
  };

  const getTotal = () => {
    let total_amount = Object.keys(newPackage.services)
      .filter((key) => newPackage.services[key] !== undefined)
      .map((key) =>
        newPackage.services[key].amount !== null
          ? newPackage.services[key].amount * newPackage.services[key].period
          : 0
      )
      .reduce((a, b) => a + b);

    return {
      amount: total_amount,
    };
  };

  const fieldChanger = (section, key, payload) => {
    setNewPackage({
      ...newPackage,
      [section]: {
        ...newPackage[section],
        [key]: {
          ...newPackage[section][key],
          ...payload,
        },
      }
    });
    setTotal(_total().amount);
  };

    return (
      <AuthContainer
        makePlanUpgrade={[2]}
        authorities={[0, 1]}
        limited_permission="sales"
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-9">
            <Input
              style={{
                borderBottom: `${
                  newPackage.name === null ||
                  newPackage.name === ""
                    ? "1px solid #3189ec"
                    : ""
                }`,
              }}
              label={context.t(`['packages/add'].name`)}
              placeholder={context.t(`["packages/add"].givenName`)}
              value={newPackage.name ?? null}
              onChange={(e) => setNewPackage({ ...newPackage, name: e.target.value ?? null })}
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-3">
            <Button
              disabled={
                newPackage.name === null ||
                newPackage.name === ""
              }
              icon={<LocalMall />}
              title={context.t(`['packages/add'].addServiceButtonTitle`)}
              backgroundColor="blue-opacity"
              textColor="blue"
              size="lg"
              fullWidth
              onClick={addServiceInPackage}
            />
          </Column>
        </Grid>
        <Column className="xs-12 sm-12 md-12 lg-12">
          <SectionContainer>
            {Object.keys(newPackage.services).filter(
              (key) => newPackage.services[key] !== undefined
            ).length > 0 ? (
              Object.keys(newPackage.services)
                .filter((key) => newPackage.services[key] !== undefined)
                .map((item, index) => (
                  <Grid key={index} className="no-gutters-row">
                    <Column className="xs-12 sm-12 md-12 lg-6">
                      {/* -Hizmet Arama Eklendi- */}
                      <AutoCompleteInputWithoutEndPoint
                        label={context.t(
                          `['packages/add'].serviceInputLabel`
                        )}
                        items={[...services]}
                        handler={(service) =>
                          fieldChanger("services", item, {
                            service_id: service.id,
                            amount: service.amount,
                          })
                        }
                        handlerPackageSelect={handlerServiceSelect}
                      />
                      {/* -Hizmet Arama Eklendi- */}
                    </Column>
                    <Column className="xs-12 sm-12 md-auto lg-3">
                      <Select
                        label={context.t(
                          `['packages/add'].periodInputLabel`
                        )}
                        items={[...new Array(50).keys()].map((i) => ({
                          value: i + 1,
                        }))}
                        labelKey="value"
                        valueKey="value"
                        selected={newPackage.services[item].period ?? null}
                        handler={(period) =>
                          fieldChanger("services", item, {
                            period: parseInt(period),
                          })
                        }
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-auto lg-3">
                      <Input
                        label={
                          context.t(
                            `['packages/add'].periodAmountInputLabel`
                          ) +
                          (context.state.currency
                            ? " (" + context.state.currency + ")"
                            : "(₺)")
                        }
                        type="number"
                        value={`${Math.round(
                          newPackage.services[item].amount
                        )}`}
                        onChange={(e) =>
                          fieldChanger("services", item, {
                            amount:
                              e.target.value && /[0-9,.]+/.test(e.target.value)
                                ? parseFloat(e.target.value)
                                : 0,
                          })
                        }
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-1">
                      <Button
                        icon="delete_forever"
                        iconSize={20}
                        backgroundColor="red-opacity"
                        textColor="red"
                        size="lg"
                        fullWidth
                        onClick={() => {
                          deleteServiceInPackage(item);
                          setTimeout(() => {
                            if (
                              Object.values(
                                newPackage.services
                              ).filter((item) => item !== undefined).length > 0
                            ) {
                              setTotal(_total().amount);
                            } else {
                              setTotal(0);
                            }
                          }, 250);
                        }}
                      />
                    </Column>
                  </Grid>
                ))
            ) : (
              <NoDataContainer>
                <img
                  src={require("../../../assets/images/manypixels/click_action.svg")}
                  alt="Malesef bulunamadı!"
                />
                <p>{context.t(`['packages/add'].noDataText`)}</p>
              </NoDataContainer>
            )}

            {Object.keys(newPackage.services).filter(
              (key) => newPackage.services[key] !== undefined
            ).length > 0 && (
              <Grid className="no-gutters-row">
                <Column className="xs-12 sm-12 md-12 lg-auto">
                  <CustomerBoxDiv>
                    <img src={RemainingDebtRed} alt="customer_point" />
                    <CustomerPointBox>
                      <Input
                        label={
                          context.t(`['packages/add'].totalInputLabel`) +
                          (context.state.currency
                            ? " (" + context.state.currency + ")"
                            : "(₺)")
                        }
                        // type="number"
                        value={total}
                        onChange={(e) => {
                          setTotal(
                            e.target.value && e.target.value !== "0"
                              ? parseFloat(e.target.value)
                              : 1
                          );
                          setTotalLoader(true);
                        }}
                        InputProps={{
                          style: {
                            fontWeight: "bold",
                          },
                          endAdornment: totalLoader && (
                            <InputAdornment
                              position="end"
                              style={{ marginRight: 0 }}
                            >
                              <img
                                src={Loader}
                                width="100"
                                height="100"
                                alt="loading"
                              />
                            </InputAdornment>
                          ),
                          onKeyUp: (e) => {
                            const value = e.target.value;
                            clearTimeout(typingTimeout);
                            typingTimeout =
                              value.length > 0 &&
                              setTimeout(() => editTotal(), 1250);
                          },
                        }}
                      />
                    </CustomerPointBox>
                  </CustomerBoxDiv>
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-3">
                  <Button
                    className="mt-1"
                    disabled={
                      Object.values(newPackage.services).filter(
                        (item) => item?.service_id === null
                      ).length !== 0 ||
                      newPackage?.name === null ||
                      newPackage?.name === ""
                    }
                    icon={<Group />}
                    title={context.t(
                      `['packages/add'].pickCostumerAndPackageSale`
                    )}
                    backgroundColor="purple-opacity"
                    textColor="purple"
                    // size="lg"
                    fullWidth
                    onClick={() => {
                      createAndContinuie();

                      console.log(
                        "paket id si ile birlikte paket satışları sayfasına gidecek, burada backend in olışturulan packet_id yi göndermesi gerekiyor."
                      );
                    }}
                  />
                </Column>
              </Grid>
            )}

            {Object.keys(newPackage.services).filter(
              (key) => newPackage.services[key] !== undefined
            ).length > 0 && (
              <Grid className="no-gutters-row">
                <Column className="xs-12 sm-12 md-12 lg-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-3">
                  <Button
                    disabled={
                      Object.values(newPackage.services).filter(
                        (item) => item?.service_id === null
                      ).length !== 0 ||
                      newPackage?.name === null ||
                      newPackage?.name === ""
                    }
                    icon="check"
                    title={context.t(
                      `['packages/add'].completeButtonTitle`
                    )}
                    backgroundColor="teal-opacity"
                    textColor="teal"
                    // size="lg"
                    fullWidth
                    onClick={createPackage}
                  />
                </Column>
              </Grid>
            )}
          </SectionContainer>
        </Column>
        <PickCustomerAndPackageSaleDialog />
        <CustomerDialog />
      </AuthContainer>
    );
}

export default PackageAdd;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 150px;
    object-fit: contain;
  }
  p {
    color: #505050;
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.04);
`;

const CustomerBoxDiv = styled.div`
  width: 100%;
  height: 60px;
  padding: 8px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 5px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    margin-top: 7px;
    margin-left: 10px;
  }
`;

const CustomerPointBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;

  h3 {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    color: #aaa;
  }

  span {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;

    b {
      font-size: 13px;
      font-weight: 400;
      color: #aaa;
    }
  }
`;
