import React, { useEffect, useContext, useState } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Axios from "axios";
import moment from "moment";

import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import { toast } from "react-toastify";
import Select from "../../../theme/CustomMUI/Select";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import styled from "styled-components";
import { DateRange } from "@material-ui/icons";
import RemainingDebt from "../../../assets/images/svg/remaining_debt.svg";

import { withStyles } from "@material-ui/styles";
import CardSection from "../../../components/Cards/CardSection";

import Loader from "../../../assets/images/loader.gif";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const styles = (theme) => ({
  root: {
    "& .MuiSelect-select:focus": {
      backgroundColor: "rgba(249, 186, 132, 0.21) !important",
    },
  },
});
const IncomeTransactions = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  const [loaded, setLoaded] = useState(false);
  const [filter, setFilter] = useState({
    start_date: location.state
        ? `${location.state?.detail.start.split("/")[2]}-${
            location.state?.detail.start.split("/")[1]
          }-${location.state?.detail.start.split("/")[0]}`
        : moment().subtract(1, "days").format("YYYY-MM-DD"),
    end_date: location.state
      ? `${location.state?.detail.end.split("/")[2]}-${
          location.state?.detail.end.split("/")[1]
        }-${location.state?.detail.end.split("/")[0]}`
        : moment().format("YYYY-MM-DD"),
    payment_type: null,
  });
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [reportsDate, setReportsDate] = useState({
    between: {
      start: location.state
        ? `${location.state?.detail.start.split("/")[0]}/${
            location.state?.detail.start.split("/")[1]
          }/${location.state?.detail.start.split("/")[2]}`
        : moment().subtract(1, "days").format("DD/MM/YYYY"),
      end: location.state
        ? `${location.state?.detail.end.split("/")[0]}/${
            location.state?.detail.end.split("/")[1]
          }/${location.state?.detail.end.split("/")[2]}`
        : moment().format("DD/MM/YYYY"),
    } 
  });
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [readyToUseFilter, setReadyToUseFilter] = useState(null);
  const [incomeTransactions, setIncomeTransactions] = useState({
    data: [],
    pagination: {
      page: 1,
      total_page: 1,
      onChange: (page) => getIncomeData(page),
    },
  });
  const [filterPicker, setFilterPicker] = useState([
    {
      id: 1,
      label: "Bugün",
      offset: 1,
    },
    {
      id: 2,
      label: "Bu Hafta",
      offset: 3,
    },
    {
      id: 3,
      label: "Bu Ay",
      offset: 4,
    },
  ]);

  const [selectedFilterPicker, setSelectedFilterPicker] = useState(
    location.state
      ? null
      : {
          id: 1,
          label: "Bugün",
          offset: 1,
        }
  );
  const [totalIncome, setTotalIncome] = useState(null);
  const [comingData, setComingData] = useState(location.state ? location.state : null);

  const getIncomeData = (page = 1) => {
    setLoaded(false);
    Axios.get(
      `${context.api_endpoint}/company/accounting/incomes`,
      selectedFilterPicker === null
        ? {
            params: {
              page: page ? page : 1,
              start: moment(filter.start_date).format("YYYY-MM-DD"),
              end: moment(filter.end_date).format("YYYY-MM-DD"),
              customerid: selectedCustomer ? selectedCustomer.id : null,
              paymenttype: filter.payment_type
                ? filter.payment_type !== "null"
                  ? filter.payment_type
                  : null
                : null,
              isbetween: true,
            },
          }
        : {
            params: {
              page: page ? page : 1,
              isbetween: false,
              offset: selectedFilterPicker.offset,
              customerid: selectedCustomer ? selectedCustomer.id : null,
              paymenttype: filter.payment_type
                ? filter.payment_type !== "null"
                  ? filter.payment_type
                  : null
                : null,
            },
          }
    )
      .then(({ data }) => {
        setLoaded(true);
        setReportsDate({
          between: {
            start: moment(data.data.start_time.split("T")[0])
              .local()
              .format("DD/MM/YYYY"),
            end: moment(data.data.end_time.split("T")[0])
              .local()
              .format("DD/MM/YYYY"),
          }
        });
        setIncomeTransactions({
          ...incomeTransactions,
            data: [
              ...data.data.records.map((item) => {
                return {
                  ...item,
                  amount: item.amount.toFixed(2),
                  payment_date: moment(item.payment_date)
                    .tz("Europe/Istanbul")
                    .format("LL"),
                };
              }),
            ],
            // pagination: {
            //   ...incomeTransactions.pagination,
            //   page: data.data.page,
            //   total_page: data.data.records.length,
            // }
        });
        setTotalIncome(data.data.total_income + (context.state.currency ? context.state.currency : "₺"));
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO: search for customer by name
  const getCustomers = (key) => {
    Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    })
      .then(({ data }) => {
        setCustomers([...data.data.records]);
      })
      .catch((e) => {
        if (e.response.data.code === 401) {
          history.push("/wallet");
        }
      });

    return customers;
  };

  const unlisten = history.listen((location, action) => {
    incomeTransactions.page !== null &&
      action === "POP" &&
      getIncomeData();
  });

  useEffect(() => {
    getIncomeData();
    getCustomers();
    unlisten();

    return() => {
      unlisten();
    }
  }, []);

    return (
      <AuthContainer
        makePlanUpgrade={[2]}
        authorities={[1]}
        limited_permission="income_expense"
      >
        <Grid>
          <Column
            className="xs-12 sm-12 md-12 lg-12 mb-1 pb-2"
            style={{ borderBottom: "1px solid #b2b2b2" }}
          >
            <Column
              className="xs-12 sm-12 md-12 lg-6"
              style={{ height: "63px", display: "flex", alignItems: "center" }}
            >
              <ShortButtonContainer>
                <ShortButton
                  onClick={() => {
                    setSelectedFilterPicker({
                      id: 1,
                      label: "Bugün",
                      offset: 1,
                    })
                    getIncomeData();
                  }}
                  style={{
                    backgroundColor: `${
                      selectedFilterPicker?.id === 1
                        ? "rgb(49, 169, 243, 0.14)"
                        : ""
                    }`,
                    border: "1px solid rgb(91, 187, 245, 0.41)",
                    color: "rgb(49, 169, 243)",
                  }}
                >
                  Bugün
                </ShortButton>
                <ShortButton
                  onClick={() => {
                    setSelectedFilterPicker({
                      id: 2,
                      label: "Bu Hafta",
                      offset: 3,
                    });
                    getIncomeData();
                  }}
                  style={{
                    backgroundColor: `${
                      selectedFilterPicker?.id === 2
                        ? "rgb(29, 139, 62, 0.21)"
                        : ""
                    }`,
                    border: "1px solid rgb(58, 154, 87, 0.35)",
                    color: "rgb(29, 139, 62)",
                  }}
                >
                  Bu Hafta
                </ShortButton>
                <ShortButton
                  onClick={() => {
                    setSelectedFilterPicker({
                      id: 3,
                      label: "Bu Ay",
                      offset: 4,
                    });
                    getIncomeData();
                  }}
                  style={{
                    backgroundColor: `${
                      selectedFilterPicker?.id === 3
                        ? "rgb(254, 151, 151, 0.19)"
                        : ""
                    }`,
                    border: "1px solid rgb(185, 178, 178, 0.65)",
                    color: "rgb(254, 111, 111)",
                  }}
                >
                  Bu Ay
                </ShortButton>
              </ShortButtonContainer>
            </Column>
            <Column
              className="xs-12 sm-12 md-12 lg-6"
              style={{ borderLeft: "1px solid #b2b2b2" }}
            >
              <FilterContainer>
                <FilterButton
                  onClick={() => {
                    setFilterModalOpen(true);
                    setSelectedFilterPicker(null);
                    setReadyToUseFilter(1);
                    setFilter({
                      start_date: moment()
                        .local()
                        .subtract(1, "days")
                        .toISOString(),
                      end_date: moment().local().toISOString()
                    });
                    setReportsDate({
                      between: {
                        start: moment()
                          .local()
                          .subtract(1, "days")
                          .format("DD/MM/YYYY"),
                        end: moment().local().format("DD/MM/YYYY"),
                      }
                    });
                  }}
                >
                  {" "}
                  <DateRange />
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "1.2em",
                      paddingLeft: "1vw",
                    }}
                  >
                    {reportsDate.between.start} -{" "}
                    {reportsDate.between.end}
                  </span>
                </FilterButton>

                <FilterButton
                  style={{
                    cursor: "auto",
                    backgroundColor: "rgba(208, 233, 233, 0.65)",
                    color: "#54AEAE",
                    border: "1px solid rgba(208, 233, 233, 0.8)",
                  }}
                >
                  <img
                    src={RemainingDebt}
                    alt="remaining_debt"
                    style={{ marginRight: "10px", color: "red" }}
                  />
                  Toplam Gelir{" "}
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "1.2em",
                      paddingLeft: "1vw",
                    }}
                  >
                    {`${parseInt(totalIncome)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}${
                      context.state.currency
                        ? context.state.currency
                        : "₺"
                    }`}
                  </span>
                </FilterButton>
              </FilterContainer>
            </Column>
          </Column>
        </Grid>

        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12">
            <Column className="xs-12 sm-12 md-12 lg-4">
              <Select
                label={context.t(`accounting.typeInputLabel`)}
                items={[
                  { id: "null", label: context.t(`accounting.typeALL`) },
                  { id: "0", label: context.t(`accounting.typeCASH`) },
                  { id: "1", label: context.t(`accounting.typeCARD`) },
                  { id: "2", label: context.t(`accounting.typeEFT`) },
                ]}
                labelKey="label"
                valueKey="id"
                selected={`${filter.payment_type}`}
                handler={(payment_type) => {
                  setFilter({
                    ...filter,
                    payment_type: payment_type && payment_type
                  });
                  getIncomeData();
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-4">
              <AutocompleteInput
                label={context.t(
                  `[receipts/add].searchCustomerInputLabel`
                )}
                labelKey="full_name"
                valueKey="id"
                returnFullObject
                onRemove={() => {
                  setSelectedCustomer(null);
                  getIncomeData();
                }}
                selected={selectedCustomer || null}
                selectedHandler={(selected_customer) => {
                  setSelectedCustomer(selected_customer);
                  setTimeout(() => {
                    getIncomeData();
                  }, 250);
                }}
                asyncDataService={async (keyword) => getCustomers(keyword)}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-4">
              <ExcelButton
                fullWidth={true}
                route="accounting/incomes"
                size="lg"
                requestData={
                  selectedFilterPicker === null
                    ? {
                        start: moment(filter.start_date).format(
                          "YYYY-MM-DD"
                        ),
                        end: moment(filter.end_date).format(
                          "YYYY-MM-DD"
                        ),
                        customerid: selectedCustomer
                          ? selectedCustomer.id
                          : null,
                        paymenttype: filter.payment_type
                          ? filter.payment_type !== "null"
                            ? filter.payment_type
                            : null
                          : null,
                        isbetween: true,
                        is_excel: true,
                      }
                    : {
                        isbetween: false,
                        customerid: selectedCustomer
                          ? selectedCustomer.id
                          : null,
                        paymenttype: filter.payment_type
                          ? filter.payment_type !== "null"
                            ? filter.payment_type
                            : null
                          : null,
                        offset: selectedFilterPicker.offset,
                        is_excel: true,
                      }
                }
              />
            </Column>
          </Column>
        </Grid>

        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12">
            <CardSection
              title={"Gelir İşlemleri"}
              className="no-gutters-all"
              includesColumn={false}
            >
              {loaded ? (
                <Table
                  loaded={loaded}
                  headings={{
                    payment_date: {
                      label: context.t(
                        `['accounting/income'].headings.createdAt`
                      ),
                      sortable: {
                        0: [
                          {
                            field: "`receipt_payments`.`payment_date`",
                            order: "ASC",
                          },
                        ],
                        1: [
                          {
                            field: "`receipt_payments`.`payment_date`",
                            order: "DESC",
                          },
                        ],
                      },
                    },
                    customer_name: {
                      label: context.t(
                        `['accounting/income'].headings.customerFullName`
                      ),
                      sortable: {
                        0: [
                          { field: "`customers`.`name`", order: "ASC" },
                          { field: "`customers`.`surname`", order: "ASC" },
                        ],
                        1: [
                          { field: "`customers`.`name`", order: "DESC" },
                          { field: "`customers`.`surname`", order: "DESC" },
                        ],
                      },
                    },
                    amount: {
                      label: context.t(
                        `['accounting/income'].headings.amount`
                      ),
                      suffix: context.state.currency
                        ? context.state.currency
                        : "₺",
                      sortable: {
                        0: [
                          {
                            field: "`receipt_payments`.`amount`",
                            order: "ASC",
                          },
                        ],
                        1: [
                          {
                            field: "`receipt_payments`.`amount`",
                            order: "DESC",
                          },
                        ],
                      },
                    },
                    payment_type: {
                      label: context.t(
                        `['accounting/income'].headings.type`
                      ),
                    },
                    _: { label: context.t(`component.actionHeadingText`) },
                  }}
                  rows={incomeTransactions.data}
                  replacements={{
                    payment_type: {
                      0: context.t(`accounting.typeCASH`),
                      1: context.t(`accounting.typeCARD`),
                      2: context.t(`accounting.typeEFT`),
                    },
                  }}
                  pagination={incomeTransactions.pagination}
                  buttons={[
                    {
                      title: context.t(`accounting.showOnReceipt`),
                      icon: "launch",
                      transitionEffect: true,
                      onClick: (row) =>
                        history.push(
                          `/receipts/detail/${row.receipt_id}`
                        ),
                    },
                  ]}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <img src={Loader} width="100" height="100" alt="loading" />
                </div>
              )}
            </CardSection>
          </Column>
        </Grid>

        <AlertDialog
          title={<h3 style={{ textAlign: "center" }}>Zaman Aralığı Seçiniz</h3>}
          disableBackdropClick={false}
          open={filterModalOpen}
          maxWidth="sm"
          closeHandler={() => {
            setFilterModalOpen(false);
          }}
          buttons={[
            {
              title: "Onayla",
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => getIncomeData(),
            },
            {
              title: "İptal",
              icon: "close",
              textColor: "grey",
              onClick: () => {
                setReadyToUseFilter(null);
                setSelectedFilterPicker({
                  id: 1,
                  label: "Bugün",
                  offset: 1,
                });
              },
            },
          ]}
        >
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-12 mt-3 mb-5">
              <Column className="xs-12 sm-12 md-12 lg-6">
                <ShortButtonContainer
                  style={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <ShortButton
                    style={{
                      border: "1px solid rgba(249,186,132, 0.51)",
                      color: "rgb(219,146,92)",
                      backgroundColor: `${
                        readyToUseFilter === 1
                          ? "rgba(249,186,132, 0.21)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      setReadyToUseFilter(1);
                      setFilter({
                        start_date: moment()
                          .local()
                          .subtract(1, "days")
                          .toISOString(),
                        end_date: moment().local().toISOString()
                      });
                      setReportsDate({
                        between: {
                          start: moment()
                            .local()
                            .subtract(1, "days")
                            .format("DD/MM/YYYY"),
                          end: moment().local().format("DD/MM/YYYY"),
                        }
                      });
                    }}
                  >
                    Dün
                  </ShortButton>
                  <ShortButton
                    style={{
                      border: "1px solid rgb(91, 187, 245, 0.41)",
                      color: "rgb(49, 169, 243)",
                      backgroundColor: `${
                        readyToUseFilter === 2
                          ? "rgb(49, 169, 243, 0.14)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      setReadyToUseFilter(2);
                      setFilter({
                        start_date: moment()
                          .local()
                          .subtract(7, "days")
                          .toISOString(),
                        end_date: moment().local().toISOString()
                      });
                      setReportsDate({
                        between: {
                          start: moment()
                            .local()
                            .subtract(7, "days")
                            .format("DD/MM/YYYY"),
                          end: moment().local().format("DD/MM/YYYY"),
                        }
                      });
                    }}
                  >
                    Son 7 gün
                  </ShortButton>
                  <ShortButton
                    style={{
                      border: "1px solid rgb(58, 154, 87, 0.35)",
                      color: "rgb(29, 139, 62)",
                      backgroundColor: `${
                        readyToUseFilter === 3
                          ? "rgb(29, 139, 62, 0.21)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      setReadyToUseFilter(3);
                      setFilter({
                        start_date: moment()
                          .local()
                          .subtract(15, "days")
                          .toISOString(),
                        end_date: moment().local().toISOString()
                      });
                      setReportsDate({
                        between: {
                          start: moment()
                            .local()
                            .subtract(15, "days")
                            .format("DD/MM/YYYY"),
                          end: moment().local().format("DD/MM/YYYY"),
                        }
                      });
                    }}
                  >
                    Son 15 gün
                  </ShortButton>
                  <ShortButton
                    style={{
                      border: "1px solid rgb(185, 178, 178, 0.65)",
                      color: "rgb(254, 111, 111)",
                      backgroundColor: `${
                        readyToUseFilter === 4
                          ? "rgb(254, 151, 151, 0.19)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      setReadyToUseFilter(4);
                      setFilter({
                        start_date: moment()
                          .local()
                          .subtract(30, "days")
                          .toISOString(),
                        end_date: moment().local().toISOString()
                      });
                      setReportsDate({
                        between: {
                          start: moment()
                            .local()
                            .subtract(30, "days")
                            .format("DD/MM/YYYY"),
                          end: moment().local().format("DD/MM/YYYY"),
                        }
                      });
                    }}
                  >
                    Son 30 gün
                  </ShortButton>
                </ShortButtonContainer>
              </Column>

              <Column
                className="xs-12 sm-12 md-12 lg-6"
                style={{ height: "100%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid #b2b2b2",
                    height: "100%",
                  }}
                >
                  <Column className="xs-12 sm-12 md-12 lg-12">
                    <DatePickerInput
                      label={context.t(`reports.startInputLabel`)}
                      value={new Date(filter.start_date)}
                      maxDate={new Date(filter.end_date)}
                      onChange={async (date) => {
                        setReadyToUseFilter(null);
                        setFilter({
                          ...filter,
                          start_date: moment(date).local().toISOString()
                        });
                        setReportsDate({
                          ...reportsDate,
                          between: {
                            ...reportsDate.between,
                            start: moment(date).local().format("DD/MM/YYYY")
                          }
                        });
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-12">
                    <DatePickerInput
                      label={context.t(`reports.endInputLabel`)}
                      value={
                        new Date(
                          String(filter.end_date).split("T")[0]
                        )
                      }
                      minDate={filter.start_date}
                      onChange={async (date) => {
                        setReadyToUseFilter(null);
                        setFilter({
                          ...filter,
                          end_date: moment(date).local().toISOString()
                        });
                        setReportsDate({
                          ...reportsDate,
                          between: {
                            ...reportsDate.between,
                            end: moment(date).local().format("DD/MM/YYYY")
                          }
                        });
                      }}
                    />
                  </Column>
                </div>
              </Column>
            </Column>
          </Grid>
        </AlertDialog>
      </AuthContainer>
    );
  
}

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

const FilterButton = styled.div`
  border-radius: 8px;
  padding: 1vh 2.5vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  margin-bottom: 10px;
  color: rgb(219, 146, 92);
  border: 1px solid rgba(249, 186, 132, 0.51);
  background-color: rgba(249, 186, 132, 0.21);
`;

const ShortButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ShortButton = styled.div`
  border-radius: 8px;
  width: 12vw;
  padding: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
`;

export default withStyles(styles)(IncomeTransactions);
