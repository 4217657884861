import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/store";
import { useHistory, useParams } from "react-router-dom";

import Input from "../../../theme/CustomMUI/Input";
import AuthContainer from "../../../components/AuthContainer";
import AutoComplete from "../../../theme/CustomMUI/AutoComplete";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Select from "../../../theme/CustomMUI/Select";

import { Grid, Column } from "../../../theme/Grid";
import Button from "../../../theme/Button";
import Table from "../../../theme/Table";

import Axios from "axios";
import { toast } from "react-toastify";
import styled from "styled-components";
import moment from "moment";

const DebitDetail = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const params = useParams();

  const [loaded, setLoaded] = useState(false);

  const [current_payment, setCurrentPayment] = useState([]);
  const [selected_current_payment, setSelectedCurrentPayment] = useState(null);
  const [payment_dialog, setPaymentDialog] = useState(false);
  const [payment] = useState([
    {
      label: context.t(`['accounting/debit'].payments.typeCASH`),
      value: "1",
    },
    {
      label: context.t(`['accounting/debit'].payments.typeCARD`),
      value: "2",
    },
    {
      label: context.t(`['accounting/debit'].payments.typeEFT`),
      value: "3",
    },
    {
      label: context.t(`['accounting/debit'].payments.typePOINT`),
      value: "4",
    },
  ]);
  const [selected_payment, setSelectedPayment] = useState(null);

  const [current_payment_dialog, setCurrentPaymentDialog] = useState(false);

  const [form, setForm] = useState({
    custname: null,
    date: new Date(),
    amount: null,
    explanation: null,
    json_id: null,
  });

  const [expense, setExpense] = useState([]);
  const [types_loaded, setTypesLoaded] = useState(false);

  const [payments, setPayments] = useState({
    date: new Date(),
    amount: "",
  });

  //TODO: Detail Expense
  const detailExpense = () => {
    Axios.get(
      `${context.api_endpoint}/company/current/expenses?id=${params.id}`
    )
      .then(({ data }) => {
        setLoaded(true);
        setForm({
          custname: data.data.current_name,
          date: moment(data.data.payment_date).format("YYYY-MM-DD"),
          amount: data.data.all_amount,
          explanation: data.data.note,
          json_id: data.data.json_id,
        });
        setCurrentPayment([
          ...data.data.current_payments.map((paym) => ({
            ...paym,
            date: moment(paym.payment_date).format("LLL"),
            amount: paym.amount,
            payment_type:
              paym.payment_type !== 0
                ? payment.find(
                    (pay) => pay.value === paym.payment_type.toString()
                  ).label
                : "-",
          })),
        ]);
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        }
      });
  };

  //TODO: Update Expense
  const updateExpense = () => {
    Axios.put(`${context.api_endpoint}/company/current/expense`, {
      id: parseInt(params.id),
      amount: parseInt(form.amount),
      note: form.explanation,
      current_company: form.custname ? form.custname : "",
      payment_date: moment(form.date).format("YYYY-MM-DD"),
      json_id: form.json_id ? form.json_id : "",
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(response.data.data);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO: Insert Payment
  const insertPayment = () => {
    Axios.post(`${context.api_endpoint}/company/current/expense/payment`, {
      amount: parseInt(payments.amount),
      description: payments.explanation,
      current_expense_id: parseInt(params.id),
      payment_type: parseInt(selected_payment.value),
    })
      .then((response) => {
        if (response.status === 201) {
          detailExpense();
          toast.success(response.data.data);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO: update Payment
  const updatePayment = (id) => {
    Axios.put(`${context.api_endpoint}/company/current/expense/payment`, {
      id: parseInt(id),
      amount: parseInt(selected_current_payment.amount),
      current_expense_id: parseInt(params.id),
      payment_type: parseInt(selected_current_payment.payment_type),
    })
      .then((response) => {
        if (response.status === 201) {
          setCurrentPaymentDialog(false);
          toast.success(response.data.data);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO: delete Payment
  const deletePayment = (id) => {
    Axios.delete(`${context.api_endpoint}/company/current/expense/payment`, {
      params: {
        id: parseInt(id),
        current_expense_id: parseInt(params.id),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          detailExpense();
          toast.success(response.data.data);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  // TODO: Get Expense Types
  const getExpenseTypes = () => {
    Axios.get(`${context.api_endpoint}/company/expense/categories`)
      .then(async ({ data }) => {
        const types = JSON.parse(data.data.name);
        await setTypesLoaded(true);
        await setExpense([
          ...Object.keys(types)
            .map((key) => {
              if (types[key] !== null && types[key] !== "") {
                return { json_id: key, label: types[key] };
              } else {
                return undefined;
              }
            })
            .filter((item) => item !== undefined),
        ]);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    detailExpense();
    getExpenseTypes();
  }, []);

  return (
    <AuthContainer
      authorities={[1]}
      makePlanUpgrade={[2]}
      limited_permission="system"
    >
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-6">
          <PlatformImage src={require("../../../assets/images/expense.png")} />
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-6">
          <Input
            required
            label={context.t(`["accounting/credit"].add.customerSelection`)}
            name="custname"
            value={form.custname ? form.custname : ""}
            onChange={(e) => setForm({ ...form, custname: e.target.value })}
          />
          <DatePickerInput
            required
            label={context.t(`["accounting/debit"].add.scheduledPaymentDate`)}
            value={form.date}
            onChange={(date) => setForm({ ...form, date: date })}
          />
          <Input
            required
            type="number"
            label={
              context.t(`["accounting/debit"].add.enterAmount`) +
              (context.state.currency
                ? " (" + context.state.currency + ")"
                : "(₺)")
            }
            selected={form.amount !== null ? form.amount : ""}
            value={form.amount ? form.amount : ""}
            customInput={Input}
            decimalScale={2}
            suffix={" ₺"}
            onChange={async (e) => {
              setForm({
                ...form,
                amount: e.target.value,
              });
            }}
          />
          <Select
            label={context.t(`['accounting/expense'].add.typeInputLabel`)}
            items={types_loaded === true ? expense : null}
            value={form.json_id ? form.json_id : ""}
            labelKey="label"
            valueKey="json_id"
            selected={form.json_id !== null ? form.json_id : ""}
            handler={(json_id) => setForm({ ...form, json_id: json_id })}
          />
          <Input
            required
            label={context.t(`["accounting/debit"].add.explanation`)}
            value={form.explanation ? form.explanation : ""}
            multiline
            rows={4}
            onChange={(e) => {
              setForm({
                ...form,
                explanation: e.target.value,
              });
            }}
          />

          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-6">
              <Button
                title={context.t(`["accounting/debit"].add.updateReceivable`)}
                type="submit"
                size="md"
                fullWidth
                textColor="green"
                backgroundColor="green-opacity"
                onClick={() => {
                  updateExpense();
                  history.push("/accounting/debit");
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-6">
              <Button
                title={context.t(`["accounting/debit"].add.collectPayment`)}
                type="submit"
                size="md"
                fullWidth
                textColor="red"
                backgroundColor="red-opacity"
                onClick={() => setPaymentDialog(true)}
              />
            </Column>
          </Grid>
        </Column>
      </Grid>
      <AlertDialog
        open={payment_dialog}
        maxWidth="sm"
        fullWidth
        title={context.t(`["accounting/debit"].add.paymentDialogTitle`)}
        closeHandler={() => setPaymentDialog(false)}
        buttons={[
          {
            title: context.t(`["accounting/debit"].add.savePaymnet`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {
              if (selected_payment !== null) {
                insertPayment();
              } else {
                toast.warn("Ödeme Türünü Seçiniz");
                setPaymentDialog(true);
              }
            },
          },
          {
            title: context.t(`["accounting/debit"].add.closePayment`),
            icon: "close",
            backgroundColor: "red-opacity",
            textColor: "red",
          },
        ]}
      >
        {/* <DatePickerInput
          required
          label={context.t(`["accounting/debit"].add.scheduledPaymentDate`)}
          value={payments.date}
          onChange={(date) => setPayments({ ...payments, date: date })}
        /> */}
        <Input
          required
          type="number"
          label={
            context.t(`["accounting/debit"].add.enterAmount`) +
            context.state.currency
              ? " (" + context.state.currency + ")"
              : "(₺)"
          }
          selected={payments.amount !== null ? payments.amount : ""}
          customInput={Input}
          decimalScale={2}
          suffix={" ₺"}
          onChange={(e) => {
            setPayments({
              ...payments,
              amount: e.target.value,
            });
          }}
        />
        <Select
          label={context.t(`['accounting/debit'].payments.typeInputLabel`)}
          placeholder={context.t(
            `['accounting/debit'].payments.typeInputPlaceholder`
          )}
          items={payment}
          selected={selected_payment && selected_payment.value}
          labelKey="label"
          valueKey="value"
          handler={(payment_type) => {
            setSelectedPayment({ ...selected_payment, value: payment_type });
          }}
        />
      </AlertDialog>
      <Grid className="mt-5">
        <Column className="xs-12 sm-12 md-12 lg-12">
          <Table
            title="Detail Credit"
            loaded={loaded}
            headings={{
              date: {
                label: context.t(`["accounting/debit"].add.date`),
              },
              amount: {
                label: context.t(`["accounting/debit"].add.amount`),
              },
              payment_type: {
                label: context.t(`["accounting/debit"].add.paymentType`),
              },
              _: {
                label: context.t(`["accounting/debit"].add.actions`),
              },
            }}
            rows={current_payment}
            buttons={[
              {
                title: `${context.t(`["accounting/debit"].add.update`)}`,
                icon: "update",
                textColor: "primary",
                onClick: (row) => {
                  setCurrentPaymentDialog(true);
                  setSelectedCurrentPayment(row);
                },
              },
              {
                title: context.t(`["accounting/debit"].add.delete`),
                icon: "delete",
                textColor: "red",
                onClick: (row) => {
                  deletePayment(row.ID);
                },
              },
            ]}
          />
        </Column>
        <AlertDialog
          open={current_payment_dialog}
          maxWidth="sm"
          fullWidth
          title={context.t(`["accounting/debit"].add.paymentDialogTitle`)}
          closeHandler={() => setCurrentPaymentDialog(false)}
          buttons={[
            {
              title: context.t(`["accounting/debit"].add.savePaymnet`),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                updatePayment(selected_current_payment.ID);
                detailExpense();
              },
            },
            {
              title: context.t(`["accounting/debit"].add.closePayment`),
              icon: "close",
              backgroundColor: "red-opacity",
              textColor: "red",
            },
          ]}
        >
          {/* <DatePickerInput
            required
            label={context.t(`["accounting/debit"].add.scheduledPaymentDate`)}
            value={
              selected_current_payment
                ? moment(selected_current_payment.payment_date).format(
                    "YYYY-MM-DD"
                  )
                : current_payment.date
            }
            onChange={(date) => {
              selected_current_payment
                ? setSelectedCurrentPayment({
                    ...selected_current_payment,
                    payment_date: date,
                  })
                : setCurrentPayment({ ...current_payment, date: date });
            }}
          /> */}
          <Input
            required
            type="number"
            label={
              context.t(`["accounting/debit"].add.enterAmount`) +
              (context.state.currency
                ? " (" + context.state.currency + ")"
                : "(₺)")
            }
            selected={
              selected_current_payment
                ? selected_current_payment.amount
                : current_payment.amount !== null
                ? current_payment.amount
                : ""
            }
            value={
              selected_current_payment
                ? selected_current_payment.amount
                : current_payment.amount !== null
                ? current_payment.amount
                : ""
            }
            customInput={Input}
            decimalScale={2}
            suffix={" ₺"}
            onChange={(e) => {
              selected_current_payment
                ? setSelectedCurrentPayment({
                    ...selected_current_payment,
                    amount: e.target.value,
                  })
                : setCurrentPayment({
                    ...current_payment,
                    amount: e.target.value,
                  });
            }}
          />
          <Select
            label={context.t(`['accounting/debit'].payments.typeInputLabel`)}
            placeholder={context.t(
              `['accounting/debit'].payments.typeInputPlaceholder`
            )}
            items={payment}
            selected={
              selected_current_payment
                ? selected_current_payment.payment_type
                : current_payment
                ? current_payment.payment_type
                : ""
            }
            labelKey="label"
            valueKey="value"
            handler={(payment_type) => {
              selected_current_payment
                ? setSelectedCurrentPayment({
                    ...selected_current_payment,
                    payment_type: payment_type,
                  })
                : setCurrentPayment({
                    ...current_payment,
                    payment_type: payment_type,
                  });
            }}
          />
        </AlertDialog>
      </Grid>
    </AuthContainer>
  );
};

export default DebitDetail;

const PlatformImage = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: contain;
`;
