import React, { useEffect, useState } from "react";
import logo from "../../../../assets/images/logo.png";
import { GiSandsOfTime } from "react-icons/gi";

function Navbar({ timeLeft, setTimeLeft }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  function formatTime(seconds) {
    const days = Math.floor(seconds / 86400); // 1 gün = 86400 saniye
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Sayıları gerekli durumlarda önde sıfır olacak şekilde pad etme
    const formattedDays = days.toString();
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    console.log("formattedDays: ", typeof formattedDays);

    return (
      <div
        style={{
          width: "100%",
          fontFamily: "Arial",
          fontSize: "20px",
          textAlign: "center",
          backgroundColor: "#f0f0f0",
          borderRadius: "5px",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {" "}
        <GiSandsOfTime style={{}} size={35} />
        <div>
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              textShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
              fontSize: "12px",
              color: "rgb(213, 10, 88)",
            }}
          >
            Fırsatı Kaçırma!
          </span>
          <div style={{ width: "100%", textAlign: "center" }}>
            {Number(formattedDays) > 1 ? (
              <>
                Son{" "}
                <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                  {formattedDays}
                </span>{" "}
                Gün
              </>
            ) : (
              <>
                <span>{hours}</span>:<span>{formattedMinutes}</span>:
                <span>{formattedSeconds}</span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        position: "fixed",
        top: "0px",
        zIndex: 999,
        backgroundColor: "white",
        padding: "10px",
      }}
    >
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <img style={{ width: "150px" }} src={logo} />
        </div>

        <a
          href="#test"
          style={{
            marginRight: "20px",
            textDecoration: "none",
            color: "black",
          }}
        >
          {formatTime(timeLeft)}
        </a>
      </div>
    </div>
  );
}

export default Navbar;
