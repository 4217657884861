import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/store";
import DatePickerInput from "../../theme/CustomMUI/DatePickerInput";
import FullScreenDialog from "../../theme/CustomMUI/FullScreenDialog";
import Input from "../../theme/CustomMUI/Input";
import { Column, Grid } from "../../theme/Grid";
import { Alert } from "@material-ui/lab";
import Axios from "axios";
import moment from "moment";
import Select from "../../theme/CustomMUI/Select";
import Table from "../../theme/Table";
import { toast } from "react-toastify";

const FilterAppointment = (props) => {
  const context = useContext(AppContext);

  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => setPage(page),
  });
  const [page, setPage] = useState(1);
  const [appointmentData, setAppointmentData] = useState([]);
  const [staffs, setStaffs] = useState([]);

  const [staff_id, setStaffID] = useState(null);
  const [service_key, setServiceKey] = useState("");
  const [customer_key, setCustomerKey] = useState("");
  const [appointment_state, setAppointmentState] = useState(null);

  const [filter, setFilter] = useState({
    between: {
      start: moment().local().subtract(30, "days").toISOString(),
      end: moment().local().add(1, "days").toISOString(),
    },
  });

  const [filterPicker] = useState([
    {
      id: 1,
      label: "Bugün",
      offset: 1,
    },
    {
      id: 2,
      label: "Bu Hafta",
      offset: 3,
    },
    {
      id: 3,
      label: "Bu Ay",
      offset: 4,
    },
  ]);
  const [selected_filter_picker, setSelected_filter_picker] = useState(null);

  const getAppointmentsByFilter = (value, page = 1) => {
    const { between } = filter;
    Axios.get(
      `${context.api_endpoint}/company/appointment/list`,
      selected_filter_picker === null
        ? {
            params: {
              page: page ? page : 1,
              start: between.start.split("T")[0],
              end: between.end.split("T")[0],
              isbetween: true,
              staffid: staff_id,
              servicekey: service_key,
              customerkey: value ?? "",
              appointmentstate: appointment_state,
            },
          }
        : {
            params: {
              page: page ? page : 1,
              offset: selected_filter_picker.offset,
              isbetween: false,
              staffid: staff_id,
              servicekey: service_key,
              customerkey: value ?? "",
              appointmentstate: appointment_state,
            },
          }
    )
      .then(({ data }) => {
        // console.log("data");
        setLoaded(true);
        setAppointmentData(
          [
            ...data.data.records.map((item) => ({
              ...item,
              customer: {
                ...item.customer,
                phone: `0${item.customer.phone.substring(
                  0,
                  3
                )} ${item.customer.phone.substring(
                  3,
                  6
                )} ${item.customer.phone.substring(6, 10)}`,
              },
              created_at: moment(item.created_at).local().format("LLL"),
              appointment_start_date: moment(item.appointment_start_date)
                .local()
                .format("LLL"),
            })),
          ].filter((item) => item !== undefined)
        );
        setPagination({
          ...pagination,
          page: data.data.page,
          total_page: data.data.records.length,
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getAppointmentIndex = () => {
    Axios.get(`${context.api_endpoint}/company/staffs/unsafe`).then(
      (response) => {
        if (response.status === 200) {
          setStaffs([...response.data.data]);
        }
      }
    );
  };

  useEffect(() => {
    if (props.open) {
      getAppointmentsByFilter(customer_key, pagination.page);
    }
  }, [
    props.open,
    selected_filter_picker,
    filter.between.start,
    filter.between.end,
    customer_key,
    page,
    service_key,
    staff_id,
    appointment_state,
  ]);

  useEffect(() => {
    if (props.open) {
      getAppointmentIndex();
    }
  }, [props.open]);

  return (
    <FullScreenDialog
      open={props.open}
      title={context.t(`component.filterAppointmentDialog.title`)}
      onClose={props.onClose}
    >
      <Grid>
        <Column className="xs-12 sm-12 md-4 lg-3">
          <Alert severity="info" className="mb-2">
            <div>
              <b>{context.t(`['appointments/filter'].alertBoldText`)}</b>
            </div>
            {context.t(`['appointments/filter'].alertText`)}
          </Alert>

          <DatePickerInput
            className="mb-1"
            required
            label={context.t(`['appointments/filter'].startInputLabel`)}
            value={new Date(moment(filter.between.start).toISOString())}
            onChange={async (start_date) => {
              setLoaded(false);
              setFilter({
                ...filter,
                between: {
                  ...filter.between,
                  start: moment(start_date).toISOString(),
                },
              });
              setSelected_filter_picker(null);
            }}
          />

          <DatePickerInput
            className="mb-1"
            required
            label={context.t(`['appointments/filter'].endInputLabel`)}
            minDate={new Date(moment(filter.between.start).toISOString())}
            value={new Date(moment(filter.between.end).toISOString())}
            onChange={async (end_date) => {
              setLoaded(false);
              setFilter({
                ...filter,
                between: {
                  ...filter.between,
                  end: moment(end_date).toISOString(),
                },
              });
              setSelected_filter_picker(null);
            }}
          />

          <Select
            label={context.t(`['appointments/filter'].statusInputLabel`)}
            items={[
              {
                label: context.t(`['appointments/filter'].statusInputItems[0]`),
                value: "null",
              },
              {
                label: context.t(`['appointments/filter'].statusInputItems[1]`),
                value: 0,
              },
              {
                label: context.t(`['appointments/filter'].statusInputItems[2]`),
                value: 1,
              },
              {
                label: context.t(`['appointments/filter'].statusInputItems[3]`),
                value: 2,
              },
              {
                label: context.t(`['appointments/filter'].statusInputItems[4]`),
                value: 3,
              },
            ]}
            labelKey="label"
            valueKey="value"
            selected={`${appointment_state}`}
            handler={async (value) => {
              setAppointmentState(value === "null" ? null : parseInt(value));
            }}
          />

          <Select
            label={context.t(`['appointments/filter'].staffInputLabel`)}
            items={[
              {
                id: "null",
                full_name: context.t(`['appointments/filter'].allStaffsText`),
              },
              ...staffs,
            ]}
            labelKey="full_name"
            valueKey="id"
            selected={`${staff_id}`}
            handler={async (staff_id) => {
              setStaffID(staff_id !== "null" ? parseInt(staff_id) : null);
            }}
          />

          <Select
            label={context.t(`appointments.dayWeekSelectLabel`)}
            items={filterPicker}
            labelKey="label"
            valueKey="id"
            selected={
              selected_filter_picker ? `${selected_filter_picker.id}` : null
            }
            returnFullObject
            handler={async (filterPicker) => {
              setSelected_filter_picker(filterPicker);
            }}
          />

          <Input
            label={context.t(`['appointments/filter'].serviceInputLabel`)}
            onChange={async ({ target: { value } }) => {
              setServiceKey(value);
            }}
          />

          <Input
            label={context.t(`['appointments/filter'].customerInputLabel`)}
            onChange={({ target: { value } }) => {
              setCustomerKey(value);
            }}
          />
        </Column>
        <Column className="xs-12 sm-12 md-8 lg-9">
          <Table
            loaded={loaded}
            headings={{
              appointment_start_date: {
                label: context.t(
                  `['appointments/filter'].headings.appointmentStartDate`
                ),
                style: { width: 200 },
              },
              "customer.full_name": {
                label: context.t(
                  `['appointments/filter'].headings.customerFullName`
                ),
                //with_photo: "customer.photo",
                limited_line: 1,
              },
              "service.name": {
                label: context.t(
                  `['appointments/filter'].headings.serviceName`
                ),
                limited_line: 1,
              },
              "staff.full_name": {
                label: context.t(
                  `['appointments/filter'].headings.staffFullName`
                ),
                with_photo: "staff.detail.profile_photo",
                limited_line: 1,
              },
              "customer.phone": {
                label: context.t(
                  `['appointments/filter'].headings.customerPhone`
                ),
              },
              created_at: {
                label: context.t(`['appointments/filter'].headings.createdAt`),
              },
            }}
            rows={appointmentData}
            pagination={pagination}
          />
        </Column>
      </Grid>
    </FullScreenDialog>
  );
};

export default React.memo(FilterAppointment);
