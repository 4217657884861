import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import AuthContainer from "../../../components/AuthContainer";
import Axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Input from "../../../theme/CustomMUI/Input";
import { Alert } from "@material-ui/lab";
// import CalculateMessageCharCount from "../../../functions/CalculateMessageCharCount";

const CustomersRating = () => {
  const context = useContext(AppContext);

  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => getRatings(page),
  });
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [message_dialog, setMessage_dialog] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [message_id, setMessage_id] = useState(0);
  // const [delete_confirm, setDelete_confirm] = useState(false);
  // const [selected_rating, set_selected_rating] = useState(null);

  const getRatings = (page = 1) => {
    Axios.get(`${context.api_endpoint}/company/customer/ratings`, {
      params: { page },
    })
      .then(({ data }) => {
        if (data.status === 200) {
          setLoaded(true);
          setData([
            ...data.data.map((item) => ({
              ...item,
              created_at: moment(item.created_at).format("LLL"),
              company_star: `${parseInt(item.company_star).toFixed(2)} / 5.00`,
              staff_star: `${parseInt(item.staff_star).toFixed(2)} / 5.00`,
            })),
          ]);
          // setPagination({
          //   ...pagination,
          //   total_page: data.data.records.length,
          //   page: data.data.page,
          // });
        }
      })
      .catch((e) => {
        if (e.response.status === 400) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const addReplyToRating = () => {
    Axios.post(`${context.api_endpoint}/company/new/customer/rating/reply`, {
      customer_rating_id: message_id ? message_id : null,
      reply_to_rating: message ? message : "",
    })
      .then(({ data }) => {
        if (data.status === 201) {
          toast.success(data.data);
          getRatings();
        }
      })
      .catch((e) => {
        if (e.response.status === 400) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  // const deleteRatingByID = () => {
  //   Axios.post(`${context.api_endpoint}/company/delete/customer/rating`, {
  //     id: _selected_rating.id,
  //   })
  //     .then((response) => {
  //       if (response.status === 201) {
  //         getRatings();
  //         toast.success(context.t(`['customers/rating'].deleteToast`));
  //       }
  //     })
  //     .catch((e) => {
  //       if (e.response.status === 401) {
  //         toast.error(e.response.data.message);
  //       } else {
  //         toast.warning(e.response.data.message);
  //       }
  //     });
  // };

  useEffect(() => {
    getRatings();
  }, []);

  return (
    <AuthContainer authorities={[1]} limited_permission="customer">
      <Grid>
        <Column className="xs-12 sm-12">
          <Table
            loaded={loaded}
            headings={{
              created_at: {
                label: context.t(`['customers/rating'].headings.createdAt`),
              },
              staff_name: {
                label: context.t(`['customers/rating'].headings.staffFullName`),
                with_photo: "appointment.staff.detail.profile_photo",
                limited_line: 1,
              },
              service_name: {
                label: context.t(`['customers/rating'].headings.serviceName`),
                limited_line: 1,
              },
              company_star: {
                label: context.t(`['customers/rating'].headings.companyStar`),
                limited_line: 1,
              },
              staff_star: {
                label: context.t(`['customers/rating'].headings.staffStar`),
                limited_line: 1,
              },
              description: {
                label: context.t(`['customers/rating'].headings.description`),
                limited_line: 5,
                style: { width: 400 },
              },
              _: { label: context.t(`component.actionHeadingTextRating`) },
            }}
            rows={data}
            pagination={pagination}
            buttons={[
              {
                icon: "message",
                title: context.t(`['customers/rating'].viewToComment`),
                textColor: "green",
                onClick: (row) => {
                  console.log(row);
                  setMessage(row.reply_to_rating);
                  setMessage_dialog(true);
                },
              },
              {
                icon: "send",
                title: context.t(`['customers/rating'].replyToComment`),
                textColor: "primary",
                onClick: (row) => {
                  setMessage_id(row.id);
                  setMessage(row.reply_to_rating);
                  setSendMessage(true);
                },
              },
              // {
              //   icon: "delete",
              //   title: context.t(`['customers/rating'].deleteButtonTitle`),
              //   textColor: "red",
              //   onClick: (row) => {
              //     if (context.state.company_license.accounting === 1) {
              //       set_selected_rating({ ...row });
              //       setDelete_confirm(true);
              //     } else {
              //       toast.warning(
              //         context.t(
              //           `['customers/rating'].deleteBeforePlanUpgradeToast`
              //         )
              //       );
              //     }
              //   },
              // },
            ]}
          />
        </Column>
      </Grid>

      <AlertDialog
        maxWidth="sm"
        fullWidth={true}
        open={sendMessage}
        closeHandler={() => setSendMessage(false)}
        title={context.t(`['customers/rating'].message.title`)}
        buttons={[
          {
            title: context.t(`['customers/rating'].message.confirmButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => addReplyToRating(),
          },
          {
            title: context.t(`['customers/rating'].message.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Input
          label={context.t(`['customers/rating'].message.alertText`)}
          multiline={true}
          rows={10}
          value={message}
          helperText={context.t(`['customers/rating'].message.noteInputHint`, {
            char: message.length,
          })}
          onChange={(e) =>
            e.target.value.length <= 450 && setMessage(e.target.value)
          }
        />
      </AlertDialog>

      <AlertDialog
        maxWidth="sm"
        fullWidth={true}
        open={message_dialog}
        closeHandler={() => setMessage_dialog(false)}
        title={context.t(`['customers/rating'].message.comment`)}
        buttons={[
          {
            title: "Kapat",
            icon: "close",
            backgroundColor: "primary-opacity",
            textColor: "primary",
          },
        ]}
      >
        <Alert>{message}</Alert>
      </AlertDialog>
    </AuthContainer>
  );
};

export default CustomersRating;
