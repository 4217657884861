import React, { useEffect, useState, useContext } from "react";
import AppContext from "../../../../context/store";
import AuthContainer from "../../../../components/AuthContainer";
import Axios from "axios";

import { Grid, Column } from "../../../../theme/Grid";
import Input from "../../../../theme/CustomMUI/Input";
import Select from "../../../../theme/CustomMUI/Select";
import Button from "../../../../theme/Button";

import CalculateMessageCharCount from "../../../../functions/CalculateMessageCharCount";
import ModalDialog from "../../../../theme/CustomMUI/AlertDialog";
import Alert from "@material-ui/lab/Alert";
import SectionTitle from "../../../../theme/SectionTitle";
import Table from "../../../../theme/Table";
import { Checkbox, Chip, FormGroup, TextField } from "@material-ui/core";
import styled from "styled-components";
import { toast } from "react-toastify";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../../../../theme/FCLabel";
import { Check, Block, FeaturedPlayList, WhatsApp } from "@material-ui/icons";
import UndefinedSenderAlert from "../../../../components/Alerts/UndefinedSenderAlert";
import WpUndefinedAlert from "../../../../components/Alerts/WpUndefinedAlert";
import SenderFetchAlert from "../../../../components/Alerts/SenderFetchAlert";
import CalculateMessageCount from "../../../../functions/CalculateMessageCount";
import StatsCard from "../../../../components/Cards/StatsCard";
import SMSReview from "../../../../components/Dialogs/SMSReview";
import { phoneNumberDisplay } from "../../../../functions/PhoneNumberDisplay";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import moment from "moment";
import TimeSelect from "../../../../theme/TimeSelect";
import BetweenTimeGenerator from "../../../../functions/BetweenTimeGenerator";
import UploadInput from "../../../../components/UploadInput";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SendSelective = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [step, setStep] = useState(0);
  const [check_modal, setCheckModal] = useState(false);
  const [search_key, setSearchKey] = useState("");
  const [sender, setSender] = useState("");

  const [customers, setCustomers] = useState({
    loaded: false,
    data: [],
    pagination: {
      total_page: null,
      page: 1,
      onChange: (page) =>
        search_key && search_key.length
          ? getCustomers(search_key, page)
          : getCustomers("", page),
    }
  });

  const [approved_customers, setApprovedCustomers] = useState([]);
  const [excepted_customers, setExceptedCustomers] = useState([]);

  const [sms_request_status, setSmsRequestStatus] = useState(false);
  const [request, setRequest] = useState({
    all: false, //Tüm müşterilere gönderilecek mi?
    id: [], //Müşteri ID listesi,
    except: [], //Tüm müşteriler true ise hariç müşterilerin ID listesi,
    message: "", //Gönderilecek mesaj içeriği
    send_date: new Date(), //Gönderilecek tarih
    selected_time: null, //Gönderilecek saat
    hours: [...BetweenTimeGenerator(context.state.company_shift)], //Gönderilecek saat aralığı
    future_date: false,
    file: null,
  });

  const [templates, setTemplates] = useState([]);
  const [selected_template, setSelectedTemplate] = useState(null);
  const [available_credit, setAvailableCredit] = useState(null);
  const [shortCode, setShortCode] = useState(null);

  // Checkbox for sms and wp
  const [checkedSMS, setCheckedSMS] = useState(true);
  const [checkedWP, setCheckedWP] = useState(false);

  // whatsapp or sms
  const [sms_prefer, setSmsPrefer] = useState(1);

  // WPState and WP phone
  const [wp_state, setWPState] = useState(null);
  const [wp_phone, setWPPhone] = useState(null);

  const [open_sms_review_dialog, setOpenSmsReviewDialog] = useState(false);
  const [total_number, setTotalNumber] = useState(0);
  const [total_sms_credit, setTotalSmsCredit] = useState(0);
  const [total_wp_credit, setTotalWPCredit] = useState(0);

  // checking dor wp accounts
  const checkWp = async () => {
    await Axios.get(
      `${context.api_endpoint}/company/get/wp/message/settings`
    )
      .then(async (ress) => {
        if (ress.data.data.device_key !== "") {
          await Axios.post(
            `${context.api_endpoint}/salon/wp/login/check/active/${ress.data.data.device_key}`
          )
            .then((ress) => {
              if (ress.status === 200) {
                setWPState(ress.data.is_connect);
                setWPPhone(ress.data.data.device_number);
                setCheckedWP(true);
              }
            })
            .catch(() => {
              setWPState(false);
              setWPPhone(null);
              setCheckedWP(false);
            });
        } else {
          setCheckedWP(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCustomers = (key, page = 1) => {
    Axios.get(`${context.api_endpoint}/company/customers/blacklist`, {
      params: {
        page: page ? page : 1,
        key: key ? key : null,
      },
    }).then(({ data }) => {
      setCustomers({
        ...customers,
        loaded: true,
        data: [
          ...data.data.records.map((item) => {
            return {
              ...item,
              phone:
                context.state.user.permission === 2 &&
                context.state.limited_permission.customer_info === 0
                  ? phoneNumberDisplay(item.phone)
                  : `0${item.phone.substring(0, 3)} ${item.phone.substring(
                      3,
                      6
                    )} ${item.phone.substring(6, 10)}`,
            };
          }),
        ]
      });
    });
  };

  const getContextSenderSettings = () => {
    Axios.get(`${context.api_endpoint}/company/itself`).then(
      (response) => {
        const {
          data: { data },
        } = response;
        context.dispatch({
          type: "SET_COMPANY_SMS_SETTINGS",
          payload: {
            company_sms_settings: { ...{ sender: data.sender } },
            company_sms_settings_loaded: true,
          },
        });
      }
    );
  };

  const sendSelectiveSms = () => {
    setSmsRequestStatus(true);

    let appointment_date = null;
    if (request.future_date === true) {
      let appointment_time = request.selected_time.split(":");
      appointment_date = `${moment(request.send_date).format("YYYY-MM-DD")}T${
        appointment_time[0]
      }:${appointment_time[1]}:00Z`;
    }

    let sms_prefer = (() => {
      if (checkedSMS && checkedWP) {
        return 3;
      }
      if (checkedSMS) {
        return 1;
      } else {
        if (checkedWP) {
          return 2;
        } else {
          return 0;
        }
      }
    })();
    if (sms_prefer === 0) {
      return toast.error("Gönderim yöntemlerinden birisini seçin");
    }

    const formData = new FormData();
    approved_customers.forEach((item) => {
      formData.append("id", item.id);
    });

    formData.append("all", request.all);
    excepted_customers.forEach((item) => {
      formData.append("except", item.id);
    });

    formData.append("message", request.message);

    request.future_date &&
      formData.append(
        "send_date",
        request.future_date === true ? appointment_date : null
      );

    formData.append("sms_prefer", sms_prefer);

    formData.append("file", request.file);

    Axios.post(
      `${context.api_endpoint}/company/message/to/customers`,
      formData
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          getBalance();
          setTimeout(() => {
            setStep(0);
            setApprovedCustomers([]);
            setRequest({
              message: "",
            });
          }, 500);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
          setTimeout(() => {
            setStep(0);
            setApprovedCustomers([]);
            setRequest({
              message: "",
            });
          }, 500);
        } else {
          toast.warning(e.response.data.message);
          setTimeout(() => {
            setStep(0);
            setApprovedCustomers([]);
            setRequest({
              message: "",
            });
          }, 500);
        }
      });
  };
  const getBalance = async () => {
    Axios.get(`${context.api_endpoint}/company/balance`)
      .then((data) => {
        // setWpBalance(data.data.data.wp_credit);
        context.dispatch({
          type: "SET_BALANCE",
          payload: { balance: data.data },
        });
      })
      .catch((err) => console.log(err));
  };
  const getTemplates = () => {
    Axios.get(`${context.api_endpoint}/company/sms/template/all`).then(
      ({ data }) => {
        setTemplates([...data.data]);
      }
    );
  };

  const getMessageSettings = () => {
    Axios.get(`${context.api_endpoint}/company/message/credit`).then(
      ({ data }) => {
        setAvailableCredit(`${data.data.credit} SMS`);
        setSender(data.data.settings.sender);
        if (
          data.data.settings.sender === "" ||
          data.data.settings.sender === null
        ) {
          setCheckedSMS(false);
        }
      }
    );
  };

  const getSMSReview = () => {
    let sms_prefer = (() => {
      if (checkedSMS && checkedWP) {
        return 3;
      }
      if (checkedSMS) {
        return 1;
      } else {
        if (checkedWP) {
          return 2;
        } else {
          return 0;
        }
      }
    })();
    if (sms_prefer === 0) {
      return toast.error("Gönderim yöntemlerinden birisini seçin");
    }
    Axios.post(
      `${context.api_endpoint}/company/message/to/customer/preview`,
      {
        all: request.all,
        id: !request.all
          ? [...approved_customers.map((item) => item.id)]
          : [],
        except: request.all
          ? [...excepted_customers.map((item) => item.id)]
          : [],
        message: request.message,
        sms_prefer,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setTotalNumber(response.data.data.total_numbers);
          setTotalSmsCredit(response.data.data.total_numbers);
          setTotalWPCredit(response.data.data.total_wp_credit);
          setOpenSmsReviewDialog(true);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getShortcode = () => {
    Axios.get(`${context.api_endpoint}/company/shortcode/list/keyword`)
      .then((response) => {
        if (response.status === 200) {
          setShortCode(response.data.data.keyword);
        }
      })
      .catch((e) => {});
  };

  const fileHandlerProp = (value) => {
    setRequest({
      ...request,
      file: value,
    });
  };

  const Sidebar = (props) => {
    return (
      <>
        <div>
          <div className={request.all ? "mb-5" : ""}>
            <SectionTitle className="mb-1">
              {context.t(`['sms/selective'].approved.title`)}
            </SectionTitle>
            {!request.all ? (
              <ChipContainer>
                {approved_customers.length > 0 ? (
                  approved_customers.map((item) => (
                    <Chip
                      style={{ height: 46, borderRadius: 5 }}
                      className="mr-2 mb-2 bg-green-opacity fc-green fw-500"
                      label={item.full_name}
                      onDelete={() => {
                        setApprovedCustomers([
                          ...approved_customers.filter(
                            (_item) => _item !== item
                          )
                        ]);
                      }}
                    />
                  ))
                ) : (
                  <Alert
                    severity="info"
                    color="warning"
                    style={{ width: "100%", boxSizing: "border-box" }}
                  >
                    <b>
                      {context.t(
                        `['sms/selective'].approved.notFound.alertBoldText`
                      )}
                    </b>
                    <br />
                    {context.t(
                      `['sms/selective'].approved.notFound.alertText`
                    )}
                  </Alert>
                )}
              </ChipContainer>
            ) : (
              <Alert
                severity="success"
                style={{ width: "100%", boxSizing: "border-box" }}
              >
                <b>
                  {context.t(`['sms/selective'].approved.alertBoldText`)}
                </b>
              </Alert>
            )}
          </div>

          {request.all && (
            <div>
              <SectionTitle className="mb-1">
                {context.t(`['sms/selective'].excepted.title`)}
              </SectionTitle>
              <ChipContainer>
                {excepted_customers.length > 0 ? (
                  excepted_customers.map((item) => (
                    <Chip
                      style={{ height: 46, borderRadius: 5 }}
                      className="mr-2 mb-2 bg-red-opacity fc-red fw-500"
                      label={item.full_name}
                      onDelete={() => {
                        setExceptedCustomers([
                          ...excepted_customers.filter(
                            (_item) => _item !== item
                          )
                        ]);
                      }}
                    />
                  ))
                ) : (
                  <Alert
                    severity="info"
                    style={{ width: "100%", boxSizing: "border-box" }}
                  >
                    <b>
                      {context.t(
                        `['sms/selective'].excepted.notFound.alertBoldText`
                      )}
                    </b>
                    <br />
                    {context.t(
                      `['sms/selective'].excepted.notFound.alertText`
                    )}
                  </Alert>
                )}
              </ChipContainer>
            </div>
          )}

          {step === 0 && (
            <Button
              className="mt-3"
              title={context.t(`['sms/selective'].continueButtonTitle`)}
              backgroundColor="primary"
              textColor="white"
              disabled={
                context.state.company_sms_settings_loaded !== true ||
                (approved_customers.length === 0 && request.all === false)
              }
              fullWidth
              pushEffect
              onClick={() => setStep(1)}
            />
          )}

          {step === 1 && (
            <Button
              className="mt-3"
              title={context.t(
                `['sms/selective'].backToSelectButtonTitle`
              )}
              backgroundColor="primary-opacity"
              textColor="primary"
              fullWidth
              pushEffect
              onClick={() => setStep(0)}
            />
          )}
        </div>
      </>
    );
  };

  // Bu aşamda müşteri seçimleri yapılmaktadır.
  const CustomerSettingsStep = (props) => {
    return (
      <>
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
            <SectionTitle className="mb-1">
              {context.t(`['sms/selective'].customer.title`)}
            </SectionTitle>
            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-6">
              <Input
                autoFocus
                label={context.t(`['sms/selective'].customer.searchInputLabel`)}
                value={search_key}
                placeholder="Müşteri adını giriniz"
                onInput={(e) => {
                  setSearchKey(e.target.value);
                  getCustomers(search_key, 1);
                }}
              />
              </Column>
              <Column className="xs-12 sm-12 md-6">
                <Button
                  className="elevation-1"
                  icon={!request.all ? "check" : "close"}
                  title={
                    !request.all
                      ? context.t(`['sms/selective'].customer.selectAll`)
                      : context.t(`['sms/selective'].customer.unselectAll`)
                  }
                  textColor={!request.all ? "green" : "red"}
                  backgroundColor="white"
                  size="lg"
                  fullWidth
                  onClick={() => {
                    setExceptedCustomers([]);
                    setApprovedCustomers([]);
                    setRequest({ ...request, all: !request.all });
                  }}
                />
              </Column>
            </Grid>

            <Table
              loaded={customers.loaded}
              headings={{
                full_name: {
                  label: context.t(
                    `['sms/selective'].customer.headings.fullName`
                  ),
                  limited_line: 1,
                  style: { width: "33%" },
                },
                phone: {
                  label: context.t(
                    `['sms/selective'].customer.headings.phone`
                  ),
                  limited_line: 1,
                  style: { width: "33%" },
                },
                _: { label: context.t(`component.actionHeadingText`) },
              }}
              rows={customers.data}
              pagination={customers.pagination}
              showAlwaysPagination={true}
              buttons={[
                {
                  iconComponent: Check,
                  textColor: "green",
                  backgroundColor: "green-opacity",
                  fullWidth: true,
                  pushEffect: true,
                  disabled: (row) =>
                    request.all ||
                    approved_customers.filter((item) => item.id === row.id)
                      .length ||
                    excepted_customers.filter((item) => item.id === row.id)
                      .length,
                  onClick: (row) => {
                    setApprovedCustomers(
                      approved_customers.filter((item) => item.id === row.id)
                      .length === 0
                      ? [...approved_customers, row]
                      : [...approved_customers]
                    );
                  }
                },
                {
                  iconComponent: Block,
                  textColor: "red",
                  backgroundColor: "red-opacity",
                  fullWidth: true,
                  pushEffect: true,
                  disabled: (row) =>
                    !request.all ||
                    approved_customers.filter((item) => item.id === row.id)
                      .length ||
                    excepted_customers.filter((item) => item.id === row.id)
                      .length,
                  onClick: (row) => {
                    setExceptedCustomers(
                      excepted_customers.filter((item) => item.id === row.id)
                      .length === 0
                      ? [...excepted_customers, row]
                      : [...excepted_customers]
                    );
                  }
                },
              ]}
            />
          </Column>

          <Column className={`xs-12 sm-12 md-12 lg-12 xl-6`}>
            <Sidebar />
          </Column>
        </Grid>
      </>
    );
  };

  const SmsSettingsStep = (props) => {
    return (
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-8">
          <Column className="xs-12 sm-12 md-12 lg-2 mt-1">
            <SectionTitle className="mb-1">
              {context.t(`sms.send.messageInputLabel`)}
            </SectionTitle>
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-8">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={request.future_date}
                  onChange={(e) => {
                    setRequest({ ...request, future_date: e.target.checked });
                  }}
                />
              }
              label={
                <FCLabel>
                  <i className="material-icons">filter_list</i>{" "}
                  <span>{context.t(`sms.send.futureDate`)}</span>
                </FCLabel>
              }
            />
          </Column>
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
              <Select
                label={context.t(`sms.send.template`)}
                items={[...templates]}
                selected={selected_template?.name ?? ""}
                labelKey="name"
                valueKey="name"
                returnFullObject
                handler={(template) => {
                  setSelectedTemplate(template);
                  setRequest({
                    ...request,
                    message: template.content
                  });
                }}
              />
            </Column>
            {request.future_date && (
              <>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  <DatePickerInput
                    label={context.t(`sms.send.sendDate`)}
                    value={
                      request.send_date
                        ? new Date(request.send_date).toISOString()
                        : ""
                    }
                    onChange={(date) => {
                      setRequest({
                        ...request,
                        send_date: new Date(date).toISOString()
                      });
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  <TimeSelect
                    fullTime={true}
                    label={context.t(
                      `['settings/hours'].startInputLabelHour`
                    )}
                    type="hour"
                    value={
                      request.selected_time !== null
                        ? request.selected_time.split(":")[0]
                        : ""
                    }
                    fullWidth={true}
                    size="medium"
                    companyShiftTime={true}
                    // minuteStep={5}
                    handler={(value) => {
                      setRequest({
                        ...request,
                        selected_time:
                          request.selected_time !== null
                            ? `${value}:${
                                request.selected_time.split(":")[1]
                              }`
                            : `${value}:00`
                      });
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  <TimeSelect
                    label={context.t(
                      `['settings/hours'].startInputLabelMinute`
                    )}
                    type="minute"
                    value={
                      request.selected_time !== null
                        ? request.selected_time.split(":")[1]
                        : ""
                    }
                    selectedHour={request.selected_time}
                    lastHour={request.hours[request.hours.length - 1]}
                    fullWidth={true}
                    size="medium"
                    handler={(time) => {
                      setRequest({
                        ...request,
                        selected_time:
                          request.selected_time !== null
                            ? `${request.selected_time.split(":")[0]}:${time}`
                            : null
                      });
                    }}
                  />
                </Column>
              </>
            )}
          </Grid>
          <Input
            label={context.t(`sms.send.messageInputLabel`)}
            multiline={true}
            rows={10}
            value={request.message}
            onChange={(e) => {
              setRequest({
                ...request,
                message: e.target.value
              });
            }}
            helperText={CalculateMessageCharCount(
              request.message,
              `${
                checkedSMS && checkedWP
                  ? "1"
                  : !checkedSMS && checkedWP
                  ? "3"
                  : "1"
              } `
            )}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              className="mb-1 ml-1"
              title={context.t(`sms.send.autoText.shortCode`)}
              size="xs"
              textColor="purple"
              outlined
              disabled={shortCode === ""}
              onClick={() => {
                setRequest({
                  ...request,
                  message: `${request.message}SMS iptali için ${shortCode} yazın 4607'a gönderin.`
                });
              }}
            />

            {checkedWP && (
              <UploadInput fileHandlerProp={fileHandlerProp} />
            )}
          </div>

          <Button
            disabled={
              request.message.length === 0 ||
              !CalculateMessageCount(
                request.message,
                `${
                  checkedSMS && checkedWP
                    ? "1"
                    : !checkedSMS && checkedWP
                    ? "3"
                    : "1"
                } `
              )
            }
            title={context.t("menuItems.sms.children.sendSelective")}
            icon="send"
            backgroundColor="primary"
            textColor="white"
            fullWidth={true}
            onClick={() => {
              getSMSReview();
            }}
          />
        </Column>

        <Column className={`xs-12 sm-12 md-12 lg-12 xl-4`}>
          <Sidebar />
        </Column>
      </Grid>
    );
  };

  useEffect(() => {
    getContextSenderSettings();
    getCustomers();
    getTemplates();
    getMessageSettings();
    getShortcode();
    checkWp();
  }, []);

    return (
      <AuthContainer authorities={[1]} limited_permission="sms">
        <Grid className="mb-2">
          <Column className="xs-12 sm-12 md-auto">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-orange bg-orange-opacity">
                    <FeaturedPlayList
                      style={{
                        fontSize: "2em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>SMS Mesajı</StatsHeader>
                    <StatsValue>{sender}</StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
          <Column className="xs-12 sm-12 md-auto">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-green bg-green-opacity">
                    <WhatsApp
                      style={{
                        fontSize: "3em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>WhatsApp Mesajı</StatsHeader>
                    <StatsValue>{wp_phone}</StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
          <Column className="xs-12 sm-12 md-auto">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      sender === "" ? false : checkedSMS
                    }
                    onChange={(e) => {
                      if (sender !== "") {
                        setCheckedSMS(e.target.checked);
                      }
                    }}
                    color="primary"
                  />
                }
                label={context.t(`sms.sendType.sendSMS`)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedWP}
                    onChange={(e) => {
                      if (!wp_state) {
                        history.push("/settings/wp");
                      }
                      setCheckedWP(e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label={context.t(`sms.sendType.sendWP`)}
              />
            </FormGroup>
          </Column>
          <Column className="xs-12 sm-12 md-2">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-orange bg-orange-opacity">
                    <FeaturedPlayList
                      style={{
                        fontSize: "2em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>
                      {context.t(`sms.credit.sms`)}
                    </StatsHeader>
                    <StatsValue>
                      {" "}
                      {context.state.balance?.data?.credit}
                    </StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
          <Column className="xs-12 sm-12 md-2">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-green bg-green-opacity">
                    <WhatsApp
                      style={{
                        fontSize: "3em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>{context.t(`sms.credit.wp`)}</StatsHeader>
                    <StatsValue>
                      {" "}
                      {context.state.balance?.data?.wp_credit}
                    </StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
        </Grid>

        {(sender === "" || sender === null) &&
        !wp_state ? (
          <>
            <UndefinedSenderAlert />
            <WpUndefinedAlert />
          </>
        ) : (
          <></>
        )}

        {step === 0 && <CustomerSettingsStep />}

        {step === 1 && <SmsSettingsStep />}

        <ModalDialog
          title={context.t(`sms.send.check.title`)}
          open={check_modal}
          textType={true}
          closeHandler={() => setCheckModal(false)}
          buttons={[
            {
              title: context.t(`sms.send.check.confirmButtonTitle`),
              onClick: () => getSMSReview(),
            },
            { title: context.t(`sms.send.check.discardButtonTitle`) },
          ]}
        >
          <Alert severity="warning">
            {context.t(`sms.send.check.alertText`)}
          </Alert>
        </ModalDialog>
        <SMSReview
          open={open_sms_review_dialog}
          closeHandler={() => {
            setOpenSmsReviewDialog(false);
          }}
          sendSMS={sendSelectiveSms}
          sms_content={request.message}
          total_number={total_number}
          total_sms_credit={context.state.balance?.data?.credit}
          in_use_sms_credit={total_sms_credit}
          total_wp_credit={context.state.balance?.data?.wp_credit}
          in_use_wp_credit={total_wp_credit}
          check_wp={checkedWP}
          check_sms={checkedSMS}
        />
      </AuthContainer>
    );
}

export default SendSelective;

const ChipContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const StatsCardContainer = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  min-height: 73px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
`;

const IconAlignContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 5px;
`;

const TextAlignContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding: 16px;
  }
`;

const StatsHeader = styled.h1`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #aaa;
  padding-bottom: 10px;
`;

const StatsTitle = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #a0a0a0;
`;

const StatsValue = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  padding: 0;
`;
