/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContainer from "../../../components/AuthContainer";
import AppContext from "../../../context/store";
import moment from "moment";
import "moment-timezone";
import { toast } from "react-toastify";
import Axios from "axios";

import styled from "styled-components";
import { Grid, Column } from "../../../theme/Grid";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import SemiCirclePie from "../../../components/Charts/SemiCirclePie";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TimelineIcon from "@material-ui/icons/Timeline";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ReceiptIcon from "@material-ui/icons/Receipt";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import RemainingDebt from "../../../assets/images/svg/remaining_debt.svg";

import Loader from "../../../assets/images/loader.gif";

import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import DateRangeIcon from "@material-ui/icons/DateRange";

const CompanyReports = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [reportsDate, setReportsDate] = useState({
    between: {
      start: moment().local().format("DD/MM/YYYY"),
      end: moment().local().format("DD/MM/YYYY"),
    },
  });

  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({
    between: {
      start: moment().local().subtract(30, "days").toISOString(),
      end: moment().local().add(1, "days").toISOString(),
    },
  });
  const [filter_modal_open, SetFilterModalOpen] = useState(false);

  const [readyToUseFilter, setReadyToUseFilter] = useState(null);

  const [filterPicker] = useState([
    {
      id: 1,
      label: "Bugün",
      offset: 1,
    },
    {
      id: 2,
      label: "Bu Hafta",
      offset: 3,
    },
    {
      id: 3,
      label: "Bu Ay",
      offset: 4,
    },
  ]);
  const [selected_filter_picker, setSelected_filter_picker] = useState({
    id: 1,
    label: "Bugün",
    offset: 1,
  });

  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({
    general: {
      max_appointment: 0,
      max_receipt: 0,
      sold_items: 0,
      sold_service: 0,
    },
    accounting: {
      bounty: 0,
      item_income: 0,
      service_income: 0,
      max_expense: 0,
    },
    payment: {
      0: 0,
      1: 0,
      2: 0,
    },
    expense: {
      0: 0,
      1: 0,
      2: 0,
    },
  });
  const [report2, setReport2] = useState(true);
  const [report3, setReport3] = useState(true);
  const [report4, setReport4] = useState(false);

  const drillDetail = (location) => {
    history.push({
      pathname: location,
      state: {
        detail: {
          start: reportsDate.between.start,
          end: reportsDate.between.end,
        },
      },
    });
  };

  const getCompanyReports = async () => {
    setLoading(true);
    Axios.get(`${context.api_endpoint}/company/reports`, 
      selected_filter_picker === null 
        ? {
          params: {
            start: moment(filter.between.start).format("YYYY-MM-DD"),
            end: moment(filter.between.end).format("YYYY-MM-DD"),
            isbetween: true
          }
        }
        : {   
          params: {
            offset: selected_filter_picker.offset,
            isbetween: false,
          }
        },
    )
      .then(({ data: { data } }) => {
        setLoading(false);
        let payment = {};
        let expense = {};
        data.payment_data?.map(
          (p) => (payment[`${p.payment_type}`] = p.amount)
        );
        data.expense_data?.map(
          (p) => (expense[`${p.payment_type}`] = p.amount)
        );

        setLoaded(true);
        setData({
          ...data,
          payment,
          expense,
        });

        console.log(data);


        setReportsDate({
          ...reportsDate,
          between: {
            ...reportsDate.between,
            start: moment(String(data.start_time).split("T")[0])
              .local()
              .format("DD/MM/YYYY"),
            end: moment(String(data.end_time).split("T")[0])
              .local()
              .format("DD/MM/YYYY"),
          },
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          toast.error(err.response.data.message);
          history.push("/wallet");
        } else {
          toast.warning(err.response.data.message);
        }
      });
  };

  useEffect(() => {
    getCompanyReports();
  }, [selected_filter_picker]);

  return (
    <AuthContainer
      authorities={[1]}
      makePlanUpgrade={[2]}
      limited_permission="income_expense"
    >
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-12">
          <Column
            className="xs-12 sm-12 md-12 lg-6"
            style={{
              height: "63px",
              display: "flex",
            }}
          >
            <ShortButtonContainer>
              <ShortButton
                onClick={() => {
                  setSelected_filter_picker(
                    filterPicker.filter((filter) => filter.id === 1)[0]
                  );
                  getCompanyReports();
                }}
                style={{
                  backgroundColor: `${
                    selected_filter_picker?.id === 1
                      ? "rgb(49, 169, 243, 0.14)"
                      : ""
                  }`,
                  border: "1px solid rgb(91, 187, 245, 0.41)",
                  color: "rgb(49, 169, 243)",
                }}
              >
                Bugün
              </ShortButton>

              <ShortButton
                onClick={() => {
                  setSelected_filter_picker(
                    filterPicker.filter((filter) => filter.id === 2)[0]
                  );
                }}
                style={{
                  backgroundColor: `${
                    selected_filter_picker?.id === 2
                      ? "rgb(29, 139, 62, 0.21)"
                      : ""
                  }`,
                  border: "1px solid rgb(58, 154, 87, 0.35)",
                  color: "rgb(29, 139, 62)",
                }}
              >
                Bu Hafta
              </ShortButton>

              <ShortButton
                onClick={() => {
                  setSelected_filter_picker(
                    filterPicker.filter((filter) => filter.id === 3)[0]
                  );
                }}
                style={{
                  backgroundColor: `${
                    selected_filter_picker?.id === 3
                      ? "rgb(254, 151, 151, 0.19)"
                      : ""
                  }`,
                  border: "1px solid rgb(185, 178, 178, 0.65)",
                  color: "rgb(254, 111, 111)",
                }}
              >
                Bu Ay
              </ShortButton>
            </ShortButtonContainer>
          </Column>

          <Column
            className="xs-12 sm-12 md-12 lg-6"
            style={{
              borderLeft: "1px solid #b2b2b2",
              height: "63px",
              display: "flex",
            }}
          >
            <FilterContainer>
              <FilterButton
                onClick={() => {
                  SetFilterModalOpen(true);
                  setSelected_filter_picker(null);
                  setReadyToUseFilter(1);
                  setFilter({
                    between: {
                      start: moment().local().subtract(1, "days").toISOString(),
                      end: moment().local().toISOString(),
                    },
                  });
                  setReportsDate({
                    between: {
                      start: moment()
                        .local()
                        .subtract(1, "days")
                        .format("DD/MM/YYYY"),
                      end: moment().local().format("DD/MM/YYYY"),
                    },
                  });
                }}
              >
                {/* <span style={{ paddingRight: "1vw" }}> */}
                <DateRangeIcon />
                {/* </span> */}
                {/* Zaman Aralığı Seç{" "} */}
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "1.2em",
                    paddingLeft: "1vw",
                  }}
                >
                  {reportsDate.between.start} - {reportsDate.between.end}
                </span>
              </FilterButton>
              <FilterButton
                style={{
                  cursor: "auto",
                  backgroundColor: "rgba(208, 233, 233, 0.65)",
                  color: "#54AEAE",
                  border: "1px solid rgba(208, 233, 233, 0.8)",
                }}
              >
                <img
                  src={RemainingDebt}
                  alt="remaining_debt"
                  style={{ marginRight: "10px", color: "red" }}
                />
                Net Gelir{" "}
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "1.2em",
                    paddingLeft: "1vw",
                  }}
                >
                  {`${(
                    data.accounting_data?.paid_income -
                    data.accounting_data?.total_expense
                  )
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}${
                    context.state.currency ? context.state.currency : "₺"
                  }`}
                </span>
              </FilterButton>

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #b2b2b2",
                  padding: "0.5vh 3vh",
                  borderRadius: "5px",
                }}
              >
                <p>Zaman Aralığı</p>
                <p style={{ fontSize: "1.1em", fontWeight: "500" }}>
                  {reportsDate.between.start} - {reportsDate.between.end}
                </p>
              </div> */}

              {/* <ExcelButton
                route="reports"
                requestData={
                  selected_filter_picker === null
                    ? {
                        start: moment(filter.between.start).format(
                          "YYYY-MM-DD"
                        ),
                        end: moment(filter.between.end).format("YYYY-MM-DD"),
                        isbetween: true,
                        is_excel: true,
                      }
                    : {
                        offset: selected_filter_picker.offset,
                        isbetween: false,
                        is_excel: true,
                      }
                }
              /> */}
            </FilterContainer>
          </Column>
        </Column>
      </Grid>

      {loading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img src={Loader} width="80" height="80" alt="loading" />
          <>Raporlarınız yükleniyor. Lütfen bekleyiniz...</>
        </div>
      ) : (
      <Column>
        <Grid>
          <Column
            className="xs-12 sm-12 md-12 lg-12 mt-1"
            style={{
              borderTop: "1px solid #b2b2b2",
              borderBottom: "1px solid #b2b2b2",
            }}
          >
            {/* info cards */}
            <Column className="xs-12 sm-12 md-12 lg-12 p-3">
              <Column className="xs-12 sm-12 md-12 lg-3">
                <InfoCard
                  style={{
                    backgroundColor: "rgb(49, 169, 243, 0.14)",
                    border: "1px solid rgb(91, 187, 245, 0.41)",
                    color: "rgb(49, 169, 243)",
                  }}
                >
                  <CalendarTodayIcon />
                  <InfoCardText>
                    <p>{data.general_data?.total_appointment}</p>
                    <span>
                      {context.t(`['reports/company']._maxAppointment`)}
                    </span>
                  </InfoCardText>
                </InfoCard>
              </Column>

              <Column className="xs-12 sm-12 md-12 lg-3">
                <InfoCard
                  style={{
                    backgroundColor: "rgb(29, 139, 62, 0.21)",
                    border: "1px solid rgb(58, 154, 87, 0.35)",
                    color: "rgb(29, 139, 62)",
                  }}
                >
                  <ReceiptIcon />
                  <InfoCardText>
                    <p>{data.general_data?.total_receipt}</p>
                    <span>{context.t(`['reports/company']._maxReceipt`)}</span>
                  </InfoCardText>
                </InfoCard>
              </Column>

              <Column className="xs-12 sm-12 md-12 lg-3">
                <InfoCard
                  style={{
                    backgroundColor: "rgb(254, 151, 151, 0.19)",
                    border: "1px solid rgb(185, 178, 178, 0.65)",
                    color: "rgb(254, 111, 111)",
                  }}
                >
                  <LocalGroceryStoreIcon />
                  <InfoCardText>
                    <p>{data.general_data?.sold_items}</p>
                    <span>{context.t(`['reports/company'].soldItem`)}</span>
                  </InfoCardText>
                </InfoCard>
              </Column>

              <Column className="xs-12 sm-12 md-12 lg-3">
                <InfoCard
                  style={{
                    backgroundColor: "rgba(249,186,132, 0.21)",
                    border: "1px solid rgba(249,186,132, 0.51)",
                    color: "rgb(219,146,92)",
                  }}
                >
                  <EventSeatIcon />
                  <InfoCardText>
                    <p>{data.general_data?.total_service_count}</p>
                    <span>{context.t(`['reports/company'].soldService`)}</span>
                  </InfoCardText>
                </InfoCard>
              </Column>
            </Column>
          </Column>
        </Grid>

        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12">
            <ReportsContainer
              style={{
                transition: "all 0.3s ease",
                height: report2 ? "50vh" : "3vh",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ReportsHeader
                  onClick={() => {
                    // setContainerShow(false);
                    // setReport1(false);
                    setReport2((prevValue) => !prevValue);
                    // setReport3(false);
                    // setReport4(false);
                  }}
                >
                  <ReportsHeaderIcon>
                    <TrendingUpIcon fontSize="large" color="primary" />
                  </ReportsHeaderIcon>
                  Gelir Raporları
                </ReportsHeader>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    // setContainerShow(false);
                    // setReport1(false);
                    setReport2((prevValue) => !prevValue);
                    // setReport3(false);
                    // setReport4(false);
                  }}
                >
                  {report2 ? (
                    <ExpandLessIcon fontSize="large" />
                  ) : (
                    <ExpandMoreIcon fontSize="large" />
                  )}
                </div>
              </div>
              {report2 && (
                <Column className="xs-12 sm-12 md-12 lg-12">
                  <Column
                    className="xs-12 sm-12 md-12 lg-12"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {/* chart */}
                    <Column className="xs-12 sm-12 md-12 lg-9">
                      {loaded && (
                        <SemiCirclePie
                          data={[
                            {
                              label: context.t(`['reports/company'].typeCASH`),
                              count: data.payment[0]
                                ? parseFloat(
                                    data.payment["0"].toFixed(2)
                                    // .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                  )
                                : 0,
                            },
                            {
                              label: context.t(`['reports/company'].typeCARD`),
                              count: data.payment[1]
                                ? parseFloat(
                                    data.payment["1"].toFixed(2)
                                    // .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                  )
                                : 0,
                            },
                            {
                              label: context.t(`['reports/company'].typeEFT`),
                              count: data.payment[2]
                                ? parseFloat(
                                    data.payment["2"].toFixed(2)
                                    // .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                  )
                                : 0,
                            },
                          ]}
                          colors={["#006159", "#00796f", "#009589"]}
                          labelKey="label"
                          labelText={`{category}\n{value.percent.formatNumber('#.0')}% ({value}${
                            context.state.currency ? context.state.currency : "₺"
                          })`}
                          valueKey="count"
                        />
                      )}
                    </Column>

                    {/* raport kutuları */}
                    <Column className="xs-12 sm-12 md-12 lg-3">
                      <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
                        <InfoCard
                          style={{
                            backgroundColor: "rgb(49, 169, 243, 0.14)",
                            border: "1px solid rgb(91, 187, 245, 0.41)",
                            color: "rgb(49, 169, 243)",
                          }}
                        >
                          <InfoCardText>
                            <p>{`${
                              data.payment["0"]
                                ? data.payment["0"]
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                : "0.00"
                            }${
                              context.state.currency
                                ? context.state.currency
                                : "₺"
                            }`}</p>
                            <span>
                              {context.t(`['reports/company'].typeCASH`)}
                            </span>
                          </InfoCardText>
                        </InfoCard>
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
                        <InfoCard
                          style={{
                            backgroundColor: "rgb(29, 139, 62, 0.21)",
                            border: "1px solid rgb(58, 154, 87, 0.35)",
                            color: "rgb(29, 139, 62)",
                          }}
                        >
                          <InfoCardText>
                            <p>{`${
                              data.payment["1"]
                                ? data.payment["1"]
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                : "0.00"
                            }${
                              context.state.currency
                                ? context.state.currency
                                : "₺"
                            }`}</p>
                            <span>
                              {context.t(`['reports/company'].typeCARD`)}
                            </span>
                          </InfoCardText>
                        </InfoCard>
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
                        <InfoCard
                          style={{
                            backgroundColor: "rgb(254, 151, 151, 0.19)",
                            border: "1px solid rgb(185, 178, 178, 0.65)",
                            color: "rgb(254, 111, 111)",
                          }}
                        >
                          <InfoCardText>
                            <p>{`${
                              data.payment["2"]
                                ? data.payment["2"]
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                : "0.00"
                            }${
                              context.state.currency
                                ? context.state.currency
                                : "₺"
                            }`}</p>
                            <span>
                              {context.t(`['reports/company'].typeEFT`)}
                            </span>
                          </InfoCardText>
                        </InfoCard>
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
                        <InfoCard
                          style={{
                            backgroundColor: "rgba(249,186,132, 0.21)",
                            border: "1px solid rgba(249,186,132, 0.51)",
                            color: "rgb(219,146,92)",
                          }}
                        >
                          <InfoCardText>
                            <p>{`${(
                              (data.payment["0"] ? data.payment["0"] : 0) +
                              (data.payment["1"] ? data.payment["1"] : 0) +
                              (data.payment["2"] ? data.payment["2"] : 0)
                            )
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}${
                              context.state.currency
                                ? context.state.currency
                                : "₺"
                            }`}</p>
                            <span>
                              {context.t(`['reports/company'].generalIncome`)}
                            </span>
                          </InfoCardText>
                        </InfoCard>
                      </Column>
                    </Column>
                  </Column>
                  <Column
                    className="xs-12 sm-12 md-12 lg-9"
                    style={{
                      marginTop: "-70px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Detail
                      onClick={() => {
                        drillDetail("/accounting/income");
                      }}
                    >
                      <p>Detaya git</p>
                      <span>
                        <ChevronRightIcon
                          fontSize="large"
                          style={{ marginTop: "10px" }}
                        />
                      </span>
                    </Detail>
                  </Column>
                </Column>
              )}
            </ReportsContainer>
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-12">
            <ReportsContainer
              style={{
                transition: "all 0.3s ease",
                height: report3 ? "50vh" : "3vh",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ReportsHeader
                  onClick={() => {
                    // setContainerShow(false);
                    // setReport1(false);
                    // setReport2(false);
                    setReport3((prevValue) => !prevValue);
                    // setReport4(false);
                  }}
                >
                  <ReportsHeaderIcon>
                    <TrendingDownIcon fontSize="large" color="secondary" />
                  </ReportsHeaderIcon>
                  Gider Raporları
                </ReportsHeader>
                {report3 ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      // setContainerShow(false);
                      // setReport1(false);
                      // setReport2(false);
                      setReport3((prevValue) => !prevValue);
                      // setReport4(false);
                    }}
                  >
                    <ExpandLessIcon fontSize="large" />
                  </div>
                ) : (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      // setContainerShow(false);
                      // setReport1(false);
                      // setReport2(false);
                      setReport3((prevValue) => !prevValue);
                      // setReport4(false);
                    }}
                  >
                    <ExpandMoreIcon fontSize="large" />
                  </div>
                )}
              </div>
              {report3 && (
                <Column className="xs-12 sm-12 md-12 lg-12">
                  {/* chart */}
                  <Column className="xs-12 sm-12 md-12 lg-9">
                    {loaded && (
                      <SemiCirclePie
                        data={[
                          {
                            label: context.t(`['reports/company'].typeCASH`),
                            count: data.expense[0]
                              ? parseFloat(
                                  data.expense["0"].toFixed(2)
                                  // .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                )
                              : 0,
                          },
                          {
                            label: context.t(`['reports/company'].typeCARD`),
                            count: data.expense[1]
                              ? parseFloat(
                                  data.expense["1"].toFixed(2)
                                  // .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                )
                              : 0,
                          },
                          {
                            label: context.t(`['reports/company'].typeEFT`),
                            count: data.expense[2]
                              ? parseFloat(
                                  data.expense["2"].toFixed(2)
                                  // .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                )
                              : 0,
                          },
                        ]}
                        colors={["#37474F", "#546E7A", "#90A4AE"]}
                        labelKey="label"
                        labelText={`{category}\n{value.percent.formatNumber('#.0')}% ({value}${
                          context.state.currency ? context.state.currency : "₺"
                        })`}
                        valueKey="count"
                      />
                    )}
                  </Column>
                  {/* Rapor kartları */}
                  <Column className="xs-12 sm-12 md-12 lg-3">
                    <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
                      <InfoCard
                        style={{
                          backgroundColor: "rgb(49, 169, 243, 0.14)",
                          border: "1px solid rgb(91, 187, 245, 0.41)",
                          color: "rgb(49, 169, 243)",
                        }}
                      >
                        <InfoCardText>
                          <p>{`${
                            data.expense["0"]
                              ? data.expense["0"]
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                              : "0.00"
                          }${
                            context.state.currency ? context.state.currency : "₺"
                          }`}</p>
                          <span>{context.t(`['reports/company'].typeCASH`)}</span>
                        </InfoCardText>
                      </InfoCard>
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
                      <InfoCard
                        style={{
                          backgroundColor: "rgb(29, 139, 62, 0.21)",
                          border: "1px solid rgb(58, 154, 87, 0.35)",
                          color: "rgb(29, 139, 62)",
                        }}
                      >
                        <InfoCardText>
                          <p>{`${
                            data.expense["1"]
                              ? data.expense["1"]
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                              : "0.00"
                          }${
                            context.state.currency ? context.state.currency : "₺"
                          }`}</p>
                          <span>{context.t(`['reports/company'].typeCARD`)}</span>
                        </InfoCardText>
                      </InfoCard>
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
                      <InfoCard
                        style={{
                          backgroundColor: "rgb(254, 151, 151, 0.19)",
                          border: "1px solid rgb(185, 178, 178, 0.65)",
                          color: "rgb(254, 111, 111)",
                        }}
                      >
                        <InfoCardText>
                          <p>{`${
                            data.expense["2"]
                              ? data.expense["2"]
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                              : "0.00"
                          }${
                            context.state.currency ? context.state.currency : "₺"
                          }`}</p>
                          <span>{context.t(`['reports/company'].typeEFT`)}</span>
                        </InfoCardText>
                      </InfoCard>
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
                      <InfoCard
                        style={{
                          backgroundColor: "rgba(249,186,132, 0.21)",
                          border: "1px solid rgba(249,186,132, 0.51)",
                          color: "rgb(219,146,92)",
                        }}
                      >
                        <InfoCardText>
                          <p>{`${(
                            (data.expense["0"] ? data.expense["0"] : 0) +
                            (data.expense["1"] ? data.expense["1"] : 0) +
                            (data.expense["2"] ? data.expense["2"] : 0)
                          )
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}${
                            context.state.currency ? context.state.currency : "₺"
                          }`}</p>
                          <span>
                            {context.t(`['reports/company'].maxExpense`)}
                          </span>
                        </InfoCardText>
                      </InfoCard>
                    </Column>
                  </Column>

                  <Column
                    className="xs-12 sm-12 md-12 lg-9"
                    style={{
                      marginTop: "-70px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Detail
                      onClick={() => {
                        drillDetail("/accounting/expense");
                      }}
                    >
                      <p>Detaya git</p>
                      <span>
                        <ChevronRightIcon
                          fontSize="large"
                          style={{ marginTop: "10px" }}
                        />
                      </span>
                    </Detail>
                  </Column>
                </Column>
              )}
            </ReportsContainer>
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-12">
            <ReportsContainer
              style={{
                transition: "all 0.3s ease",
                height: report4 ? "40vh" : "3vh",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ReportsHeader
                  onClick={() => {
                    // setContainerShow(false);
                    // setReport1(false);
                    // setReport2(false);
                    // setReport3(false);
                    setReport4((prevValue) => !prevValue);
                  }}
                  style={{ cursor: "pointer", width: "100%" }}
                >
                  <ReportsHeaderIcon>
                    <TimelineIcon color="action" fontSize="large" />
                  </ReportsHeaderIcon>
                  Özet
                </ReportsHeader>
                {report4 ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      // setContainerShow(false);
                      // setReport1(false);
                      // setReport2(false);
                      // setReport3(false);
                      setReport4((prevValue) => !prevValue);
                    }}
                  >
                    <ExpandLessIcon fontSize="large" />
                  </div>
                ) : (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      // setContainerShow(false);
                      // setReport1(false);
                      // setReport2(false);
                      // setReport3(false);
                      setReport4((prevValue) => !prevValue);
                    }}
                  >
                    <ExpandMoreIcon fontSize="large" />
                  </div>
                )}
              </div>
              {report4 && (
                <Column className="xs-12 sm-12 md-12 lg-12">
                  <Column className="xs-12 sm-12 md-12 lg-12">
                    {/* Chart */}
                    <Column className="xs-12 sm-12 md-12 lg-9">
                      {loaded && (
                        <SemiCirclePie
                          data={[
                            {
                              label: context.t(`['reports/company'].totalIncome`),
                              count: data.accounting_data?.paid_income,
                            },
                            {
                              label: context.t(`['reports/company'].maxExpense`),
                              count: data.accounting_data?.total_expense,
                            },
                          ]}
                          colors={["#63b75d", "#e7272d"]}
                          labelKey="label"
                          labelText={`{category}\n{value.percent.formatNumber('#.0')}% ({value}${
                            context.state.currency ? context.state.currency : "₺"
                          })`}
                          valueKey="count"
                        />
                      )}
                    </Column>
                    {/* rapor kartları */}
                    <Column className="xs-12 sm-12 md-12 lg-3">
                      <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
                        <InfoCard
                          style={{
                            backgroundColor: "rgb(49, 169, 243, 0.14)",
                            border: "1px solid rgb(91, 187, 245, 0.41)",
                            color: "rgb(49, 169, 243)",
                          }}
                        >
                          <InfoCardText>
                            <p>{`${parseFloat(data.accounting_data?.paid_income)
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}${
                              context.state.currency
                                ? context.state.currency
                                : "₺"
                            }`}</p>
                            <span>
                              {context.t(`['reports/company'].totalIncome`)}
                            </span>
                          </InfoCardText>
                        </InfoCard>
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
                        <InfoCard
                          style={{
                            backgroundColor: "rgb(29, 139, 62, 0.21)",
                            border: "1px solid rgb(58, 154, 87, 0.35)",
                            color: "rgb(29, 139, 62)",
                          }}
                        >
                          <InfoCardText>
                            <p>{`${data.accounting_data?.total_expense
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}${
                              context.state.currency
                                ? context.state.currency
                                : "₺"
                            }`}</p>
                            <span>
                              {context.t(`['reports/company'].maxExpense`)}
                            </span>
                          </InfoCardText>
                        </InfoCard>
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
                        <InfoCard
                          style={{
                            backgroundColor: "rgb(254, 151, 151, 0.19)",
                            border: "1px solid rgb(185, 178, 178, 0.65)",
                            color: "rgb(254, 111, 111)",
                          }}
                        >
                          <InfoCardText>
                            <p>{`${(
                              data.accounting_data?.paid_income -
                              data.accounting_data?.total_expense
                            )
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}${
                              context.state.currency
                                ? context.state.currency
                                : "₺"
                            }`}</p>
                            <span>
                              {context.t(`['reports/company'].netIncome`)}
                            </span>
                          </InfoCardText>
                        </InfoCard>
                      </Column>
                    </Column>
                  </Column>
                </Column>
              )}
            </ReportsContainer>
          </Column>
        </Grid>
      </Column>
      )}

      {/* 
      **
      Tarih seçmek için açılan dialog 
      */}

      <AlertDialog
        title={<h3 style={{ textAlign: "center" }}>Zaman Aralığı Seçiniz</h3>}
        disableBackdropClick={false}
        open={filter_modal_open}
        maxWidth="sm"
        closeHandler={() => {
          SetFilterModalOpen(false);
        }}
        buttons={[
          {
            title: "Onayla",
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {
              getCompanyReports();
            },
          },
          {
            title: "İptal",
            icon: "close",
            textColor: "grey",
            onClick: () => {
              setReadyToUseFilter(null);
              setSelected_filter_picker({
                id: 1,
                label: "Bugün",
                offset: 1,
              });
            },
          },
        ]}
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12 mt-3 mb-5">
            <Column className="xs-12 sm-12 md-12 lg-6">
              <ShortButtonContainer
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <ShortButton
                  style={{
                    border: "1px solid rgba(249,186,132, 0.51)",
                    color: "rgb(219,146,92)",
                    backgroundColor: `${
                      readyToUseFilter === 1 ? "rgba(249,186,132, 0.21)" : ""
                    }`,
                  }}
                  onClick={() => {
                    setReadyToUseFilter(1);
                    setFilter({
                      between: {
                        start: moment()
                          .local()
                          .subtract(1, "days")
                          .toISOString(),
                        end: moment().local().toISOString(),
                      },
                    });
                    setReportsDate({
                      between: {
                        start: moment()
                          .local()
                          .subtract(1, "days")
                          .format("DD/MM/YYYY"),
                        end: moment().local().format("DD/MM/YYYY"),
                      },
                    });
                  }}
                >
                  {context.t(`component.dateRangeStats.yesterday`)}
                </ShortButton>
                <ShortButton
                  style={{
                    border: "1px solid rgb(91, 187, 245, 0.41)",
                    color: "rgb(49, 169, 243)",
                    backgroundColor: `${
                      readyToUseFilter === 2 ? "rgb(49, 169, 243, 0.14)" : ""
                    }`,
                  }}
                  onClick={() => {
                    setReadyToUseFilter(2);
                    setFilter({
                      between: {
                        start: moment()
                          .local()
                          .subtract(7, "days")
                          .toISOString(),
                        end: moment().local().toISOString(),
                      },
                    });
                    setReportsDate({
                      between: {
                        start: moment()
                          .local()
                          .subtract(7, "days")
                          .format("DD/MM/YYYY"),
                        end: moment().local().format("DD/MM/YYYY"),
                      },
                    });
                  }}
                >
                  {context.t(`component.dateRangeStats.lastWeek`)}
                </ShortButton>
                <ShortButton
                  style={{
                    border: "1px solid rgb(58, 154, 87, 0.35)",
                    color: "rgb(29, 139, 62)",
                    backgroundColor: `${
                      readyToUseFilter === 3 ? "rgb(29, 139, 62, 0.21)" : ""
                    }`,
                  }}
                  onClick={() => {
                    setReadyToUseFilter(3);
                    setFilter({
                      between: {
                        start: moment()
                          .local()
                          .subtract(15, "days")
                          .toISOString(),
                        end: moment().local().toISOString(),
                      },
                    });
                    setReportsDate({
                      between: {
                        start: moment()
                          .local()
                          .subtract(15, "days")
                          .format("DD/MM/YYYY"),
                        end: moment().local().format("DD/MM/YYYY"),
                      },
                    });
                  }}
                >
                  {context.t(`component.dateRangeStats.last_15_days`)}
                </ShortButton>
                <ShortButton
                  style={{
                    border: "1px solid rgb(185, 178, 178, 0.65)",
                    color: "rgb(254, 111, 111)",
                    backgroundColor: `${
                      readyToUseFilter === 4 ? "rgb(254, 151, 151, 0.19)" : ""
                    }`,
                  }}
                  onClick={() => {
                    setReadyToUseFilter(4);
                    setFilter({
                      between: {
                        start: moment()
                          .local()
                          .subtract(30, "days")
                          .toISOString(),
                        end: moment().local().toISOString(),
                      },
                    });
                    setReportsDate({
                      between: {
                        start: moment()
                          .local()
                          .subtract(30, "days")
                          .format("DD/MM/YYYY"),
                        end: moment().local().format("DD/MM/YYYY"),
                      },
                    });
                  }}
                >
                  {context.t(`component.dateRangeStats.lastMonth`)}
                </ShortButton>
              </ShortButtonContainer>
            </Column>

            <Column
              className="xs-12 sm-12 md-12 lg-6"
              style={{ height: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderLeft: "1px solid #b2b2b2",
                  height: "100%",
                }}
              >
                <Column className="xs-12 sm-12 md-12 lg-12">
                  <DatePickerInput
                    label={context.t(`reports.startInputLabel`)}
                    value={new Date(filter.between.start)}
                    maxDate={new Date(filter.between.end)}
                    onChange={async (date) => {
                      setReadyToUseFilter(null);
                      setFilter({
                        ...filter,
                        between: {
                          ...filter.between,
                          start: moment(date).local().toISOString(),
                        },
                      });
                      setReportsDate({
                        ...reportsDate,
                        between: {
                          ...reportsDate.between,
                          start: moment(date).local().format("DD/MM/YYYY"),
                        },
                      });
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12">
                  <DatePickerInput
                    label={context.t(`reports.endInputLabel`)}
                    value={new Date(String(filter.between.end).split("T")[0])}
                    minDate={filter.between.start}
                    onChange={async (date) => {
                      setReadyToUseFilter(null);
                      setFilter({
                        ...filter,
                        between: {
                          ...filter.between,
                          end: moment(date).local().toISOString(),
                        },
                      });
                      setReportsDate({
                        ...reportsDate,
                        between: {
                          ...reportsDate.between,
                          end: moment(date).local().format("DD/MM/YYYY"),
                        },
                      });
                    }}
                  />
                </Column>
              </div>
            </Column>
          </Column>
        </Grid>
      </AlertDialog>
    </AuthContainer>
  );
};

export default CompanyReports;

const FAKE_TABLE_HEADINGS = {
  label: { label: "", style: { width: "100%", fontWeight: "600" } },
  count: { label: "" },
};

const ReportsContainer = styled.div`
  border-bottom: 1px solid #b2b2b2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em;
  flex-direction: column;
  overflow: hidden;
`;

const InfoCard = styled.div`
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
`;

const InfoCardText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 13px;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
`;

const ShortButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ShortButton = styled.div`
  border-radius: 8px;
  width: 12vw;
  padding: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
`;

const FilterButton = styled.div`
  border-radius: 8px;
  padding: 1vh 2.5vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  margin-bottom: 10px;
  color: rgb(219, 146, 92);
  border: 1px solid rgba(249, 186, 132, 0.51);
  background-color: rgba(249, 186, 132, 0.21);
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

const Detail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center
  padding: 20vh;
  background-color: rgba(140, 15, 180, 0.08);
  color: purple;
  border-radius: 50px;
  width: 20vh;
  cursor: pointer;
  height: 5.5vh;

  p {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 !important;
  }

  span {
    width: 20%;
  }
`;

const ReportsHeader = styled.h3`
  width: 100%;
  cursor: pointer;
  font-size: 1.8em;
  font-weight: 200;
  display: flex;
  align-items: center;
`;

const ReportsHeaderIcon = styled.span`
  padding-right: 10px;
`;
