import Axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import AuthContainer from "../../../../components/AuthContainer";
import AppContext from "../../../../context/store";
import Button from "../../../../theme/Button";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import Input from "../../../../theme/CustomMUI/Input";
import { Column, Grid } from "../../../../theme/Grid";
import Table from "../../../../theme/Table";
import Alert from "@material-ui/lab/Alert";
import CalculateMessageCharCount from "../../../../functions/CalculateMessageCharCount";

const Template = () => {
  const context = useContext(AppContext);

  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    total_page: null,
    page: null,
    onChange: (page) => getTemplates(page)
  }); 
  const [templates, setTemplates] = useState([]);

  const [newTemplate, setNewTemplate] = useState({
    id: null,
    title: null,
    content: ""
  });

  const [delete_id, setDeleteId] = useState(null); 

  const [open_template_dialog, setOpenTemplateDialog] = useState(false);
  const [open_delete_template_dialog, setOpenDeleteTemplateDialog] = useState(false);

  const getTemplates = (page = 1) => {
    Axios.get(
      `${context.api_endpoint}/company/sms/templates?page${page}`
    ).then(({ data }) => {
      setLoaded(true);
      setPagination({
        ...pagination,
        total_page: data.data.records.length,
        page: data.page
      });
      setTemplates([...data.data.records]);
    });
  };

  const addTemplate = () => {
    Axios.post(`${context.api_endpoint}/company/sms/template/insert`, {
      name: newTemplate.title,
      content: newTemplate.content,
    })
      .then(({ data }) => {
        setNewTemplate({
          ...newTemplate,
          id: null,
          title: null,
          content: ""
        });
        setOpenTemplateDialog(false);
        toast.success(context.t(`['sms/template'].addSuccessToast`));
        getTemplates();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const updateTemplate = () => {
    Axios.put(`${context.api_endpoint}/company/sms/template/update`, {
      id: newTemplate.id,
      name: newTemplate.title,
      content: newTemplate.content,
    })
      .then( ({ data }) => {
        setNewTemplate({
          ...newTemplate,
          id: null,
          title: null,
          content: ""
        });
        toast.success(context.t(`['sms/template'].updateSuccessToast`));
        getTemplates();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const deleteTemplate = (id) => {
    Axios.post(`${context.api_endpoint}/company/sms/template/delete`, {
      id: id,
    })
      .then(({ data }) => {
        setDeleteId(null);
        setOpenDeleteTemplateDialog(false);
        toast.success(context.t(`['sms/template'].deleteSuccessToast`));
        getTemplates();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => getTemplates());

    return (
      <AuthContainer authorities={[1]} limited_permission="sms">
        <Grid>
          <Grid className="mb-2">
            <Button
              title={context.t(
                `['sms/template'].createTemplateButtonTitle`
              )}
              icon="add"
              backgroundColor="primary"
              textColor="white"
              onClick={() => {
                setOpenTemplateDialog(true);
              }}
            />
          </Grid>
          <Column className="xs-12 sm-12 md-12">
            <Table
              loaded={loaded}
              headings={{
                name: {
                  label: context.t(
                    `['sms/template'].templatesHeadings.name`
                  ),
                },
                content: {
                  label: context.t(
                    `['sms/template'].templatesHeadings.content`
                  ),
                  limited_line: 1,
                  style: {
                    maxWidth: "350px",
                  },
                },
                _: { label: context.t(`component.actionHeadingText`) },
              }}
              rows={templates}
              pagination={pagination}
              showAlwaysPagination
              buttons={[
                {
                  title: context.t(`component.detailEditButtonTitle`),
                  icon: "launch",
                  textColor: "primary",
                  transitionEffect: true,
                  pushEffect: true,
                  onClick: (row) => {
                    setOpenTemplateDialog(true);
                    setNewTemplate({
                      ...newTemplate,
                      id: row.id,
                      title: row.name,
                      content: row.content
                    });
                  },
                },
                {
                  title: context.t(`['sms/template'].deleteButtonTitle`),
                  icon: "delete_forever",
                  transitionEffect: true,
                  textColor: "red",
                  onClick: (row) => {
                    setDeleteId(row.id);
                    setOpenDeleteTemplateDialog(true);
                  },
                },
              ]}
            />
          </Column>

          <AlertDialog
            title={context.t(
              `['sms/template'].templateDialog.${
                newTemplate.id ? "updateTitle" : "addTitle"
              }`
            )}
            open={open_template_dialog}
            fullWidth={true}
            disableBackdropClick={false}
            buttons={[
              {
                title: context.t(
                  `['sms/template'].templateDialog.${
                    newTemplate.id ? "updateButtonTitle" : "createButtonTitle"
                  }`
                ),
                icon: "check",
                onClick: () => {
                  // newTemplate.id
                  //   ? updateTemplate()
                  //   : newTemplate.title && newTemplate.content
                  //   ? addTemplate()
                  //   : toast.warning("Gerekli alanları doldurunuz.");
                  if (newTemplate.id) {
                    updateTemplate();
                  } else {
                    if (newTemplate.title && newTemplate.content) {
                      addTemplate();
                    } else {
                      setOpenTemplateDialog(true);
                      toast.warning("Gerekli alanları doldurunuz.");
                    }
                  }
                },
              },
              {
                title: context.t(
                  `['sms/template'].templateDialog.discardButtonTitle`
                ),
                icon: "close",
                textColor: "red",
              },
            ]}
            closeHandler={() => {
              setOpenTemplateDialog(false);
            }}
          >
            <Input
              label={context.t(
                `['sms/template'].templateDialog.templateTitleInputLabel`
              )}
              placeholder={context.t(
                `['sms/template'].templateDialog.templateTitleInputPlaceholder`
              )}
              value={newTemplate.title ?? ""}
              onChange={(e) => {
                setNewTemplate({
                  ...newTemplate,
                  title: e.target.value,
                });
              }}
            />
            <Input
              label={context.t(
                `['sms/template'].templateDialog.templateContentInputLabel`
              )}
              multiline={true}
              rows={10}
              value={newTemplate.content ?? ""}
              helperText={CalculateMessageCharCount(newTemplate.content, 1)}
              onChange={(e) => {
                setNewTemplate({
                  ...newTemplate,
                  content: e.target.value
                });
              }}
            />
          </AlertDialog>
          <AlertDialog
            title={context.t(`['sms/template'].deleteConfirm.title`)}
            open={open_delete_template_dialog}
            disableBackdropClick={false}
            buttons={[
              {
                title: context.t(
                  `['sms/template'].deleteConfirm.confirmButtonTitle`
                ),
                disabledHandleClose: true,
                onClick: () => deleteTemplate(delete_id),
              },
              {
                title: context.t(
                  `['sms/template'].deleteConfirm.discardButtonTitle`
                ),
              },
            ]}
            closeHandler={() => {
              setDeleteId(null);
              setOpenDeleteTemplateDialog(false);
            }}
          >
            <Alert severity="warning">
              <b>
                {context.t(`['sms/template'].deleteConfirm.alertBoldText`)}
              </b>
              <br />
              {context.t(`['sms/template'].deleteConfirm.alertText`)}
            </Alert>
          </AlertDialog>
        </Grid>
      </AuthContainer>
    );
  
}

export default Template;
