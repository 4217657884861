import React, { useContext } from "react";
import AppContext from "../../context/store";

import { Grid, Column } from "../../theme/Grid";
import styled from "styled-components";

// import SemiCirclePie from "../../components/Charts/SemiCirclePie";

const Care = () => {
  const context = useContext(AppContext);
  return (
    <Grid>
      <Column className="xs-12 sm-12">
        <Container404
          className={
            context.state.is_authenticated === false ? "mt-5" : null
          }
        >
          <Image404 src={require("../../assets/images/undraw/care.png")} />
          <InfoCaption>Bakımdayız...</InfoCaption>
          <InfoText>
            <b>
              Sizlere daha iyi hizmet verebilmek için
              <br />
              10:45 - 01:00 arasında bakım yapılacaktır.
            </b>
          </InfoText>
        </Container404>
      </Column>
    </Grid>
  );
}

export default Care;

const Container404 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 220px;
`;

const Image404 = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 32px;
`;

const InfoCaption = styled.h2`
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
`;

const InfoText = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 2;
  padding: 0;
  margin: 0 0 16px;

  b {
    font-weight: 500;
  }
`;
