import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../../context/store";
import styled from "styled-components";
import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import { Update, Launch, ExitToApp } from "@material-ui/icons";
import Button from "../../../theme/Button";
import Axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import AuthWithToken from "../../../functions/AuthWithToken";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Input from "../../../theme/CustomMUI/Input";
import { ButtonBase } from "@material-ui/core";
import BranchAdd from "./InPanelAdd";
import AuthContainer from "../../../components/AuthContainer";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const BranchesIndex = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [heroLoaded, setHeroLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [TABTYPE, setTABTYPE] = useState(0);

  const [branches, setBranches] = useState([
    ...context.state.company_branches?.map((item) => ({
      ...item,
      license: {
        ...item.license,
        end_date_text: item.license?.end_date
          ? moment(item.license.end_date).local().format("DD/MM/YYYY HH:mm")
          : null,
      },
    }))
  ]);

  const [selectedBranch, setSelectedBranch] = useState({});
  
  const [branchReports, setBranchReports] = useState([]);

  const [branchPermitted, setBranchPermitted] = useState({
    staff_id: null,
    staff_name: null,
    staff_surname: null,
    staff_phone: null,
    staff_mail: null,
    staff_password: null,
  });
  const [branchPermittedModal, setBranchPermittedModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
      setHeroLoaded(true);
    }, 250);
  }, []);

  const logInBranch = (staff_id) => {
    const date = new Date();
    if (date.getMinutes() === 0) date.setMinutes(1);
    if (date.getSeconds() === 0) date.setSeconds(1);

    Axios.post(`${context.api_endpoint}/auth/staff?auth=staff-by-id`, {
      owner_id: context.state.user.id * date.getMinutes(),
      staff_id: parseInt(staff_id) * date.getSeconds(),
      time: `${moment(date).format("YYYY-MM-DD")}T${moment(date).format(
        "HH:mm:ss"
      )}Z`,
    })
      .then(({ data }) => {
        AuthWithToken(data.data.token, context).then(() => {
          history.push("/dashboard");
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const branchPermittedUpdate = () => {
    Axios.put(`${context.api_endpoint}/company/dealer/update`, {
      ...branchPermitted,
    })
      .then((response) => {
        response.status === 201 &&
          toast.success("Çalışan bilgileri başarıyla güncellendi!");
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };



  /**
   * _TabNavigatorBuilder içerisinde kullanılacak olan tekli tab buttonun builder fonksiyonudur.
   */
  const _NavigatorSingleTabBuilder = ({ title, control, onClick }) => {
    return (
      <TNavigatorTab>
        <ButtonBase className={control} onClick={onClick}>
          {title}
        </ButtonBase>
      </TNavigatorTab>
    );
  };

  const _BranchListBuilder = () => {
    return (
      <BranchContainer>
        <Grid>
          <Column className="xs-12 sm-12">
            <Table
              loaded={loaded}
              rows={[context.state.company_branch_first, ...branches]}
              headings={{
                id: { label: context.t(`branches.headings.listTable.id`) },
                name: {
                  label: context.t(
                    `branches.headings.listTable.companyName`
                  ),
                },
                "staffs[0].full_name": {
                  label: context.t(
                    `branches.headings.listTable.staffFullName`
                  ),
                },
                "staffs[0].detail.phone": {
                  label: context.t(
                    `branches.headings.listTable.staffPhoneNumber`
                  ),
                },
                "staffs[0].detail.mail": {
                  label: context.t(
                    `branches.headings.listTable.staffMail`
                  ),
                },
                "license.end_date_text": {
                  label: context.t(
                    `branches.headings.listTable.licenseEndDate`
                  ),
                },
                _: { label: context.t(`component.actionHeadingText`) },
              }}
              buttons={[
                {
                  iconComponent: Launch,
                  title: context.t(`branches.loginBranchButtonTitle`),
                  textColor: "blue",
                  onClick: (row) => {
                    logInBranch(row.staffs[0].id);
                  },
                },
                {
                  iconComponent: Update,
                  title: context.t(`branches.editStaffButtonTitle`),
                  textColor: "green",
                  onClick: async (row) => {
                    if (new Date(row.license.end_date) > new Date()) {
                      await setSelectedBranch({...row});
                      await setBranchPermitted({
                        staff_id: row.staffs[0].id,
                        staff_name: row.staffs[0].name,
                        staff_surname: row.staffs[0].surname,
                        staff_phone: row.staffs[0].detail.phone,
                        staff_mail: row.staffs[0].detail.mail,
                        staff_password: "",
                      });
                      await setBranchPermittedModal(true);
                    } else
                      toast.warning(
                        context.t(`branches.licenseExpiredEditToast`)
                      );
                  },
                },
              ]}
            />
          </Column>
        </Grid>

        <AlertDialog
          title={`${selectedBranch?.name} Yetkilisini Düzenle`}
          open={branchPermittedModal}
          maxWidth="md"
          fullWidth
          closeHandler={() => setBranchPermittedModal(false)}
          disableBackdropClick={false}
          buttons={[
            {
              icon: "check",
              title: context.t(`branches.completeEditingButtonTitle`),
              backgroundColor: "blue-opacity",
              onClick: () => branchPermittedUpdate(),
            },
            {
              icon: "close",
              title: context.t(`branches.discardEditingButtonTitle`),
              textColor: "grey",
            },
          ]}
        >
          <Input
            label="Yetkili Adı"
            value={branchPermitted.staff_name}
            onChange={(e) =>
              setBranchPermitted({
                ...branchPermitted,
                staff_name: e.target.value,
              })
            }
          />
          <Input
            label="Yetkili Soyadı"
            value={branchPermitted.staff_surname}
            onChange={(e) =>
              setBranchPermitted({
                ...branchPermitted,
                staff_surname: e.target.value,
              })
            }
          />
          <Input
            label="Yetkili Maili"
            value={branchPermitted.staff_mail}
            onChange={(e) =>
              setBranchPermitted({
                ...branchPermitted,
                staff_mail: e.target.value,
              })
            }
          />
          <Input
            label="Yetkili Telefonu"
            value={branchPermitted.staff_phone}
            onChange={(e) =>
              setBranchPermitted({
                ...branchPermitted,
                staff_phone: e.target.value,
              })
            }
          />
        </AlertDialog>
      </BranchContainer>
    );
  };

  const _BranchReportsBuilder = () => {
    useEffect(() => {
      setLoaded(false);
      Axios.get(`${context.api_endpoint}/company/dealer/reports`)
        .then(({ data: { data } }) => {
          setLoaded(true);
          setBranchReports([
            ...data.map((branch) => ({
              ...branch,
              staff_count: `${branch.staff_count}`,
              appointment_count: `${branch.appointment_count}`,
              receipt_count: `${branch.receipt_count}`,
              service_income: `${branch.service_income?.toFixed(2)}`,
              sale_income: `${branch.sale_income?.toFixed(2)}`,
              staff_bounty: `${branch.staff_bounty?.toFixed(2)}`,
              total_income: `${branch.total_income?.toFixed(2)}`,
              total_expense: `${branch.total_expense?.toFixed(2)}`,
              total_count: `${(
                branch.total_income - branch.total_expense
              )?.toFixed(2)}`,
            })),
          ]);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    }, []);

    return (
      <BranchContainer>
        <Grid>
          <Column className="xs-12 sm-12">
            <Table
              loaded={loaded}
              rows={[
                ...branchReports,
                {
                  company_name: "Toplam",
                  staff_count: sum("staff_count"),
                  appointment_count: sum("appointment_count"),
                  receipt_count: sum("receipt_count"),
                  service_income: sum("service_income"),
                  sale_income: sum("sale_income"),
                  staff_bounty: sum("staff_bounty"),
                  total_income: sum("total_income"),
                  total_expense: sum("total_expense"),
                  total_count: sum("total_count"),
                },
              ]}
              headings={{
                company_name: {
                  label: context.t(
                    `branches.headings.reportsTable.companyName`
                  ),
                },
                staff_count: {
                  label: context.t(
                    `branches.headings.reportsTable.staffCount`
                  ),
                },
                appointment_count: {
                  label: context.t(
                    `branches.headings.reportsTable.appointmentCount`
                  ),
                },
                receipt_count: {
                  label: context.t(
                    `branches.headings.reportsTable.receiptCount`
                  ),
                },
                service_income: {
                  label: context.t(
                    `branches.headings.reportsTable.serviceIncome`
                  ),
                  suffix: "₺",
                },
                sale_income: {
                  label: context.t(
                    `branches.headings.reportsTable.saleIncome`
                  ),
                  suffix: "₺",
                },
                staff_bounty: {
                  label: context.t(
                    `branches.headings.reportsTable.staffBounty`
                  ),
                  suffix: "₺",
                },
                total_income: {
                  label: context.t(
                    `branches.headings.reportsTable.totalIncome`
                  ),
                  suffix: "₺",
                },
                total_expense: {
                  label: context.t(
                    `branches.headings.reportsTable.totalExpense`
                  ),
                  suffix: "₺",
                },
                total_count: {
                  label: context.t(
                    `branches.headings.reportsTable.totalCount`
                  ),
                  suffix: "₺",
                },
              }}
            />
          </Column>
        </Grid>
      </BranchContainer>
    );
  };

  const sum = (key) => {
    return `${branchReports
      .map((branch) => parseFloat(branch[key]))
      .reduce((a, b) => a + b, 0)}` !== "NaN"
      ? `${branchReports
          .map((branch) => parseFloat(branch[key]))
          .reduce((a, b) => a + b, 0)
          .toFixed(2)}`
      : `0.00`;
  };

  /**
   * Hero'nun altında tab navigator oluşturan ve
   * ilgili tab'in component'ini sayfaya dahil eden builderfonksiyonudur..
   */
  const _TabNavigatorBuilder = () => {
    const Tab = _NavigatorSingleTabBuilder;

    //Components
    const BranchList = _BranchListBuilder;
    const BranchReports = _BranchReportsBuilder;

    //Navigator Settings
    const TabNavigator = [
      {
        title: context.t(`branches.tabBranchListTitle`),
        component: <BranchList />,
      },
      {
        title: context.t(`branches.tabBranchReportsTitle`),
        component: <BranchReports />,
      },
      {
        title: context.t(`branches.tabBranchAddTitle`),
        component: (
          <BranchAdd onSuccess={() => setTABTYPE(0)} />
        ),
      },
    ];

    return (
      <>
        <Grid>
          <Column className="xs-12 sm-12">
            <TNavigator className="mb-3">
              {TabNavigator.filter((_tab) => _tab !== undefined).map(
                (_tab, index) => (
                  <Tab
                    key={index}
                    title={_tab.title}
                    control={TABTYPE === index ? "active" : ""}
                    onClick={() => {
                      setTABTYPE(index);
                      setLoaded(true)
                    }}
                  />
                )
              )}
            </TNavigator>
          </Column>
        </Grid>
        {TabNavigator[TABTYPE].component}
      </>
    );
  };

    const TabNavigator = _TabNavigatorBuilder;

    return (
      <AuthContainer>
        <BranchView>
          <Hero
            style={{
              background: `url(${require("./../../../assets/images/textures/1.png")})`,
            }}
          >
            <HeroOverlay />
            <HeroInner className={heroLoaded ? "show" : undefined}>
              <h4>{context.t(`branches.heroHeadingText`)}</h4>
              <h1>
                {context.t(`branches.greetingText`)}{" "}
                {context.state.user.full_name}!
              </h1>
              <p className="mb-1">
                {context.t(`branches.greetingBottomText`)}
              </p>
              <p className="mb-1">
                {context.t(`branches.greetingBottomText2`)}
              </p>
              <Button
                iconComponent={ExitToApp}
                title={context.t(`branches.logoutButtonTitle`)}
                backgroundColor="grey-opacity"
                textColor="grey"
                onClick={() => {
                  context.dispatch({ type: "LOGOUT_USER" });
                  history.push("/");
                }}
              />
            </HeroInner>
          </Hero>
          <TabNavigator />
        </BranchView>
      </AuthContainer>
    );
}

export default BranchesIndex;

const BranchView = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 40px;
`;

const Hero = styled.div`
  width: 100vw;
  min-height: 300px;
  padding: 64px 0 24px;
  box-sizing: border-box;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-size: 22px;
    font-weight: 600;
  }

  p {
    font-weight: 500;
  }
`;

const HeroOverlay = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0.88) 100%
  );
`;

const HeroInner = styled.div`
  z-index: 999;
  text-align: center;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: -40px;
  opacity: 0;

  &.show {
    position: relative;
    top: 0;
    opacity: 1;
    transition: top ease 0.8s, opacity 1.6s ease;
  }
`;

const BranchContainer = styled.div`
  padding: 0 16px;
`;

const TNavigator = styled.ul`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
  background: #fff;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TNavigatorTab = styled.li`
  width: auto;
  flex: none;
  text-align: center;

  button {
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
    text-decoration: none;
    display: block;
    padding: 16px;
    line-height: 26px;
    color: #303030;

    &.active {
      color: var(--primary);
      border-bottom: 2px solid var(--primary);
    }
  }
`;
