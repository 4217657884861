import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Axios from "axios";
import moment from "moment";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import StatsCard from "../../../components/Cards/StatsCard";
import { toast } from "react-toastify";
import Select from "../../../theme/CustomMUI/Select";
import RemainingDebt from "../../../assets/images/svg/remaining_debt.svg";
import { DateRange } from "@material-ui/icons";
import styled from "styled-components";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";

import Loader from "../../../assets/images/loader.gif";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DebtTransactions = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [filter, setFilter] = useState({
    start_date: moment().subtract(1, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });
  const [reportsDate, setReportsDate] = useState({
    between: {
      start: moment().subtract(1, "days").format("DD/MM/YYYY"),
      end: moment().format("DD/MM/YYYY"),
    }
  });
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [debtTransactions, setDebtTransactions] = useState({
    pagination: {
      page: 1,
      total_page: 1,
      onChange: (page) => getDebtData(page),
    },
    data: [],
  });
  const [filterPicker, setFilterPicker] = useState([
    {
      id: 1,
      label: "Bugün",
      offset: 1,
    },
    {
      id: 2,
      label: "Bu Hafta",
      offset: 3,
    },
    {
      id: 3,
      label: "Bu Ay",
      offset: 4,
    },
  ]);

  const [selectedFilterPicker, setSelectedFilterPicker] = useState({
    id: 1,
    label: "Bugün",
    offset: 1,
  });

  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const [readyToUseFilter, setReadyToUseFilter] = useState(null);

  const [totalDebt, setTotalDebt] = useState(null);

  const getDebtData = (page = 1) => {
    setLoaded(false);
    Axios.get(
      `${context.api_endpoint}/company/accounting/debts`,
      selectedFilterPicker === null
        ? {
            params: {
              page: page ? page : 1,
              start: moment(filter.start_date).format("YYYY-MM-DD"),
              end: moment(filter.end_date).format("YYYY-MM-DD"),
              customerid: selectedCustomer ? selectedCustomer.id : null,
              isbetween: true,
            },
          }
        : {
            params: {
              page: page ? page : 1,
              isbetween: false,
              customerid: selectedCustomer ? selectedCustomer.id : null,
              offset: selectedFilterPicker.offset,
            },
          }
    )
      .then(({ data }) => {
        if (data.data.records !== null) {
          setLoaded(true);
          setReportsDate({
            between: {
              start: moment(data.data.start_time.split("T")[0])
                .local()
                .format("DD/MM/YYYY"),
              end: moment(data.data.end_time.split("T")[0])
                .local()
                .format("DD/MM/YYYY"),
            }
          });
          setDebtTransactions({
            ...debtTransactions,
              pagination: {
                ...debtTransactions.pagination,
                page: data.data.page,
                total_page: data.data.records.length,
              },
              data: [
                ...data.data.records.map((item) => {
                  return {
                    ...item,
                    id: item.receipt_id,
                    customer_name: `${item.customer.name} ${item.customer.surname}`,
                    created_at: moment(item.created_at)
                      .tz("Europe/Istanbul")
                      .format("LLL"),
                    all_amount:
                      item.all_amount === 0 || item.all_amount === null
                        ? "0.00"
                        : item.all_amount.toFixed(2),
                    paid:
                      item.paid === 0 || item.paid === null
                        ? "0.00"
                        : item.paid.toFixed(2),
                    debt_total: (
                      (item.all_amount ?? 0) - (item.paid ?? 0)
                    ).toFixed(2),
                  };
                }),
              ],
          });
          setTotalDebt(data.data.total_debt);
        } else {
          setLoaded(true);
          setReportsDate({
            between: {
              start: moment(data.data.start_time.split("T")[0])
                .local()
                .format("DD/MM/YYYY"),
              end: moment(data.data.end_time.split("T")[0])
                .local()
                .format("DD/MM/YYYY"),
            },
          });
          setDebtTransactions({
            ...debtTransactions,
              pagination: {
                ...debtTransactions.pagination,
                page: data.data.page,
                total_page: data.data.records !== null ? data.data.records.length : 1,
              },
              data: [],
          });
          setTotalDebt(data.data.total_debt);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };
  //TODO: search for customer by name
  const getCustomers = (key) => {
    Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    }).then(async ({ data }) => {
      await setCustomers([...data.data.records]);
    });

    return customers;
  };

  useEffect(() => {
    getDebtData();
    getCustomers();
  }, []);

    return (
      <AuthContainer
        makePlanUpgrade={[2]}
        authorities={[1]}
        limited_permission="income_expense"
      >
        <Grid>
          <Column
            className="xs-12 sm-12 md-12 lg-12 mb-1 pb-2"
            style={{ borderBottom: "1px solid #b2b2b2" }}
          >
            <Column
              className="xs-12 sm-12 md-12 lg-6"
              style={{ height: "63px", display: "flex", alignItems: "center" }}
            >
              <ShortButtonContainer>
                <ShortButton
                  onClick={async () => {
                    await setSelectedFilterPicker({
                      id: 1,
                      label: "Bugün",
                      offset: 1,
                    })
                    await getDebtData();
                  }}
                  style={{
                    backgroundColor: `${
                      selectedFilterPicker?.id === 1
                        ? "rgb(49, 169, 243, 0.14)"
                        : ""
                    }`,
                    border: "1px solid rgb(91, 187, 245, 0.41)",
                    color: "rgb(49, 169, 243)",
                  }}
                >
                  Bugün
                </ShortButton>
                <ShortButton
                  onClick={async () => {
                    await setSelectedFilterPicker({
                      id: 2,
                      label: "Bu Hafta",
                      offset: 3,
                    });
                    await getDebtData();
                  }}
                  style={{
                    backgroundColor: `${
                      selectedFilterPicker?.id === 2
                        ? "rgb(29, 139, 62, 0.21)"
                        : ""
                    }`,
                    border: "1px solid rgb(58, 154, 87, 0.35)",
                    color: "rgb(29, 139, 62)",
                  }}
                >
                  Bu Hafta
                </ShortButton>
                <ShortButton
                  onClick={async () => {
                    await setSelectedFilterPicker({
                      id: 3,
                        label: "Bu Ay",
                        offset: 4,
                    });
                    await getDebtData();
                  }}
                  style={{
                    backgroundColor: `${
                      selectedFilterPicker?.id === 3
                        ? "rgb(254, 151, 151, 0.19)"
                        : ""
                    }`,
                    border: "1px solid rgb(185, 178, 178, 0.65)",
                    color: "rgb(254, 111, 111)",
                  }}
                >
                  Bu Ay
                </ShortButton>
              </ShortButtonContainer>
            </Column>
            <Column
              className="xs-12 sm-12 md-12 lg-6"
              style={{ borderLeft: "1px solid #b2b2b2" }}
            >
              <FilterContainer>
                <FilterButton
                  onClick={() => {
                    setFilterModalOpen(true);
                    setSelectedFilterPicker(null);
                    setFilter({
                      start_date: moment()
                          .local()
                          .subtract(1, "days")
                          .toISOString(),
                      end_date: moment().local().toISOString()
                    });
                    setReportsDate({
                      between: {
                        start: moment()
                          .local()
                          .subtract(1, "days")
                          .format("DD/MM/YYYY"),
                        end: moment().local().format("DD/MM/YYYY"),
                      }
                    });
                  }}
                >
                  {" "}
                  <DateRange />
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "1.2em",
                      paddingLeft: "1vw",
                    }}
                  >
                    {reportsDate.between.start} -{" "}
                    {reportsDate.between.end}
                  </span>
                </FilterButton>

                <FilterButton
                  style={{
                    cursor: "auto",
                    backgroundColor: "rgba(208, 233, 233, 0.65)",
                    color: "#54AEAE",
                    border: "1px solid rgba(208, 233, 233, 0.8)",
                  }}
                >
                  <img
                    src={RemainingDebt}
                    alt="remaining_debt"
                    style={{ marginRight: "10px", color: "red" }}
                  />
                  {context.t(`accounting.totalDebt`)}{" "}
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "1.2em",
                      paddingLeft: "1vw",
                    }}
                  >
                    {`${parseInt(totalDebt)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}${
                      context.state?.currency
                        ? context.state?.currency
                        : "₺"
                    }`}
                  </span>
                </FilterButton>
              </FilterContainer>
            </Column>
          </Column>
        </Grid>

        <Grid>
          <Column
            className="xs-12 sm-12 md-12 lg-12"
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Column className="xs-12 sm-12 md-12 lg-3">
              <AutocompleteInput
                label={context.t(
                  `[receipts/add].searchCustomerInputLabel`
                )}
                labelKey="full_name"
                valueKey="id"
                returnFullObject
                onRemove={async () => {
                  await setSelectedCustomer(null);
                  getDebtData();
                }}
                selected={selectedCustomer || null}
                selectedHandler={async (selected_customer) => {
                  await setSelectedCustomer(selectedCustomer);
                  setTimeout(() => {
                    getDebtData();
                  }, 250);
                }}
                asyncDataService={async (keyword) => getCustomers(keyword)}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <ExcelButton
                route="accounting/debts"
                fullWidth={true}
                size="lg"
                requestData={
                  selectedFilterPicker === null
                    ? {
                        start: moment(filter.start_date).format(
                          "YYYY-MM-DD"
                        ),
                        end: moment(filter.end_date).format(
                          "YYYY-MM-DD"
                        ),
                        customerid: selectedCustomer
                          ? selectedCustomer.id
                          : null,
                        isbetween: true,
                        is_excel: true,
                      }
                    : {
                        offset: selectedFilterPicker.offset,
                        customerid: selectedCustomer
                          ? selectedCustomer.id
                          : null,
                        isbetween: false,
                        is_excel: true,
                      }
                }
              />
            </Column>
          </Column>
        </Grid>

        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12">
            {loaded ? (
              <Table
                loaded={loaded}
                headings={{
                  created_at: {
                    label: context.t(
                      `['accounting/debt'].headings.createdAt`
                    ),
                    sortable: {
                      0: [{ field: "`expenses`.`created_at`", order: "ASC" }],
                      1: [{ field: "`expenses`.`created_at`", order: "DESC" }],
                    },
                  },
                  customer_name: {
                    label: context.t(
                      `['accounting/debt'].headings.customerFullName`
                    ),
                    sortable: {
                      0: [
                        { field: "`customers`.`name`", order: "ASC" },
                        { field: "`customers`.`surname`", order: "ASC" },
                      ],
                      1: [
                        { field: "`customers`.`name`", order: "DESC" },
                        { field: "`customers`.`surname`", order: "DESC" },
                      ],
                    },
                  },
                  all_amount: {
                    label: context.t(
                      `['accounting/debt'].headings.amount`
                    ),
                    suffix: context.state?.currency
                      ? context.state?.currency
                      : "₺",
                    style: { width: 100 },
                    sortable: {
                      0: [{ field: "`receipts`.`all_amount`", order: "ASC" }],
                      1: [{ field: "`receipts`.`all_amount`", order: "DESC" }],
                    },
                  },
                  paid: {
                    label: context.t(`['accounting/debt'].headings.paid`),
                    suffix: context.state?.currency
                      ? context.state?.currency
                      : "₺",
                    sortable: {
                      0: [{ field: "`receipts`.`paid`", order: "ASC" }],
                      1: [{ field: "`receipts`.`paid`", order: "DESC" }],
                    },
                    style: { width: 120 },
                  },
                  debt_total: {
                    label: context.t(
                      `['accounting/debt'].headings.remaining`
                    ),
                    suffix: context.state?.currency
                      ? context.state?.currency
                      : "₺",
                    sortable: {
                      0: [
                        { field: "`receipts`.`all_amount`", order: "ASC" },
                        { field: "`receipts`.`debt`", order: "DESC" },
                      ],
                      1: [
                        { field: "`receipts`.`all_amount`", order: "DESC" },
                        { field: "`receipts`.`debt`", order: "ASC" },
                      ],
                    },
                    style: { width: 120 },
                  },
                  _: {
                    label: context.t(`component.actionHeadingText`),
                    action_column: true,
                  },
                }}
                rows={debtTransactions.data}
                pagination={debtTransactions.pagination}
                buttons={[
                  {
                    title: context.t(`accounting.showOnReceipt`),
                    icon: "launch",
                    transitionEffect: true,
                    onClick: (row) =>
                      history.push({ pathname:`/receipts/detail/${row.id}`, state: { row: row.id } }),
                  },
                ]}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <img src={Loader} width="100" height="100" alt="loading" />
              </div>
            )}
          </Column>
        </Grid>

        <AlertDialog
          title={<h3 style={{ textAlign: "center" }}>Zaman Aralığı Seçiniz</h3>}
          disableBackdropClick={false}
          open={filterModalOpen}
          maxWidth="sm"
          closeHandler={() => {
            setFilterModalOpen(false);
          }}
          buttons={[
            {
              title: "Onayla",
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: async () => {
                await getDebtData();
              },
            },
            {
              title: "İptal",
              icon: "close",
              textColor: "grey",
              onClick: () => {
                setSelectedFilterPicker({
                  id: 1,
                  label: "Bugün",
                  offset: 1,
                });
                setReadyToUseFilter(null);
              },
            },
          ]}
        >
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-12 mt-3 mb-5">
              <Column className="xs-12 sm-12 md-12 lg-6">
                <ShortButtonContainer
                  style={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <ShortButton
                    style={{
                      border: "1px solid rgba(249,186,132, 0.51)",
                      color: "rgb(219,146,92)",
                      backgroundColor: `${
                        readyToUseFilter === 1
                          ? "rgba(249,186,132, 0.21)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      setReadyToUseFilter(1);
                      setFilter({
                        start_date: moment()
                            .local()
                            .subtract(1, "days")
                            .toISOString(),
                        end_date: moment().local().toISOString(),
                      });
                      setReportsDate({
                        between: {
                          start: moment()
                            .local()
                            .subtract(1, "days")
                            .format("DD/MM/YYYY"),
                          end: moment().local().format("DD/MM/YYYY"),
                        },
                      });
                    }}
                  >
                    Dün
                  </ShortButton>
                  <ShortButton
                    style={{
                      border: "1px solid rgb(91, 187, 245, 0.41)",
                      color: "rgb(49, 169, 243)",
                      backgroundColor: `${
                        readyToUseFilter === 2
                          ? "rgb(49, 169, 243, 0.14)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      setReadyToUseFilter(2);
                      setFilter({
                        start_date: moment()
                            .local()
                            .subtract(7, "days")
                            .toISOString(),
                        end_date: moment().local().toISOString(),
                      });
                      setReportsDate({
                        between: {
                          start: moment()
                            .local()
                            .subtract(7, "days")
                            .format("DD/MM/YYYY"),
                          end: moment().local().format("DD/MM/YYYY"),
                        },
                      });
                    }}
                  >
                    Son 7 gün
                  </ShortButton>
                  <ShortButton
                    style={{
                      border: "1px solid rgb(58, 154, 87, 0.35)",
                      color: "rgb(29, 139, 62)",
                      backgroundColor: `${
                        readyToUseFilter === 3
                          ? "rgb(29, 139, 62, 0.21)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      setReadyToUseFilter(3);
                      setFilter({
                        start_date: moment()
                            .local()
                            .subtract(15, "days")
                            .toISOString(),
                        end_date: moment().local().toISOString(),
                      });
                      setReportsDate({
                        between: {
                          start: moment()
                            .local()
                            .subtract(15, "days")
                            .format("DD/MM/YYYY"),
                          end: moment().local().format("DD/MM/YYYY"),
                        },
                      });
                    }}
                  >
                    Son 15 gün
                  </ShortButton>
                  <ShortButton
                    style={{
                      border: "1px solid rgb(185, 178, 178, 0.65)",
                      color: "rgb(254, 111, 111)",
                      backgroundColor: `${
                        readyToUseFilter === 4
                          ? "rgb(254, 151, 151, 0.19)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      setReadyToUseFilter(4);
                      setFilter({
                        start_date: moment()
                            .local()
                            .subtract(30, "days")
                            .toISOString(),
                        end_date: moment().local().toISOString(),
                      });
                      setReportsDate({
                        between: {
                          start: moment()
                            .local()
                            .subtract(30, "days")
                            .format("DD/MM/YYYY"),
                          end: moment().local().format("DD/MM/YYYY"),
                        },
                      });
                    }}
                  >
                    Son 30 gün
                  </ShortButton>
                </ShortButtonContainer>
              </Column>

              <Column
                className="xs-12 sm-12 md-12 lg-6"
                style={{ height: "100%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid #b2b2b2",
                    height: "100%",
                  }}
                >
                  <Column className="xs-12 sm-12 md-12 lg-12">
                    <DatePickerInput
                      label={context.t(`reports.startInputLabel`)}
                      value={new Date(filter.start_date)}
                      maxDate={new Date(filter.end_date)}
                      onChange={async (date) => {
                        setReadyToUseFilter(null);
                        setFilter({
                          ...filter,
                          start_date: moment(date).local().toISOString(),
                        });
                        setReportsDate({
                          ...reportsDate,
                          between: {
                            ...reportsDate.between,
                            start: moment(date).local().format("DD/MM/YYYY"),
                          },
                        });
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-12">
                    <DatePickerInput
                      label={context.t(`reports.endInputLabel`)}
                      value={
                        new Date(
                          String(filter.end_date).split("T")[0]
                        )
                      }
                      minDate={filter.start_date}
                      onChange={async (date) => {
                        setReadyToUseFilter(null);
                        setFilter({
                          ...filter,
                          end_date: moment(date).local().toISOString(),
                        });
                        setReportsDate({
                          ...reportsDate,
                          between: {
                            ...reportsDate.between,
                            end: moment(date).local().format("DD/MM/YYYY"),
                          },
                        });
                      }}
                    />
                  </Column>
                </div>
              </Column>
            </Column>
          </Grid>
        </AlertDialog>
      </AuthContainer>
    );
}

const ShortButton = styled.div`
  border-radius: 8px;
  width: 12vw;
  padding: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
`;

const ShortButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const FilterButton = styled.div`
  border-radius: 8px;
  padding: 1vh 2.5vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  margin-bottom: 10px;
  color: rgb(219, 146, 92);
  border: 1px solid rgba(249, 186, 132, 0.51);
  background-color: rgba(249, 186, 132, 0.21);
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

export default DebtTransactions;
