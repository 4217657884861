import React, { useContext, useEffect, useState } from "react";
import AuthContainer from "../../../components/AuthContainer";
import AppContext from "../../../context/store";
import Axios from "axios";
import moment from "moment";
import styled from "styled-components";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import SemiCirclePie from "../../../components/Charts/SemiCirclePie";
import Select from "../../../theme/CustomMUI/Select";
import { toast } from "react-toastify";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { DateRange, Clear } from "@material-ui/icons";
import { MenuItem, TextField } from "@material-ui/core";

import Loader from "../../../assets/images/loader.gif";

import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  root: {
    "& .MuiSelect-select:focus": {
      backgroundColor: "rgba(249, 186, 132, 0.21) !important",
    },
  },
});

const ServiceReports = ({ classes }) => {
  const context = useContext(AppContext);

  const [filter, setFilter] = useState({
    between: {
      start: moment().local().toISOString(),
      end: moment().local().toISOString(),
    },
  });
  const [reportsDate, setReportsDate] = useState({
    between: {
      start: moment().local().format("DD/MM/YYYY"),
      end: moment().local().format("DD/MM/YYYY"),
    },
  });

  const [filterPicker, setFilterPicker] = useState([
    {
      id: 1,
      label: "Bugün",
      offset: 1,
    },
    {
      id: 2,
      label: "Bu Hafta",
      offset: 3,
    },
    {
      id: 3,
      label: "Bu Ay",
      offset: 4,
    },
  ]);

  const [selectedFilterPicker, setSelectedFilterPicker] = useState({
    id: 1,
    label: "Bugün",
    offset: 1,
  });
  const [chart, setChart] = useState([]);
  const [datas, setDatas] = useState({
    loaded: false,
    pagination: {
      page: 1,
      total_page: 1,
      onChange: (page) => getServiceReports(page),
    },
    data: [],
  });
  const [pageCounts, setPageCounts] = useState([
    {
      id: 1,
      count: 10,
    },
    {
      id: 2,
      count: 50,
    },
    {
      id: 3,
      count: 100,
    },
  ]);
  const [selectedPageCount, setSelectedPageCount] = useState(null);
  const [count,setCount] = useState(10);
  const [staffs, setStaffs] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [readyToUseFilter, setReadyToUseFilter] = useState(null);

  const getServiceReports = (page = 1) => {
    setLoading(true);
    Axios.get(
      `${context.api_endpoint}/company/service/reports`,
      selectedFilterPicker === null
        ? {
            params: {
              page: page,
              start: moment(filter.between.start).format("YYYY-MM-DD"),
              end: moment(filter.between.end).format("YYYY-MM-DD"),
              isbetween: true,
              staffid: selectedStaff ? selectedStaff.id : null,
              pagecount: count ? count : 10,
            },
          }
        : {
            params: {
              page: page,
              offset: selectedFilterPicker.offset,
              isbetween: false,
              staffid: selectedStaff ? selectedStaff.id : null,
              pagecount: count ? count : 10,
            },
          }
    )
      .then(({ data: { data } }) => {
        setLoading(false);
        setChart(data.service_data ? [...data.service_data] : []);
        setDatas({
          ...datas,
          loaded: true,
          data: [...data.service_record],
          // pagination: {
          //   ...datas.pagination,
          //   page: _data.page,
          //   total_page: _data.records.length,
          // },
        });
        setLoaded(true);
        setReportsDate({
          between: {
            start: moment(data.start_time.split("T")[0])
              .local()
              .format("DD/MM/YYYY"),
            end: moment(data.end_time.split("T")[0])
              .local()
              .format("DD/MM/YYYY"),
          },
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getStaffs = () => {
    Axios.get(`${context.api_endpoint}/company/staffs/unsafe`).then(
      ({ data }) => {
        setStaffs([
          ...data.data.map((staff) => ({
            id: staff.id,
            full_name: `${staff.name} ${staff.surname}`,
          })),
        ])
      }
    );
  };

  const setDates = (time) => {
    setFilter({
      between: {
        start: moment().local().subtract(time, "days").toISOString(),
        end: moment().local().toISOString(),
      }, 
    });
    setReportsDate({
      between: {
        start: moment().local().subtract(time, "days").format("DD/MM/YYYY"),
        end: moment().local().format("DD/MM/YYYY"),
      },
    })
  };

  useEffect(() => {
    getServiceReports();
    getStaffs();
  }, []);

    return (
      <AuthContainer
        authorities={[1]}
        makePlanUpgrade={[2]}
        limited_permission="income_expense"
      >
        <Grid>
          <Column
            className="xs-12 sm-12 md-12 lg-12 mb-1 pb-2"
            style={{ borderBottom: "1px solid #b2b2b2" }}
          >
            <Column
              className="xs-12 sm-12 md-12 lg-6"
              style={{ height: "63px", display: "flex", alignItems: "center" }}
            >
              <ShortButtonContainer>
                <ShortButton
                  onClick={() => {
                    setSelectedFilterPicker({
                      id: 1,
                      label: "Bugün",
                      offset: 1,
                    });
                    getServiceReports();
                  }}
                  style={{
                    backgroundColor: `${
                      selectedFilterPicker?.id === 1
                        ? "rgb(49, 169, 243, 0.14)"
                        : ""
                    }`,
                    border: "1px solid rgb(91, 187, 245, 0.41)",
                    color: "rgb(49, 169, 243)",
                  }}
                >
                  Bugün
                </ShortButton>
                <ShortButton
                  onClick={() => {
                    setSelectedFilterPicker({
                      id: 2,
                      label: "Bu Hafta",
                      offset: 3,
                    });
                    getServiceReports();
                  }}
                  style={{
                    backgroundColor: `${
                      selectedFilterPicker?.id === 2
                        ? "rgb(29, 139, 62, 0.21)"
                        : ""
                    }`,
                    border: "1px solid rgb(58, 154, 87, 0.35)",
                    color: "rgb(29, 139, 62)",
                  }}
                >
                  Bu Hafta
                </ShortButton>
                <ShortButton
                  onClick={() => {
                    setSelectedFilterPicker({
                      id: 3,
                      label: "Bu Ay",
                      offset: 4,
                    });
                    getServiceReports();
                  }}
                  style={{
                    backgroundColor: `${
                      selectedFilterPicker?.id === 3
                        ? "rgb(254, 151, 151, 0.19)"
                        : ""
                    }`,
                    border: "1px solid rgb(185, 178, 178, 0.65)",
                    color: "rgb(254, 111, 111)",
                  }}
                >
                  Bu Ay
                </ShortButton>
              </ShortButtonContainer>
            </Column>

            <Column
              className="xs-12 sm-12 md-12 lg-6"
              style={{ borderLeft: "1px solid #b2b2b2" }}
            >
              <FilterContainer>
                <FilterButton
                  onClick={() => {
                    setFilterModalOpen(true);
                    setSelectedFilterPicker(null);
                    setReadyToUseFilter(1);
                    setFilter({
                      between: {
                        start: moment()
                          .local()
                          .subtract(1, "days")
                          .toISOString(),
                        end: moment().local().toISOString(),
                      },
                    });
                  }}
                >
                  {" "}
                  <DateRange />
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "1.2em",
                      paddingLeft: "1vw",
                    }}
                  >
                    {reportsDate.between.start} -{" "}
                    {reportsDate.between.end}
                  </span>
                </FilterButton>

                <TextField
                  variant="outlined"
                  className={classes.root}
                  select
                  label="Çalışan Seçimi"
                  SelectProps={{ IconComponent: () => null }}
                  value={
                    selectedStaff
                      ? selectedStaff.id
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedStaff(null);
                          getServiceReports();
                        }}
                      >
                        <Clear />
                      </div>
                    ),
                  }}
                  style={{
                    backgroundColor: "rgba(249, 186, 132, 0.21)",
                    color: "rgb(219, 146, 92)",
                    cursor: "pointer",
                    borderRadius: "8px",
                    marginLeft: "8px",
                    border: "1px solid rgba(249, 186, 132, 0.51)",
                    width: "30%",
                  }}
                  onChange={(selected_staff) => {
                    setSelectedStaff({
                      ...selectedStaff,
                      selected_staff: {
                        id: selected_staff.target.value,
                      },
                    });
                    getServiceReports();
                  }}
                >
                  {staffs &&
                    staffs.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={`${option && option.id}`}
                        id={`${option && option.id}`}
                      >
                        {staffs[index].full_name}
                      </MenuItem>
                    ))}
                </TextField>
              </FilterContainer>
            </Column>
          </Column>
        </Grid>
        <Column className="xs-12 sm-12 md-12 lg-2">
          <ExcelButton
            route="service/reports"
            requestData={
              selectedFilterPicker === null
                ? {
                    start: moment(filter.between.start).format("YYYY-MM-DD"),
                    end: moment(filter.between.end).format("YYYY-MM-DD"),
                    isbetween: true,
                    staffid: selectedStaff ? selectedStaff.id : null,
                    is_excel: true,
                  }
                : {
                    offset: selectedFilterPicker.offset,
                    staffid: selectedStaff ? selectedStaff.id : null,
                    isbetween: false,
                    is_excel: true,
                  }
            }
          />
        </Column>
        <Grid>
          {loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src={Loader} width="80" height="80" alt="loading" />
              <>Raporlarınız yükleniyor. Lütfen bekleyiniz...</>
            </div>
          ) : (
            <Column className="xs-12 sm-12">
              {datas.loaded && (
                <SemiCirclePie
                  containerStyle={{
                    height: "350px",
                    width: "100%",
                  }}
                  data={[
                    ...chart.map((item) => ({
                      label: item.service_name,
                      count: item.total_amount,
                    })),
                  ]}
                  labelText={`{category}\n{value.percent.formatNumber('#.0')}% ({value}₺)`}
                  labelKey="label"
                  valueKey="count"
                />
              )}
              <Table
                loaded={datas.loaded}
                headings={{
                  service_name: {
                    label: context.t(`reports.headings.serviceName`),
                  },
                  service_count: {
                    label: context.t(`reports.headings.transactionCount`),
                  },
                }}
                rows={datas.data}
                pagination={datas.pagination}
              />
            </Column>
          )}
        </Grid>
        <AlertDialog
          title={<h3 style={{ textAlign: "center" }}>Zaman Aralığı Seçiniz</h3>}
          disableBackdropClick={false}
          open={filterModalOpen}
          maxWidth="sm"
          closeHandler={() => {
            setFilterModalOpen(false);
          }}
          buttons={[
            {
              title: "Onayla",
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                getServiceReports();
              },
            },
            {
              title: "İptal",
              icon: "close",
              textColor: "grey",
              onClick: () => {
                setSelectedFilterPicker({
                  id: 1,
                  label: "Bugün",
                  offset: 1,
                });
                getServiceReports();
              },
            },
          ]}
        >
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-12 mt-3 mb-5">
              <Column className="xs-12 sm-12 md-12 lg-6">
                <ShortButtonContainer
                  style={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <ShortButton
                    style={{
                      marginBottom: "10px",
                      border: "1px solid rgba(249,186,132, 0.51)",
                      color: "rgb(219,146,92)",
                      backgroundColor: `${
                        readyToUseFilter === 1
                          ? "rgba(249,186,132, 0.21)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      setReadyToUseFilter(1);
                      setDates(1);
                    }}
                  >
                    {context.t(`component.dateRangeStats.yesterday`)}
                  </ShortButton>
                  <ShortButton
                    style={{
                      marginBottom: "10px",
                      border: "1px solid rgb(91, 187, 245, 0.41)",
                      color: "rgb(49, 169, 243)",
                      backgroundColor: `${
                        readyToUseFilter === 2
                          ? "rgb(49, 169, 243, 0.14)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      setReadyToUseFilter(2);
                      setDates(7);
                    }}
                  >
                    {context.t(`component.dateRangeStats.lastWeek`)}
                  </ShortButton>
                  <ShortButton
                    style={{
                      marginBottom: "10px",
                      border: "1px solid rgb(58, 154, 87, 0.35)",
                      color: "rgb(29, 139, 62)",
                      backgroundColor: `${
                        readyToUseFilter === 3
                          ? "rgb(29, 139, 62, 0.21)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      setReadyToUseFilter(3);
                      setDates(15);
                    }}
                  >
                    {context.t(`component.dateRangeStats.last_15_days`)}
                  </ShortButton>
                  <ShortButton
                    style={{
                      marginBottom: "10px",
                      border: "1px solid rgb(185, 178, 178, 0.65)",
                      color: "rgb(254, 111, 111)",
                      backgroundColor: `${
                        readyToUseFilter === 4
                          ? "rgb(254, 151, 151, 0.19)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      setReadyToUseFilter(4);
                      setDates(30);
                    }}
                  >
                    {context.t(`component.dateRangeStats.lastMonth`)}
                  </ShortButton>
                </ShortButtonContainer>
              </Column>

              <Column
                className="xs-12 sm-12 md-12 lg-6"
                style={{ height: "100%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid #b2b2b2",
                    height: "100%",
                  }}
                >
                  <Column className="xs-12 sm-12 md-12 lg-12">
                    <DatePickerInput
                      label={context.t(`reports.startInputLabel`)}
                      value={new Date(filter.between.start)}
                      maxDate={new Date(filter.between.end)}
                      onChange={(date) => {
                        setReadyToUseFilter(null);
                        setFilter({
                          ...filter,
                          between: {
                            ...filter.between,
                            start: moment(date).local().toISOString(),
                          },
                        });
                        setReportsDate({
                          ...reportsDate,
                          between: {
                            ...reportsDate.between,
                            start: moment(date).local().format("DD/MM/YYYY"),
                          },
                        });
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-12">
                    <DatePickerInput
                      label={context.t(`reports.endInputLabel`)}
                      value={new Date(String(filter.between.end).split("T")[0])}
                      minDate={filter.between.start}
                      onChange={(date) => {
                        setReadyToUseFilter(null);
                        setFilter({
                          ...filter,
                          between: {
                            ...filter.between,
                            end: moment(date).local().toISOString(),
                          },
                        });
                        setReportsDate({
                          ...reportsDate,
                          between: {
                            ...reportsDate.between,
                            end: moment(date).local().format("DD/MM/YYYY"),
                          },
                        });
                      }}
                    />
                  </Column>
                </div>
              </Column>
            </Column>
          </Grid>
        </AlertDialog>
      </AuthContainer>
    );
  
}

export default withStyles(styles)(ServiceReports);

const ShortButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ShortButton = styled.div`
  border-radius: 8px;
  width: 12vw;
  padding: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const FilterButton = styled.div`
  border-radius: 8px;
  padding: 0 2.5vw;
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  color: rgb(219, 146, 92);
  border: 1px solid rgba(249, 186, 132, 0.51);
  background-color: rgba(249, 186, 132, 0.21);
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;
