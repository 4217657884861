import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Button from "../../../theme/Button";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import Axios from "axios";
import { toast } from "react-toastify";
import Input from "../../../theme/CustomMUI/Input";
import CustomerAddDialog from "../../../components/Dialogs/CustomerAddDialog";
import ReceiptIcon from "@material-ui/icons/Receipt";

const RecordAddReceipt = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [selected_customer, setSelectedCustomer] = useState(null);
  const [receipt_note, setReceiptNote] = useState(null);
  const [customer_add_modal, setCustomerAddModal] = useState(false);
  const [insertButtonClick, setInsertButtonClick] = useState(false);

  const searchCustomerByKey = async (key) => {
    let arr = [];
    await Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key },
    }).then(({ data }) => (arr = [...data.data.records]));

    return arr.map((item) => {
      return {
        id: item.id,
        full_name: `${item.full_name} (*** ${item.phone.slice(6, 11)})`,
        send_sms: item.send_sms,
        description: item.description,
      };
    });
  };

  const addReceiptBySelectedCustomer = () => {
    Axios.post(`${context.api_endpoint}/company/receipt/insert`, {
      customer_id: parseInt(selected_customer),
      note: receipt_note,
    })
      .then((response) => {
        if (response.status === 201) {
          setInsertButtonClick(true);
          toast.success(context.t(`['receipts/add'].insertToast`));
          history.push({pathname: `/receipts/detail/${response.data.data.id}`, state: { row: response.data.data.id }});
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
        setInsertButtonClick(false);
      });
  };

  // Insert Random Customer
  const insertRandomCustomer = () => {
    Axios.post(`${context.api_endpoint}/company/customer/insert/walkin`)
      .then((response) => {
        // console.log(response)
        if (response.status === 201) {
          // console.log(response.data.data.id)
          setSelectedCustomer(response.data.data.id);
        }
      })
      .catch((e) => {
        toast.warning(e.response.data.message);
      });
  };

  return (
    <AuthContainer
      makePlanUpgrade={[2]}
      authorities={[0, 1]}
      limited_permission="sales"
    >
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-2"></Column>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-8">
          <ReceiptTypeContainer>
            <img
              src={require("../../../assets/images/manypixels/profiling.svg")}
              alt="Müşteri Seç"
            />
            <div className="form">
              <AutocompleteInput
                className="mb-1"
                style={{ width: "100%" }}
                label={context.t(`['receipts/add'].searchCustomerInputLabel`)}
                placeholder={context.t(
                  `['receipts/add'].searchCustomerInputPlaceholder`
                )}
                labelKey="full_name"
                valueKey="id"
                onRemove={() => {
                  setSelectedCustomer(null);
                }}
                selectedHandler={(selected_customer) => {
                  setSelectedCustomer(parseInt(selected_customer));
                }}
                asyncDataService={async (keyword) =>
                  searchCustomerByKey(keyword)
                }
              />

              <Input
                className="mb-1"
                label={context.t(`['receipts/add'].receiptNoteInputLabel`)}
                multiline
                rows={3}
                onChange={(e) => setReceiptNote(e.target.value)}
              />

              <Button
                icon={<ReceiptIcon />}
                title={context.t(
                  `['receipts/add'].openReceiptBySelectedButtonTitle`
                )}
                size="md"
                backgroundColor="primary-opacity"
                textColor="primary"
                fullWidth
                disabled={insertButtonClick}
                onClick={() => {
                  setInsertButtonClick(true);
                  if (selected_customer !== null) {
                    addReceiptBySelectedCustomer();
                  } else {
                    toast.info(
                      "Öncelikle müşteri arama kutusundan seçim yapmanız gerekmektedir!",
                      {
                        autoClose: 1000,
                      }
                    );
                    setTimeout(() => {
                      setInsertButtonClick(false);
                    }, 1000);
                  }
                  // selected_customer !== null
                  //   ? addReceiptBySelectedCustomer()
                  //   : toast.info(
                  //       "Öncelikle müşteri arama kutusundan seçim yapmanız gerekmektedir!"
                  //     );
                }}
              />

              {/* <Button
                className="mt-1"
                icon="person_add"
                title={context.t(
                  `['receipts/add'].openReceiptByRandomCustomerButtonTitle`
                )}
                size="md"
                backgroundColor="secondary-opacity"
                textColor="secondary"
                fullWidth
                onClick={() => insertRandomCustomer()}
              /> */}

              {/* <Button
                className="mt-1"
                icon="person_add"
                title={context.t(
                  `['receipts/add'].openReceiptByNewCustomerButtonTitle`
                )}
                size="md"
                backgroundColor="white"
                textColor="red"
                fullWidth
                onClick={() => setCustomerAddModal(true)}
              /> */}
            </div>
          </ReceiptTypeContainer>
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-12 xl-2"></Column>
      </Grid>

      <CustomerAddDialog
        open={customer_add_modal}
        createdCustomerHandler={async (data) => {
          addReceiptBySelectedCustomer();
          setSelectedCustomer(data.id);
        }}
        closeHandler={() => setCustomerAddModal(false)}
      />
    </AuthContainer>
  );
};

export default RecordAddReceipt;

const ReceiptTypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${window.screen.width < 576 ? "column" : "row"};
  align-items: center;
  justify-content: center;
  margin: 32px 0;

  img {
    height: 180px;
    object-fit: contain;
    margin: 0 32px 32px;
  }

  div.form {
    flex: 1;
  }
`;
