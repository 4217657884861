import React, { useEffect, useState, useContext, useRef } from "react";
import AuthContainer from "../../../components/AuthContainer";
import Input from "../../../theme/CustomMUI/Input";
import AppContext from "../../../context/store";
import { Grid, Column } from "../../../theme/Grid";
import styled from "styled-components";
import Axios from "axios";
import Button from "../../../theme/Button";
import FormDataGenerator from "../../../functions/FormDataGenerator";
import { toast } from "react-toastify";
import CompressImage from "../../../functions/CompressImage";
import { Alert } from "@material-ui/lab";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const Photo = () => {
	const context = useContext(AppContext);
	let imageRef = useRef();

	const [loaded, setLoaded] = useState(false);

	const [photo, setPhoto] = useState(null);

	const [openDialog, setOpenDialog] = useState(false);

	const [uploadPhotoModal, setUploadPhotoModal] = useState(false);

	const [src, setSrc] = useState(null);
	const [crop, setCrop] = useState({
		unit: "%",
		x: 0,
		y: 0,
		width: 70,
		height: 45,
	});

	const [croppedImageUrl, setCroppedImageUrl] = useState(null);

	const [fileUrl, setFileUrl] = useState();


	const getSingleStorePhoto = () => {
		Axios.get(`${context.api_endpoint}/company/showcase/image/show`)
			.then(({ data }) => {
				setLoaded(true);
				setPhoto(data.data.path);
			})
			.catch((e) => {
				if (e.response.status === 401) {
					toast.error(e.response.data.message);
				} else {
					toast.warning(e.response.data.message);
				}
			});
	}

	const uploadStorePhoto = async (e) => {
		toast.success(context.t(`['platform/photo'].photoUploadToast`));
		Axios.post(
			`${context.api_endpoint}/company/showcase/image/insert`,
			FormDataGenerator({
				files: await CompressImage(e, {
					maxSizeMB: 2,
					fileType: "image/jpg",
				}),
			}),
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		)
			.then((response) => {
				if (response.status === 201) {
					toast.success(context.t(`['platform/photo'].insertToast`));
					getSingleStorePhoto();
					setUploadPhotoModal(false);
				}
			})
			.catch((e) => {
				if (e.response.status === 401) {
					toast.error(e.response.data.message);
				} else {
					toast.error(
						context.t(`['platform/photo'].photoUploadErrorToast`)
					);
				}
			});
	}

	const deleteStorePhoto = () => {
		Axios.delete(`${context.api_endpoint}/company/showcase/image/delete`)
			.then(() => {
				toast.success(context.t(`['settings/gallery'].deleteToast`));
				setLoaded(false);
				setPhoto(null);
			})
			.catch((e) => {
				if (e.response.status === 401) {
					toast.error(e.response.data.message);
				} else {
					toast.error(
						context.t(`['settings/gallery'].deletePhotoErrorToast`)
					);
				}
			});
	}

	// Fotoğraf crop fonksiyonları
	const onSelectFile = (e) => {
		if (e.target.files && e.target.files.length === 1) {
			const reader = new FileReader();
			reader.addEventListener("load", () => setSrc(reader.result));
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	// If you setState the crop in here you should return false.
	const onImageLoaded = (image) => {
		imageRef.current = image.target;
	};

	const onCropComplete = (crop) => {
		makeClientCrop(crop);
	};

	const onCropChange = (crop, percentCrop) => {
		setCrop(crop);
	};

	const makeClientCrop = async (crop) => {
		if (imageRef.current && crop.width && crop.height) {
			const CroppedImageUrl = await getCroppedImg(
				imageRef.current,
				crop,
				"newFile.jpeg"
			);
			setCroppedImageUrl(CroppedImageUrl)
		}
	}

	const getCroppedImg = (image, crop, fileName) => {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext("2d");

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob((blob) => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error("Canvas is empty");
					return;
				}

				blob.name = fileName;
				window.URL.revokeObjectURL(fileUrl);
				setFileUrl(window.URL.createObjectURL(blob));
				resolve(blob);
			}, "image/jpeg");
		});
	}

	useEffect(() => {
		getSingleStorePhoto();
	}, []);

	return (
		<AuthContainer authorities={[1]} limited_permission="gallery">
			<Grid>
				<Column
					className="xs-12 mb-3"
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
					}}
				>
					{
						<ProfilePhoto
							src={
								photo
									? `${context.api_endpoint.replace("api/v1", "")}${context.state.company_id
									}/${photo}`
									: require("../../../assets/images/empty_store_image.jpg")
							}
						/>
					}
					<InfoDiv>
						<Alert severity="warning" className="mt-2">
							<b>{context.t(`['platform/photo'].photoInfo`)}</b>
							<Info onClick={() => setOpenDialog(true)}>
								{context.t(`['platform/photo'].anexample`)}
							</Info>
						</Alert>
					</InfoDiv>
					<AlertDialog
						title={""}
						open={openDialog}
						fullWidth={true}
						maxWidth="lg"
						textType
						disableBackdropClick={false}
						closeHandler={() => setOpenDialog(false)}
						buttons={[
							{
								//icon: "okay",
								title: "Kapat",
								textColor: "white",
								backgroundColor: "primary",
							},
						]}
					>
						<InfoPhoto
							src={require("../../../assets/images/appointment_photo.png")}
						/>
					</AlertDialog>
				</Column>

				{/* <input
            id="store_photo"
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={(e) => uploadStorePhoto(e)}
          /> */}

				<Column>
					<Button
						className="mt-1"
						icon="add_photo_alternate"
						title={context.t(`profile.photo.uploadButtonTitle`)}
						textColor="blue"
						backgroundColor="blue-opacity"
						fullWidth
						disabled={photo ? true : false}
						onClick={() => setUploadPhotoModal(true)}
					/>

					<Button
						className="mt-1"
						icon="delete"
						title={context.t(`profile.photo.deleteButtonTitle`)}
						textColor="red"
						backgroundColor="blue-opacity"
						fullWidth
						disabled={photo ? false : true}
						onClick={() => deleteStorePhoto()}
					/>
				</Column>
			</Grid>
			<AlertDialog
				fullWidth
				open={uploadPhotoModal}
				closeHandler={() => setUploadPhotoModal(false)}
				title={"Vitrin Fotoğrafı yükle"}
				buttons={[
					{
						title: "Kapat",
						icon: "close",
						backgroundColor: "white",
						textColor: "black",
						onClick: () => {
							setUploadPhotoModal(false);
						},
					},
				]}
			>
				{/* <UploadPhoto
            uploadPhoto={uploadStorePhoto}
            closeHandler={succesCloseHandler}
          /> */}

				<div
					style={{
						textAlign: "center",
						display: "flex",
						flexDirection: "column",
						padding: "15px",
					}}
				>
					<div>
						<input
							id="select_photo"
							style={{ display: "none" }}
							type="file"
							accept="image/*"
							onChange={onSelectFile}
						/>
					</div>
					<Button
						className="mb-2 mt-1"
						icon="add"
						title={"Fotoğraf Seç"}
						textColor="blue"
						backgroundColor="blue-opacity"
						onClick={() => document.getElementById("select_photo").click()}
					/>
					{src && (
						<ReactCrop
							className="ReactCrop__image"
							aspect={16 / 9}
							crop={crop}
							ruleOfThirds
							onComplete={onCropComplete}
							onChange={onCropChange}
						>
							<img src={src} onLoad={onImageLoaded} />
						</ReactCrop>
					)}
					{croppedImageUrl && (
						<cimg
							alt="Crop"
							style={{ padding: "25px", maxWidth: "100%" }}
							src={croppedImageUrl}
						/>
					)}
					{croppedImageUrl && (
						<Button
							className="mt-1"
							icon="add"
							title={"Yükle"}
							textColor="blue"
							backgroundColor="blue-opacity"
							fullWidth
							onClick={() =>
								uploadStorePhoto(croppedImageUrl)
							}
						/>
					)}
				</div>
			</AlertDialog>
		</AuthContainer>
	);

}

export default Photo;

const ProfilePhoto = styled.img`
	background: #fafafa;
	width: 480px;
	height: 270px;
	max-width: 100%;
	object-fit: cover;
	border-radius: 5px;
`;

const InfoDiv = styled.div`
	width: 100%;
`;

const Info = styled.p`
	color: blue;
	cursor: pointer;
	margin-top: 15px;
`;

const InfoPhoto = styled.img`
	width: 100%;
`;
