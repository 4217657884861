/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useContext, useState } from "react";
import AppContext from "../../../context/store";

import styled from "styled-components";
import AuthContainer from "../../../components/AuthContainer";
import { Grid, Column } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import { Alert } from "@material-ui/lab";
import Button from "../../../theme/Button";
import Axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FeedbackIndex = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [staffId, setStaffId] = useState(context.state.user.id);
  const [message, setMessage] = useState("");

  const insertFeedback = () => {
    `${message.replace(/\s/g, "")}`.length >= 2
      ? Axios.post(`${context.api_endpoint}/company/feedback/insert`, {
          staffId,
          message,
        })
          .then((response) => {
            if (response.status === 201) {
              toast.success(
                "Geri bildiriminiz başarıyla alındı. Teşekkür ederiz!"
              );
              history.push("/dashboard");
            }
          })
          .catch((e) => {
            if (e.response.status === 401) {
              toast.warning(e.response.data.message);
            }else{
              toast.error(e);
            }
          })
      : toast.warning("Lütfen geri bildiriminizi yazınız!");
  };

    return (
      <AuthContainer>
        <Grid>
          <Column className="xs-12 sm-12">
            <SectionTitle className="mb-1">Geri Bildirim Bırakın</SectionTitle>
            <Alert severity="info" className="mb-3">
              <b>Kullanıcılarımızın fikir, görüş ve önerilerini önemsiyoruz.</b>
              <br />
              <b>Salonrandevu</b> yönetim uygulaması olarak{" "}
              <b>kullanıcı deneyimini</b> nasıl arttırabileceğimiz konusunda
              çalışmalarımıza devam etmekteyiz. Bu amaç doğrultusunda siz
              değerli kullanıcılarımızla sürekli etkileşimde olabileceğimiz bir{" "}
              <b>geri bildirim kanalı geliştirdik.</b>
              <br />
              <br />
              <b>
                Sayfadaki form üzerinden her türlü fikir, görüş ve önerilerinizi
                bize iletebilirsiniz.
              </b>
            </Alert>

            <Input
              label="Bu alana yazabilirsiniz"
              multiline={true}
              rows={8}
              onChange={(e) => setMessage(e.target.value)}
            />

            <Button
              icon="send"
              title="Gönder"
              textColor="white"
              backgroundColor="primary"
              fullWidth
              onClick={() => insertFeedback()}
            />
          </Column>
        </Grid>
      </AuthContainer>
    );
}

export default FeedbackIndex;

const SectionTitle = styled.h3`
  font-size: 19px;
  padding: 0;
  margin: 0;
  color: var(--dark-primary);
`;
