/* eslint-disable */
import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";

import { Grid, Column } from "../../../../theme/Grid";
import Button from "../../../../theme/Button";
import Input from "../../../../theme/CustomMUI/Input";
import Select from "../../../../theme/CustomMUI/Select";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import MaskedInput from "../../../../theme/CustomMUI/MaskedInput";
import BetweenTimeGenerator from "../../../../functions/BetweenTimeGenerator";
import CSwitch from "../../../../theme/CustomMUI/CSwitch";
import { toast } from "react-toastify";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import {
  AddBox,
  AddOutlined,
  Person,
  Phone,
  Comment,
  Search,
  Payment,
  Receipt,
  Sms,
} from "@material-ui/icons";
import CalculateMessageCharCount from "../../../../functions/CalculateMessageCharCount";
import ReactToPrint from "react-to-print";

import Cash from "../../../../assets/images/svg/cash.svg";
import Credit from "../../../../assets/images/svg/credit.svg";
import Transfer from "../../../../assets/images/svg/transfer.svg";
import CustomerPoint from "../../../../assets/images/svg/customer_point.svg";
import CashWhite from "../../../../assets/images/svg/cash_white.svg";
import CreditWhite from "../../../../assets/images/svg/credit_white.svg";
import CustomerPointWhite from "../../../../assets/images/svg/customer_point_white.svg";
import TransferWhite from "../../../../assets/images/svg/transfer_white.svg";
import RemainingDebt from "../../../../assets/images/svg/remaining_debt.svg";
import RemainingDebtRed from "../../../../assets/images/svg/remaining_debt_red.svg";
import ReceiptOrange from "../../../../assets/images/svg/Receipt-orange.svg";
import PaymentGreen from "../../../../assets/images/svg/payment-green.svg";
import Debt from "../../../../assets/images/svg/debt.svg";
import StaffSelect from "../../../../theme/CustomMUI/StaffSelect";
import { Tooltip } from "@material-ui/core";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import FullScreenDialog from "../../../../theme/CustomMUI/FullScreenDialog";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AppContext from "../../../../context/store";
import {
  _ContractDialogContracts,
  ContractDialogCreateNewContract,
  _ContractDialogEntrance,
  _ContractDialogUploadContract,
} from "./ContractComponents";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const themeForDatePicker = createTheme({
  typography: {
    fontSize: 11,
  },
});

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "white",
        boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
      },
    },
  },
});

/**
 * Adisyonun üst alanındaki müşteri bilgileri, değişiklikleri kaydet buttonu gibi componentlerin gösterildiği alan.
 */
export const ReceiptHeadingBuilder = ({
  contract_tab,
  setContractTab,
  newAgreementDetail,
  setNewAgreementDetail,
  file,
  setFile,
  checkout_data,
  print_dialog,
  setPrintDialog,
  getCustomerAgreements,
  customer,
  staffs,
  services,
  stock_items,
  getAllTotalService,
  getTotalAmounts,
  sex,
  is_change,
  contract_dialog,
  setContractDialog,
  getAgreements,
  getClauses,
  contract_info_id_number,
  setContractInfoIdNumber,
  contract_info_tax_number,
  setContractInfoTaxNumber,
  contract_info_address,
  setContractInfoAddress,
  selected_pattern,
  setSelectedPattern,
  contract_patterns,
  selected_agreement_dialog_show,
  setSelectedAgreementDialogShow,
  contract_info_ful_name,
  setContractInfoFulName,
  contract_info_phone,
  setContractInfoPhone,
  contract_patterns_wo_clauses,
  logo_src,
  staffArr,
  servicesArr,
  clauses,
  Signature,
  content_to_signiture,
  setContentToSigniture,
  send_to_signiture_aproval_modal,
  setSendToSignitureAprovalModal,
  sendAgreementToMobile,
  agreement_customer_for_receipt,
  setAgreementCustomerForReceipt,
  agreement_title,
  setAgreementTitle,
  uploadAgreement,
  customer_agreements,
  agreement_load,
  pagination_agreement,
  wp_aproval_modal,
  setWpAprovalModal,
  send_wp_id,
  setSendWpId,
  send_wp_customer_name,
  setSendWpCustomerName,
  send_wp_agreement_name,
  setSendWpAgreementName,
  sms_aproval_modal,
  setSmsAprovalModal,
  send_sms_id,
  setSendSmsId,
  send_sms_customer_name,
  setSendSmsCustomerName,
  send_sms_agreement_name,
  setSendSmsAgreementName,
  delete_agreement_dialog,
  setDeleteAgreementDialog,
  sendSMSAgreement,
  sendWPAgreement,
  agreement_delete_id,
  setAgreementDeleteId,
  deleteAgreement,
  validateTCKN,
  getServices,
  getStaff,
  setAgreementDetail,
}) => {
  const context = useContext(AppContext);
  const history = useHistory();

  const ContractDialogEntrance = _ContractDialogEntrance;

  const ContractDialogUploadContract = _ContractDialogUploadContract;
  const ContractDialogContracts = _ContractDialogContracts;

  const printRef = useRef();
  return (
    <Grid className="no-gutters-row ml-3 mr-3">
      {print_dialog === true ? (
        <div ref={printRef}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              padding: "0px 70px",
            }}
          >
            <h2
              style={{
                margin: "40px",
                width: "100%",
                textAlign: "center",
              }}
            >
              {context.state.company_branch_first.name}
            </h2>
            <div
              style={{
                borderBottom: "2px solid #b2b2b2",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p style={{ width: "33%", textAlign: "center" }}>
                Adisyon No: {checkout_data.receipt_id}
              </p>
              <p style={{ width: "33%", textAlign: "center" }}>
                {customer.full_name}
              </p>
              <p style={{ width: "33%", textAlign: "center" }}>
                {moment().format("DD-MM-YYYY")}
              </p>
            </div>
            {/* Hizmetler */}
            {(Object.keys(checkout_data.services).filter(
              (key) => checkout_data.services[key] !== undefined
            ).length > 0 ||
              Object.keys(checkout_data.packages).filter(
                (key) => checkout_data.packages[key] !== undefined
              ).length > 0) && (
              <>
                <div style={{ width: "100%" }}>
                  <h3 style={{ paddingLeft: "20px", paddingTop: "10px" }}>
                    Hizmetler
                  </h3>
                </div>

                <div
                  style={{
                    borderBottom: "1px solid #b2b2b2",
                    display: "flex",
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <h4>Personel</h4>
                  <h4>Hizmet</h4>
                  <h4>Ücret</h4>
                </div>
              </>
            )}
            {Object.keys(checkout_data.services).filter(
              (key) => checkout_data.services[key] !== undefined
            ).length > 0 &&
              Object.keys(checkout_data.services).map(
                (key, index) =>
                  checkout_data.services[key] !== undefined && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                      }}
                      key={index}
                    >
                      {/* Çalışan */}
                      <p
                        style={{
                          width: "33%",
                          textAlign: "center",
                        }}
                      >
                        {
                          staffs.filter(
                            (item) =>
                              item.id === checkout_data.services[key].staff_id
                          )[0].full_name
                        }
                      </p>
                      {/* Hizmet */}

                      <p
                        style={{
                          width: "33%",
                          textAlign: "center",
                        }}
                      >
                        {
                          services.filter(
                            (item) =>
                              item.id === checkout_data.services[key].service_id
                          )[0].name
                        }
                      </p>
                      {/* Ücret */}
                      <p
                        style={{
                          width: "33%",
                          textAlign: "center",
                        }}
                      >
                        {checkout_data.services[key].amount}
                        {context.state.currency ? context.state.currency : "₺"}
                      </p>
                    </div>
                  )
              )}
            {/* Paket Hizmetleri */}
            {Object.keys(checkout_data.packages).filter(
              (key) => checkout_data.packages[key] !== undefined
            ).length > 0 &&
              Object.keys(checkout_data.packages).map(
                (key, index) =>
                  checkout_data.packages[key] !== undefined && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                      }}
                      key={index}
                    >
                      {/* Çalışan */}
                      <p
                        style={{
                          width: "33%",
                          textAlign: "center",
                        }}
                      >
                        {
                          staffs.filter(
                            (item) =>
                              item.id === checkout_data.packages[key].staff_id
                          )[0].full_name
                        }
                      </p>

                      {/* Hizmet */}
                      <p
                        style={{
                          width: "33%",
                          textAlign: "center",
                        }}
                      >
                        {
                          services.filter(
                            (item) =>
                              item.id === checkout_data.packages[key].service_id
                          )[0].name
                        }
                      </p>

                      {/* Ücret */}
                      <p
                        style={{
                          width: "33%",
                          textAlign: "center",
                        }}
                      >
                        {checkout_data.packages[key].amount}
                        {context.state.currency ? context.state.currency : "₺"}
                      </p>
                    </div>
                  )
              )}
            {/* Ürünler */}
            {Object.keys(checkout_data.sales).filter(
              (key) => checkout_data.sales[key] !== undefined
            ).length > 0 && (
              <>
                <div style={{ width: "100%" }}>
                  <h3 style={{ paddingLeft: "20px", paddingTop: "10px" }}>
                    Ürünler
                  </h3>
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #b2b2b2",
                    display: "flex",
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <h4>Ürün</h4>
                  <h4>Adet</h4>
                  <h4>Ücret</h4>
                </div>
              </>
            )}
            {Object.keys(checkout_data.sales).filter(
              (key) => checkout_data.sales[key] !== undefined
            ).length > 0 &&
              Object.keys(checkout_data.sales).map(
                (key, index) =>
                  checkout_data.sales[key] !== undefined && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                      }}
                      key={index}
                    >
                      {/* Ürün */}
                      <p
                        style={{
                          width: "33%",
                          textAlign: "center",
                        }}
                      >
                        {
                          stock_items.filter(
                            (item) =>
                              item.id === checkout_data.sales[key].stock_item_id
                          )[0].name
                        }
                      </p>

                      {/* Adet */}
                      <p
                        style={{
                          width: "33%",
                          textAlign: "center",
                        }}
                      >
                        {checkout_data.sales[key].quantity}
                      </p>

                      {/* Ücret */}
                      <p
                        style={{
                          width: "33%",
                          textAlign: "center",
                        }}
                      >
                        {checkout_data.sales[key].amount}
                        {context.state.currency ? context.state.currency : "₺"}
                      </p>
                    </div>
                  )
              )}
            {/* Adisyon Özeti */}
            <div
              style={{
                width: "100%",
                marginTop: "40px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div
                className="mt-2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "20px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    marginRight: "5px",
                  }}
                >
                  Adisyon Toplamı:
                </span>
                <p>
                  {(
                    Object.keys(checkout_data.packages)
                      .map((key) => checkout_data.packages[key])
                      .map((item) => item.service_id)
                      .map((item) => {
                        return services.filter(
                          (service) => service.id === item
                        )[0].amount;
                      })
                      .reduce((acc, cur) => acc + cur, 0) +
                    Object.keys(checkout_data.services)
                      .map((key) => checkout_data.services[key])
                      .map((item) => item.service_id)
                      .map((item) => {
                        return services.filter(
                          (service) => service.id === item
                        )[0].amount;
                      })
                      .reduce((acc, cur) => acc + cur, 0) +
                    Object.keys(checkout_data.sales)
                      .map((key) => checkout_data.sales[key])
                      .map((item) => item.stock_item_id)
                      .map((item, index) => {
                        return (
                          stock_items.filter(
                            (stockItems) => stockItems.id === item
                          )[0].amount * checkout_data.sales[index].quantity
                        );
                      })
                      .reduce((acc, cur) => acc + cur, 0)
                  ).toFixed(0)}
                  {context.state.currency ? context.state.currency : "₺"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "20px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    marginRight: "5px",
                  }}
                >
                  İndirim:
                </span>
                <p>
                  {(
                    Object.keys(checkout_data.packages)
                      .map((key) => checkout_data.packages[key])
                      .map((item) => item.service_id)
                      .map((item) => {
                        return services.filter(
                          (service) => service.id === item
                        )[0].amount;
                      })
                      .reduce((acc, cur) => acc + cur, 0) +
                    Object.keys(checkout_data.services)
                      .map((key) => checkout_data.services[key])
                      .map((item) => item.service_id)
                      .map((item) => {
                        return services.filter(
                          (service) => service.id === item
                        )[0].amount;
                      })
                      .reduce((acc, cur) => acc + cur, 0) +
                    Object.keys(checkout_data.sales)
                      .map((key) => checkout_data.sales[key])
                      .map((item) => item.stock_item_id)
                      .map((item, index) => {
                        return (
                          stock_items.filter(
                            (stockItems) => stockItems.id === item
                          )[0].amount * checkout_data.sales[index].quantity
                        );
                      })
                      .reduce((acc, cur) => acc + cur, 0)
                  ).toFixed(0) -
                    (
                      Object.keys(checkout_data.packages)
                        .map((key) => checkout_data.packages[key].amount)
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.services)
                        .map((key) => checkout_data.services[key].amount)
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.sales)
                        .map(
                          (key) =>
                            checkout_data.sales[key].amount *
                            checkout_data.sales[key].quantity
                        )
                        .reduce((acc, cur) => acc + cur, 0)
                    ).toFixed(0)}
                  {context.state.currency ? context.state.currency : "₺"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "20px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    marginRight: "5px",
                  }}
                >
                  Net Toplam:
                </span>
                <p>
                  {(
                    Object.keys(checkout_data.packages)
                      .map((key) => checkout_data.packages[key].amount)
                      .reduce((acc, cur) => acc + cur, 0) +
                    Object.keys(checkout_data.services)
                      .map((key) => checkout_data.services[key].amount)
                      .reduce((acc, cur) => acc + cur, 0) +
                    Object.keys(checkout_data.sales)
                      .map(
                        (key) =>
                          checkout_data.sales[key].amount *
                          checkout_data.sales[key].quantity
                      )
                      .reduce((acc, cur) => acc + cur, 0)
                  ).toFixed(0)}
                  {context.state.currency ? context.state.currency : "₺"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "20px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    marginRight: "5px",
                  }}
                >
                  Ödenen Tutar:
                </span>
                <p>
                  {(
                    Object.keys(checkout_data.packages)
                      .map((key) => checkout_data.packages[key].amount)
                      .reduce((acc, cur) => acc + cur, 0) +
                    Object.keys(checkout_data.services)
                      .map((key) => checkout_data.services[key].amount)
                      .reduce((acc, cur) => acc + cur, 0) +
                    Object.keys(checkout_data.sales)
                      .map(
                        (key) =>
                          checkout_data.sales[key].amount *
                          checkout_data.sales[key].quantity
                      )
                      .reduce((acc, cur) => acc + cur, 0)
                  ).toFixed(0) -
                    parseFloat(getAllTotalService()) -
                    parseFloat(
                      getTotalAmounts().installment_total +
                        getTotalAmounts().paids
                    ) *
                      -1}
                  {context.state.currency ? context.state.currency : "₺"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "20px",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    marginRight: "5px",
                  }}
                >
                  Kalan Tutar:
                </span>
                <p>
                  {parseFloat(getAllTotalService()) -
                    parseFloat(
                      getTotalAmounts().installment_total +
                        getTotalAmounts().paids
                    )}
                  {context.state.currency ? context.state.currency : "₺"}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Column className="xs-12 sm-12 md-4 lg-6">
        <Column className="xs-12 sm-12 md-4 lg-3 mb-3">
          <ReceiptProfile
            style={{
              cursor: "pointer",
              border: `1px solid ${sex === 0 ? "#8c0fb4" : "#3189ec"}`,
              backgroundColor: `${
                sex === 0 ? "rgba(140,15,180, 0.1)" : "rgba(49,137,236,0.08)"
              }`,
            }}
            onClick={() => history.push(`/customers/detail/${customer.id}`)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Person
                style={{
                  marginRight: "5px",
                  color: `${sex === 0 ? "#8c0fb4" : "#3189ec"}`,
                }}
              />
              <div
                style={{
                  marginTop: "5px",
                  fontSize: `${
                    customer.full_name?.length > 15 ? "8px" : "12px"
                  }`,
                }}
              >
                {customer.full_name || "Tanımlanmamış Müşteri"}
              </div>
            </div>
            <div>
              <Phone style={{ marginRight: "5px", color: "#b2b2b2" }} />
              <>{customer.phone || null}</>
            </div>
          </ReceiptProfile>
        </Column>

        <Column className="xs-12 sm-12 md-4 lg-3">
          <div
            onMouseUp={async () => {
              await setPrintDialog(true);
            }}
          >
            <ReactToPrint
              trigger={() => (
                <Button
                  icon="print"
                  fullWidth
                  size="lg"
                  title={"Yazdır"}
                  backgroundColor="teal-opacity"
                  textColor="teal"
                  outlined={true}
                />
              )}
              content={() => printRef.current}
              onAfterPrint={async () => {
                await setPrintDialog(false);
              }}
            />
          </div>
        </Column>

        <Column className="xs-12 sm-12 md-4 lg-3">
          <Button
            icon="contacts"
            fullWidth
            size="lg"
            outlined={true}
            title={"Sözleşme"}
            backgroundColor="orange-opacity"
            textColor="orange"
            onClick={() => {
              //
              if (context.state.company_license.accounting === 3) {
                if (is_change) {
                  toast.warning(
                    "Yapmış olduğunuz değişiklikleri lütfen kaydediniz."
                  );
                } else {
                  setContractDialog(true);
                  getAgreements();
                  getClauses();
                }
              } else {
                toast.warn(
                  "Diamond lisans paketi için olan bu özelliği kullanabilmek için Diamond lisans paketini satın almanız gerekmektedir."
                );
              }
            }}
          />
        </Column>

        <Column className="xs-12 sm-12 md-4 lg-3">
          <CustomerBoxDiv
            style={{
              border: "1px solid #3189ec",
              backgroundColor: "rgba(49,137,236,0.08)",
            }}
          >
            <img
              src={CustomerPoint}
              alt="customer_point"
              style={{ height: "70%" }}
            />
            <CustomerPointBox>
              <span>
                {customer.point !== null && customer.point !== undefined
                  ? `${customer.point}`
                  : "0"}{" "}
                {context.t(`['receipt/detail/id'].point`)}
                <b>
                  ~
                  {customer.point !== null && customer.point !== undefined
                    ? `${customer.point / 100}`
                    : "0"}
                  {context.state.currency ? context.state.currency : "₺"}
                </b>
              </span>
              {/* <h3>{context.t(`['receipt/detail/id'].customerPoint`)}</h3> */}
            </CustomerPointBox>
          </CustomerBoxDiv>
        </Column>
      </Column>

      <Column className="xs-12 sm-12 md-4 lg-6">
        <Column
          className="xs-12 sm-12 md-12 lg-3"
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PaymentSection
            style={{
              backgroundColor: "rgb(255, 144, 22, 0.14)",
              border: "1px solid #ff9016",
            }}
          >
            <img src={ReceiptOrange} alt="receipt" />
            <SubTotalText
              style={{
                color: "#ff9016",
              }}
            >
              <p
                style={{
                  fontSize: "17px",
                  fontWeight: "400",
                }}
              >
                {getAllTotalService().toFixed(0)}
                {context.state.currency ? context.state.currency : "₺"}
              </p>
              <span>
                {context.t(`['receipt/detail/id'].payments.totalAmount`)}
              </span>
            </SubTotalText>
          </PaymentSection>
        </Column>

        <Column
          className="xs-12 sm-12 md-12 lg-3"
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PaymentSection
            style={{
              backgroundColor: "rgb(29, 139, 62, 0.21)",
              border: "1px solid rgb(58, 154, 87, 0.35)",
            }}
          >
            <img src={PaymentGreen} alt="payment" />
            <SubTotalText
              style={{
                color: "#1D8B3E",
              }}
            >
              <p
                style={{
                  fontSize: "17px",
                  fontWeight: "400",
                }}
              >
                {parseFloat(
                  getTotalAmounts().installment_total + getTotalAmounts().paids
                )}
                {context.state.currency ? context.state.currency : "₺"}
              </p>
              <span>
                {context.t(`['receipt/detail/id'].payments.totalPaidAmount`)}
              </span>
            </SubTotalText>
          </PaymentSection>
        </Column>

        <Column
          className="xs-12 sm-12 md-12 lg-3"
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PaymentSection
            style={{
              backgroundColor: "rgb(254, 151, 151, 0.19)",
              border: "1px solid rgb(185, 178, 178, 0.65)",
            }}
          >
            <img src={Debt} alt="debt" />
            <SubTotalText
              style={{
                color: `${
                  parseFloat(getAllTotalService()).toFixed(0) -
                    parseFloat(
                      getTotalAmounts().installment_total +
                        getTotalAmounts().paids
                    ).toFixed(0) >
                  0
                    ? "#FC8E8E"
                    : "grey"
                }`,
              }}
            >
              <p
                style={{
                  fontSize: "17px",
                  fontWeight: "400",
                }}
              >
                {parseFloat(getAllTotalService()).toFixed(0) -
                  parseFloat(
                    getTotalAmounts().installment_total +
                      getTotalAmounts().paids
                  ).toFixed(0)}
                {context.state.currency ? context.state.currency : "₺"}
              </p>
              <span>
                {context.t(`['receipt/detail/id'].payments.totalDebtAmount`)}
              </span>
            </SubTotalText>
          </PaymentSection>
        </Column>
        <Column
          className="xs-12 sm-12 md-12 lg-3"
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PaymentSection
            style={{
              backgroundColor: "rgba(49,137,236,0.08)",
              border: "1px solid rgb(185, 178, 178, 0.65)",
            }}
          >
            <Button
              icon="storage"
              size="lg"
              title={"Adisyon işlem kayıtlarına git"}
              onClick={() => {
                context.dispatch({
                  type: "LOG_TYPE",
                  payload: { log_type: 2 },
                });
                context.dispatch({
                  type: "LOG_ID",
                  payload: {
                    log_id: Number(checkout_data.receipt_id),
                  },
                });
                history.push("/settings/logs");
              }}
            />
          </PaymentSection>
        </Column>
      </Column>

      {/* Sözleşme için açılan pop-up */}
      <AlertDialog
        title={""}
        open={contract_dialog}
        maxWidth={contract_tab === 3 || file ? "md" : "sm"}
        fullWidth
        disableBackdropClick={false}
        closeHandler={() => {
          setContractDialog(false);
          setContractInfoIdNumber("");
          setContractInfoTaxNumber("");
          setContractInfoAddress("");
        }}
      >
        {contract_tab !== 0 && (
          <ArrowBackIosIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setContractTab(0);
              setSelectedPattern({
                Clauses: [],
                Template: [],
              });
              setNewAgreementDetail(false);
              setFile(null);
            }}
          />
        )}
        {contract_tab === 0 && (
          <ContractDialogEntrance
            setContractTab={setContractTab}
            getCustomerAgreements={getCustomerAgreements}
            checkout_data={checkout_data}
          />
        )}
        {contract_tab === 1 && (
          <ContractDialogCreateNewContract
            checkout_data={checkout_data}
            contract_patterns={contract_patterns}
            selected_pattern={selected_pattern}
            setSelectedPattern={setSelectedPattern}
            setNewAgreementDetail={setNewAgreementDetail}
            selected_agreement_dialog_show={selected_agreement_dialog_show}
            setSelectedAgreementDialogShow={setSelectedAgreementDialogShow}
            newAgreementDetail={newAgreementDetail}
            setAgreementDetail={setAgreementDetail}
            contract_info_ful_name={contract_info_ful_name}
            setContractInfoFulName={setContractInfoFulName}
            contract_info_phone={contract_info_phone}
            setContractInfoPhone={setContractInfoPhone}
            contract_info_id_number={contract_info_id_number}
            setContractInfoIdNumber={setContractInfoIdNumber}
            contract_info_tax_number={contract_info_tax_number}
            setContractInfoTaxNumber={setContractInfoTaxNumber}
            contract_info_address={contract_info_address}
            setContractInfoAddress={setContractInfoAddress}
            contract_patterns_wo_clauses={contract_patterns_wo_clauses}
            logo_src={logo_src}
            staffArr={staffArr}
            servicesArr={servicesArr}
            getAllTotalService={getAllTotalService}
            clauses={clauses}
            Signature={Signature}
            content_to_signiture={content_to_signiture}
            setContentToSigniture={setContentToSigniture}
            send_to_signiture_aproval_modal={send_to_signiture_aproval_modal}
            setSendToSignitureAprovalModal={setSendToSignitureAprovalModal}
            sendAgreementToMobile={sendAgreementToMobile}
          />
        )}
        {contract_tab === 2 && (
          <ContractDialogUploadContract
            file={file}
            setFile={setFile}
            agreement_customer_for_receipt={agreement_customer_for_receipt}
            setAgreementCustomerForReceipt={setAgreementCustomerForReceipt}
            agreement_title={agreement_title}
            setAgreementTitle={setAgreementTitle}
            uploadAgreement={uploadAgreement}
          />
        )}
        {contract_tab === 3 && (
          <ContractDialogContracts
            customer_agreements={customer_agreements}
            agreement_load={agreement_load}
            pagination_agreement={pagination_agreement}
            wp_aproval_modal={wp_aproval_modal}
            setWpAprovalModal={setWpAprovalModal}
            send_wp_id={send_wp_id}
            setSendWpId={setSendWpId}
            send_wp_customer_name={send_wp_customer_name}
            setSendWpCustomerName={setSendWpCustomerName}
            send_wp_agreement_name={send_wp_agreement_name}
            setSendWpAgreementName={setSendWpAgreementName}
            sms_aproval_modal={sms_aproval_modal}
            setSmsAprovalModal={setSmsAprovalModal}
            send_sms_id={send_sms_id}
            setSendSmsId={setSendSmsId}
            send_sms_customer_name={send_sms_customer_name}
            setSendSmsCustomerName={setSendSmsCustomerName}
            send_sms_agreement_name={send_sms_agreement_name}
            setSendSmsAgreementName={setSendSmsAgreementName}
            delete_agreement_dialog={delete_agreement_dialog}
            setDeleteAgreementDialog={setDeleteAgreementDialog}
            sendSMSAgreement={sendSMSAgreement}
            sendWPAgreement={sendWPAgreement}
            agreement_delete_id={agreement_delete_id}
            setAgreementDeleteId={setAgreementDeleteId}
            deleteAgreement={deleteAgreement}
          />
        )}

        {newAgreementDetail ? (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div
              style={{
                width: "35%",
              }}
            >
              <Button
                fullWidth
                title="Önizleme"
                textColor="white"
                backgroundColor="orange"
                onClick={() => {
                  if (
                    selected_pattern.Template.nationality_id &&
                    contract_info_id_number !== "" &&
                    !validateTCKN(contract_info_id_number)
                  ) {
                    toast.error("Geçersiz T.C. Kimlik Numarası");
                  } else {
                    setSelectedAgreementDialogShow(true);
                    getServices();
                    getStaff();
                  }
                }}
              />
            </div>
            <div
              style={{
                width: "35%",
              }}
            >
              <Button
                fullWidth
                outlined
                icon="clear"
                title={contract_tab === 3 ? "Kapat" : "İptal"}
                textColor="#ccc"
                onClick={() => {
                  setContractDialog(false);
                  setTimeout(() => {
                    setContractTab(0);
                    setSelectedPattern({
                      Clauses: [],
                      Template: [],
                    });
                    setNewAgreementDetail(false);
                    setContractInfoTaxNumber("");
                    setContractInfoAddress("");
                    setFile(null);
                    setAgreementTitle("");
                  }, 500);
                }}
              />
            </div>{" "}
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "35%" }}>
              <Button
                title={contract_tab === 3 ? "Kapat" : "İptal"}
                fullWidth
                outlined
                icon="clear"
                textColor="#ccc"
                onClick={() => {
                  setContractDialog(false);
                  setTimeout(() => {
                    setContractTab(0);
                    setSelectedPattern({
                      Clauses: [],
                      Template: [],
                    });
                    setNewAgreementDetail(false);
                    setContractInfoTaxNumber("");
                    setContractInfoAddress("");
                  }, 500);
                }}
              />
            </div>
          </div>
        )}
      </AlertDialog>
    </Grid>
  );
};

/**
 * Adisyona ödeme yöntemlerinin eklendiği, düzenlendiği alan.
 */
export const _PaymentsBuilder = ({
  checkout_data,
  setCheckoutData,
  advanced_date,
  advanced_amount,
  advanced_staff,
  selected_installment,
  setSelectedInstallment,
  setSelectedInstallmentStatus,
  advanced_type,
  installment_date,
  setInstallmentDate,
  installment_data,
  getTotalAmounts,
  setPaymentDetailDialogModal,
  setAddPaymentDialog,
  addPayment_dialog,
  setAdvancedAmount,
  setIsChange,
  is_change,
  getPaidsDiffTotal,
  setAdvancedDate,
  setAdvancedType,
  setAdvancedStaff,
  setPaymentSelectStaff,
  staffs,
  payment_select_staff,
  setInstallmentDialog,
  installment_dialog,
  getAllTotalService,
  getInstallmentDiffTotal,
  setSaveButtonDisabled,
  saveSingleReceipt,
}) => {
  const context = useContext(AppContext);
  let total_mount = 0;
  const rounded_amount = Math.round(getTotalAmounts().installment);

  let amountData = Object.keys(checkout_data.installment).map((key) =>
    checkout_data.installment[key] !== undefined
      ? checkout_data.installment[key].installment_amount !== NaN
        ? checkout_data.installment[key].installment_amount
        : 0
      : 0
  );

  for (let i = 0; i < amountData.length - 1; i++) {
    total_mount += Math.round(amountData[i]);
  }

  for (let j = 0; j < amountData.length; j++) {
    amountData[j] = Math.round(amountData[j]);
    if (amountData[amountData.length - 1]) {
      amountData[amountData.length - 1] = rounded_amount - total_mount;
    }
  }

  useEffect(() => {
    Object.keys(checkout_data.installment).map((key) => {
      checkout_data.installment[key] !== undefined
        ? checkout_data.installment[key].installment_amount !== NaN
          ? (checkout_data.installment[key].installment_amount = Math.round(
              amountData[key]
            ))
          : 0
        : 0;
    });
  }, [checkout_data]);

  useEffect(() => {
    if (is_change === true) {
      saveSingleReceipt();
    }
  }, [is_change]);

  return (
    <>
      <SectionBox style={{ overflowX: "hidden" }}>
        <SectionHeading
          style={{
            backgroundColor: "rgba(29,139,62, 0.08)",
            color: "green",
            border: "1px solid green",
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            onClick={() => setPaymentDetailDialogModal(true)}
            className="detail-search"
            style={{ backgroundColor: "green" }}
          >
            <Search style={{ color: "white", fontSize: "20px" }} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Payment />
            <h3 style={{ marginLeft: "15px" }}>
              {context.t(`['receipt/detail/id'].payments.title`)}
            </h3>
          </div>
          <div>
            <AddBox
              style={{ cursor: "pointer", fontSize: "36px" }}
              onClick={() => {
                setAddPaymentDialog(true);
                setAdvancedAmount(getPaidsDiffTotal());
              }}
            />
          </div>
        </SectionHeading>

        <AlertDialog
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "5px",
              }}
            >
              <AddOutlined
                style={{
                  color: "#6097D2",
                }}
              />
              <span
                style={{
                  color: "#6097D2",
                }}
              >
                {context.t(`['receipt/detail/id'].addPaymentTitle`)}
              </span>
            </div>
          }
          open={addPayment_dialog}
          fullWidth
          maxWidth="xs"
          closeHandler={() => setAddPaymentDialog(false)}
          buttons={[
            {
              title: context.t(
                `appointments.create.completePaymentButtonTitle`
              ),
              icon: "check",
              backgroundColor: "green",
              style: {
                height: "56px",
              },
              textColor: "white",
              onClick: () => {
                setCheckoutData({
                  ...checkout_data,
                  paids: {
                    ...checkout_data.paids,
                    [Object.keys(checkout_data.paids).length + 1]: {
                      payment_date: advanced_date,
                      payment_type: advanced_type,
                      pay_amount: parseFloat(advanced_amount),
                      staff_id: advanced_staff?.id,
                    },
                  },
                });
                setAddPaymentDialog(false);
                setAdvancedAmount(null);
                setAdvancedDate(new Date());
                setAdvancedType(0);
                setAdvancedStaff(null);
                setPaymentSelectStaff({});
              },
            },
            {
              title: context.t(`appointments.create.discardButtonTitle`),
              icon: "close",
              backgroundColor: "white",
              style: {
                border: "1px solid #666666",
                height: "56px",
              },
              textColor: "black",
              onClick: () => {
                setAddPaymentDialog(false);
                setPaymentSelectStaff({});
                setAdvancedStaff(null);
              },
            },
          ]}
        >
          <>
            <Column>
              <span
                style={{
                  marginBottom: "10px",
                  marginLeft: "5px",
                  fontSize: "18px",
                }}
              >
                Ödeme Tarihi *
              </span>
              <DatePickerInput
                // label={context.t(
                //   `['receipt/detail/id'].payments.dateInputLabel`
                // )}
                value={moment(advanced_date).local().toISOString()}
                onChange={(payment_date) => {
                  setAdvancedDate(payment_date);
                }}
              />
            </Column>
            <Column>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <AdvancedType
                  style={{
                    backgroundColor: advanced_type == 0 ? "#78B8FF" : "#FFFFFF",
                    filter:
                      advanced_type == 0
                        ? "drop-shadow(0px 3px 6px #78B8FF)"
                        : "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.149))",
                    border:
                      advanced_type == 0
                        ? "1px solid #6BB0FE"
                        : "1px solid #b8b8b8",
                  }}
                  onClick={() => setAdvancedType(0)}
                >
                  {advanced_type == 0 ? (
                    <img src={CashWhite} alt="cash_white" />
                  ) : (
                    <img src={Cash} alt="cash" />
                  )}
                  <span
                    style={{
                      color: advanced_type == 0 ? "#FFFFFF" : "#000000",
                    }}
                  >
                    Nakit
                  </span>
                </AdvancedType>
                <AdvancedType
                  style={{
                    marginLeft: "10px",
                    backgroundColor: advanced_type == 2 ? "#78B8FF" : "#FFFFFF",
                    filter:
                      advanced_type == 2
                        ? "drop-shadow(0px 3px 6px #78B8FF)"
                        : "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.149))",
                    border:
                      advanced_type == 2
                        ? "1px solid #6BB0FE"
                        : "1px solid #b8b8b8",
                  }}
                  onClick={() => {
                    setAdvancedType(2);
                  }}
                >
                  {advanced_type == 2 ? (
                    <img src={TransferWhite} alt="transfer_white" />
                  ) : (
                    <img src={Transfer} alt="transfer" />
                  )}
                  <span
                    style={{
                      color: advanced_type == 2 ? "#FFFFFF" : "#000000",
                    }}
                  >
                    Havale/EFT
                  </span>
                </AdvancedType>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <AdvancedType
                  style={{
                    backgroundColor: advanced_type == 1 ? "#78B8FF" : "#FFFFFF",
                    filter:
                      advanced_type == 1
                        ? "drop-shadow(0px 3px 6px #78B8FF)"
                        : "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.149))",
                    border:
                      advanced_type == 1
                        ? "1px solid #6BB0FE"
                        : "1px solid #b8b8b8",
                  }}
                  onClick={() => {
                    setAdvancedType(1);
                  }}
                >
                  {advanced_type == 1 ? (
                    <img src={CreditWhite} alt="credit_white" />
                  ) : (
                    <img src={Credit} alt="credit" />
                  )}
                  <span
                    style={{
                      color: advanced_type == 1 ? "#FFFFFF" : "#000000",
                    }}
                  >
                    Kredi Kartı/Banka Kartı
                  </span>
                </AdvancedType>
                <AdvancedType
                  style={{
                    marginLeft: "10px",
                    backgroundColor: advanced_type == 3 ? "#78B8FF" : "#FFFFFF",
                    filter:
                      advanced_type == 3
                        ? "drop-shadow(0px 3px 6px #78B8FF)"
                        : "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.149))",
                    border:
                      advanced_type == 3
                        ? "1px solid #6BB0FE"
                        : "1px solid #b8b8b8",
                  }}
                  onClick={() => {
                    setAdvancedType(3);
                  }}
                >
                  {advanced_type == 3 ? (
                    <img src={CustomerPointWhite} alt="customer_point_white" />
                  ) : (
                    <img src={CustomerPoint} alt="customer_point" />
                  )}
                  <span
                    style={{
                      color: advanced_type == 3 ? "#FFFFFF" : "#000000",
                    }}
                  >
                    Müşteri Puanı
                  </span>
                </AdvancedType>
              </div>
            </Column>
            <Column>
              <StaffSelect
                disabled={context.state.user.permission === 0}
                label="Personel Seçimi (Opsiyonel)"
                items={staffs}
                selected={payment_select_staff?.staff_id}
                labelKey="full_name"
                valueKey="id"
                returnFullObject
                handler={(staff) => {
                  setAdvancedStaff(staff);
                  setPaymentSelectStaff({
                    ...payment_select_staff,
                    staff_id: parseInt(staff.id),
                  });
                }}
              />
            </Column>
            <Column>
              <span
                style={{
                  marginBottom: "10px",
                  marginLeft: "5px",
                  fontSize: "18px",
                }}
              >
                Ödeme Tutarı *
              </span>
              <MaskedInput
                // label={"Ödeme Tutarı"}
                mask="9999999999999"
                maskChar=""
                value={advanced_amount ? advanced_amount : ""}
                onChange={(e) => {
                  setAdvancedAmount(e.target.value);
                }}
              />
            </Column>
          </>
        </AlertDialog>
        <ListSection className="no-scroll-bar">
          {Object.keys(checkout_data.paids).filter(
            (key) => checkout_data.paids[key] !== undefined
          ).length > 0 ? (
            Object.keys(checkout_data.paids).map(
              (key, index) =>
                checkout_data.paids[key] !== undefined && (
                  <Grid className="no-gutters-row" key={index}>
                    <Column className="xs-12 sm-12 md-12 mt-1 lg-3">
                      <MuiThemeProvider theme={themeForDatePicker}>
                        <DatePickerInput
                          label={context.t(
                            `['receipt/detail/id'].payments.dateInputLabel`
                          )}
                          value={
                            new Date(
                              moment(checkout_data.paids[key].payment_date)
                                .local()
                                .toISOString()
                            )
                          }
                          onChange={(payment_date) => {
                            setCheckoutData({
                              ...checkout_data,
                              paids: {
                                ...checkout_data.paids,
                                [key]: {
                                  ...checkout_data.paids[key],
                                  payment_date,
                                },
                              },
                            });
                          }}
                        />
                      </MuiThemeProvider>
                    </Column>

                    <Column className="xs-12 sm-12 md-12 lg-3">
                      <Select
                        label={context.t(
                          `['receipt/detail/id'].payments.typeInputLabel`
                        )}
                        items={[
                          {
                            label: context.t(
                              `['receipt/detail/id'].payments.typeCASH`
                            ),
                            value: "0",
                          },
                          {
                            label: context.t(
                              `['receipt/detail/id'].payments.typeCARD`
                            ),
                            value: "1",
                          },
                          {
                            label: context.t(
                              `['receipt/detail/id'].payments.typeEFT`
                            ),
                            value: "2",
                          },
                          {
                            label: context.t(
                              `['receipt/detail/id'].payments.typePOINT`
                            ),
                            value: "3",
                          },
                        ]}
                        selected={`${checkout_data.paids[key].payment_type}`}
                        labelKey="label"
                        valueKey="value"
                        handler={(payment_type) => {
                          setCheckoutData({
                            ...checkout_data,
                            paids: {
                              ...checkout_data.paids,
                              [key]: {
                                ...checkout_data.paids[key],
                                payment_type: parseInt(payment_type),
                              },
                            },
                          });
                        }}
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-3">
                      {/* Select Staff For Paids */}

                      <StaffSelect
                        label="Personel"
                        items={staffs}
                        selected={
                          checkout_data.paids[key]?.staff_id
                            ? checkout_data.paids[key]?.staff_id
                            : ""
                        }
                        labelKey="full_name"
                        valueKey="id"
                        handler={(staff_id) => {
                          setCheckoutData({
                            ...checkout_data,
                            paids: {
                              ...checkout_data.paids,
                              [key]: {
                                ...checkout_data.paids[key],
                                staff_id: parseInt(staff_id),
                              },
                            },
                          });
                        }}
                      />
                    </Column>

                    <Column className="xs-12 sm-12 md-12 lg-3">
                      <MaskedInput
                        label={
                          checkout_data.paids[key].payment_type === 3
                            ? context.t(
                                `['receipt/detail/id'].payments.pointInputLabel`
                              )
                            : context.t(
                                `['receipt/detail/id'].payments.amountInputLabel`
                              ) +
                              (context.state.currency
                                ? "(" + context.state.currency + ")"
                                : "₺")
                        }
                        mask="9999999999999"
                        maskChar=""
                        value={
                          checkout_data.paids[key].pay_amount !== null
                            ? parseFloat(checkout_data.paids[key].pay_amount)
                            : ""
                        }
                        onChange={(e) => {
                          setCheckoutData({
                            ...checkout_data,
                            paids: {
                              ...checkout_data.paids,
                              [key]: {
                                ...checkout_data.paids[key],
                                pay_amount:
                                  e.target.value &&
                                  /[0-9,.]+/.test(e.target.value)
                                    ? parseFloat(e.target.value)
                                    : null,
                              },
                            },
                          });
                        }}
                      />
                    </Column>
                  </Grid>
                )
            )
          ) : (
            <NoDataContainer style={{ height: "90%" }}>
              <img
                style={{ height: "80%" }}
                src={require("../../../../assets/images/manypixels/online_payment_.svg")}
                alt="Malesef bulunamadı!"
              />
              <p>{context.t(`['receipt/detail/id'].payments.noDataText`)}</p>
            </NoDataContainer>
          )}
        </ListSection>
      </SectionBox>

      {/* İnstallMent */}
      <SectionBox style={{ overflowX: "hidden" }}>
        <SectionHeading
          style={{
            backgroundColor: "rgba(255,0,0, 0.08)",
            color: "red",
            border: "1px solid red",
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="detail-search"
            style={{
              backgroundColor: "red",
            }}
            onClick={() => {
              setPaymentDetailDialogModal(true);
            }}
          >
            <Search style={{ color: "white", fontSize: "20px" }} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Payment />
            <h3 style={{ marginLeft: "15px" }}>
              {" "}
              {context.t(`['receipt/detail/id'].installment.title`)}
            </h3>
          </div>
          <div>
            <AddBox
              style={{ cursor: "pointer", fontSize: "36px" }}
              onClick={() => {
                setInstallmentDialog(true);
              }}
            />
          </div>
        </SectionHeading>
        <AlertDialog
          title={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <span
                style={{
                  width: "120%",
                  marginTop: "15px",
                }}
              >
                {context.t(`['receipt/detail/id'].installmentProcess`)}
              </span>
              <CustomerBoxDiv>
                {parseFloat(getAllTotalService()) -
                  parseFloat(
                    getTotalAmounts().installment_total +
                      getTotalAmounts().paids
                  ) ===
                0 ? (
                  <img src={RemainingDebt} alt="remaining_debt" />
                ) : (
                  <img src={RemainingDebtRed} alt="remaining_debt_red" />
                )}
                <CustomerPointBox>
                  <span>
                    {getInstallmentDiffTotal(1)}{" "}
                    {context.state.currency ? context.state.currency : "₺"}
                  </span>
                  <h3>{context.t(`['receipt/detail/id'].remainingDebt`)}</h3>
                </CustomerPointBox>
              </CustomerBoxDiv>
            </div>
          }
          open={installment_dialog}
          closeHandler={() => setInstallmentDialog(false)}
          fullWidth
          maxWidth="xs"
          buttons={[
            {
              title: context.t(`appointments.create.completeButtonTitle`),
              icon: "check",
              backgroundColor: "green",
              style: {
                height: "56px",
              },
              textColor: "white",
              onClick: () => {
                setSaveButtonDisabled(true);
                for (var i = 0; i < selected_installment; i++) {
                  installment_data.push({
                    installment_date:
                      i === 0
                        ? moment(installment_date).format("YYYY-MM-DD")
                        : moment(installment_date)
                            .add(i, "months")
                            .format("YYYY-MM-DD"),
                    installment_type: 0,
                    installment_amount:
                      getInstallmentDiffTotal(selected_installment),
                    installment_status: 0,
                  });
                }
                setTimeout(() => {
                  setCheckoutData({
                    ...checkout_data,
                    installment: {
                      ...[
                        ...Object.keys(checkout_data.installment).map(
                          (key) => checkout_data.installment[key]
                        ),
                        ...installment_data,
                      ],
                    },
                  });
                }, 100);
                setTimeout(() => {
                  setInstallmentDialog(false);
                  setSaveButtonDisabled(false);
                }, 500);
                // setTimeout(() => {
                //   window.location.reload();
                // }, 300);
              },
            },
            {
              title: context.t(`appointments.create.discardButtonTitle`),
              icon: "close",
              backgroundColor: "white",
              style: {
                border: "1px solid #666666",
                height: "56px",
              },
              textColor: "black",
              onClick: () => setInstallmentDialog(false),
            },
          ]}
        >
          <>
            <Column>
              <span
                style={{
                  marginBottom: "10px",
                  marginLeft: "5px",
                  fontSize: "18px",
                }}
              >
                Ödeme Tarihi *
              </span>
              <DatePickerInput
                // label={context.t(
                //   `['receipt/detail/id'].payments.dateInputLabel`
                // )}
                value={moment(installment_date).local().toISOString()}
                onChange={(payment_date) => {
                  setInstallmentDate(payment_date);
                }}
              />
            </Column>
            <Column>
              <span
                style={{
                  marginBottom: "10px",
                  marginLeft: "5px",
                  fontSize: "18px",
                }}
              >
                Taksit Sayısı *
              </span>
              <Select
                // label={context.t(
                //   `['receipt/detail/id'].installmentCountInputLabel`
                // )}
                labelKey="label"
                valueKey="value"
                items={[
                  { value: 2, label: "2 Taksit" },
                  { value: 3, label: "3 Taksit" },
                  { value: 4, label: "4 Taksit" },
                  { value: 5, label: "5 Taksit" },
                  { value: 6, label: "6 Taksit" },
                  { value: 7, label: "7 Taksit" },
                  { value: 8, label: "8 Taksit" },
                  { value: 9, label: "9 Taksit" },
                  { value: 10, label: "10 Taksit" },
                  { value: 11, label: "11 Taksit" },
                  { value: 12, label: "12 Taksit" },
                ]}
                selected={selected_installment ? selected_installment : null}
                handler={(installment) => {
                  setSelectedInstallment(installment);
                }}
              />
            </Column>
            {selected_installment && (
              <Column>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <p style={{ marginRight: "5px" }}>Ödemeler:</p>
                  <p>
                    {Math.round(getInstallmentDiffTotal(selected_installment))}
                    {context.state.currency
                      ? context.state.currency
                      : "₺"} x {selected_installment}
                  </p>
                </div>
              </Column>
            )}
          </>
        </AlertDialog>
        <ListSection className="no-scroll-bar">
          {Object.values(checkout_data.installment).length > 0 ? (
            <>
              {Object.values(checkout_data.installment)
                .filter((item) => item !== undefined)
                .map((installment, index) => {
                  return (
                    <Grid className="no-gutters-row" key={index}>
                      <Column className="xs-12 sm-12 md-12 mt-1 lg-4">
                        <MuiThemeProvider theme={themeForDatePicker}>
                          <DatePickerInput
                            label={`${context.t(
                              `['receipt/detail/id'].payments.dateInputLabel`
                            )} / ${index + 1} Taksit`}
                            value={
                              new Date(
                                moment(installment.installment_date)
                                  .local()
                                  .toISOString()
                              )
                            }
                            onChange={(installment_date) => {
                              // installment_data[index].installment_date =
                              //   installment_date;
                              setCheckoutData({
                                ...checkout_data,
                                installment: {
                                  ...[
                                    ...Object.keys(
                                      checkout_data.installment
                                    ).map(
                                      (key) => checkout_data.installment[key]
                                    ),
                                  ],
                                  [index]: {
                                    ...checkout_data.installment[index],
                                    installment_date:
                                      moment(installment_date).format(
                                        "YYYY-MM-DD"
                                      ),
                                  },
                                },
                              });
                            }}
                          />
                        </MuiThemeProvider>
                      </Column>

                      <Column className="xs-12 sm-12 md-12 lg-3">
                        <Select
                          label={"Ödeme Durumu"}
                          items={[
                            {
                              label: "Bekleniyor",
                              value: "0",
                            },
                            {
                              label: "Ödendi",
                              value: "1",
                            },
                            {
                              label: "Ödenmedi",
                              value: "2",
                            },
                          ]}
                          selected={`${
                            installment.installment_status
                              ? installment.installment_status
                              : 0
                          }`}
                          labelKey="label"
                          valueKey="value"
                          handler={(installment_status) => {
                            // installment_data[index].installment_status =
                            //   parseInt(installment_status);
                            // this.setState({
                            //   selected_installment_status:
                            //     parseInt(installment_status),
                            // });
                            setCheckoutData({
                              ...checkout_data,
                              installment: {
                                ...[
                                  ...Object.keys(checkout_data.installment).map(
                                    (key) => checkout_data.installment[key]
                                  ),
                                ],
                                [index]: {
                                  ...checkout_data.installment[index],
                                  installment_status:
                                    parseInt(installment_status),
                                },
                              },
                            });
                          }}
                        />
                      </Column>

                      <Column className="xs-12 sm-12 md-12 lg-3">
                        <Select
                          label={context.t(
                            `['receipt/detail/id'].payments.typeInputLabel`
                          )}
                          items={[
                            {
                              label: context.t(
                                `['receipt/detail/id'].payments.typeCASH`
                              ),
                              value: "0",
                            },
                            {
                              label: context.t(
                                `['receipt/detail/id'].payments.typeCARD`
                              ),
                              value: "1",
                            },
                            {
                              label: context.t(
                                `['receipt/detail/id'].payments.typeEFT`
                              ),
                              value: "2",
                            },
                            // {
                            //   label: context.t(
                            //     `['receipt/detail/id'].payments.typePOINT`
                            //   ),
                            //   value: "3",
                            // },
                          ]}
                          selected={`${installment.installment_type}`}
                          labelKey="label"
                          valueKey="value"
                          handler={(installment_type) => {
                            // installment_data[index].installment_type =
                            //   parseInt(installment_type);
                            setCheckoutData({
                              ...checkout_data,
                              installment: {
                                ...[
                                  ...Object.keys(checkout_data.installment).map(
                                    (key) => checkout_data.installment[key]
                                  ),
                                ],
                                [index]: {
                                  ...checkout_data.installment[index],
                                  installment_type: parseInt(installment_type),
                                },
                              },
                            });
                          }}
                        />
                      </Column>

                      <Column className="xs-12 sm-12 md-12 lg-2">
                        <MaskedInput
                          label={
                            installment.installment_type === 3
                              ? context.t(
                                  `['receipt/detail/id'].payments.pointInputLabel`
                                )
                              : context.t(
                                  `['receipt/detail/id'].payments.amountInputLabel`
                                ) +
                                (context.state.currency
                                  ? "(" + context.state.currency + ")"
                                  : "₺")
                          }
                          value={
                            installment.installment_amount !== null
                              ? parseFloat(
                                  installment.installment_amount
                                ).toFixed(0)
                              : ""
                          }
                          onChange={(e) => {
                            // installment_data[index].amount =
                            //   e.target.value && /[0-9,.]+/.test(e.target.value)
                            //     ? parseFloat(e.target.value)
                            //     : null;
                            setCheckoutData({
                              ...checkout_data,
                              installment: {
                                ...[
                                  ...Object.keys(checkout_data.installment).map(
                                    (key) => checkout_data.installment[key]
                                  ),
                                ],
                                [index]: {
                                  ...checkout_data.installment[index],
                                  installment_amount:
                                    e.target.value &&
                                    /[0-9,.]+/.test(e.target.value)
                                      ? parseFloat(e.target.value)
                                      : null,
                                },
                              },
                            });
                          }}
                        />
                      </Column>
                    </Grid>
                  );
                })}
            </>
          ) : (
            <>
              {Object.keys(checkout_data.installment).filter(
                (key) => checkout_data.installment[key] !== undefined
              ).length > 0 ? (
                Object.keys(checkout_data.installment).map(
                  (key, index) =>
                    checkout_data.installment[key] !== undefined && (
                      <Grid className="no-gutters-row" key={index}>
                        <Column className="xs-12 sm-12 md-12 mt-1 lg-3">
                          <MuiThemeProvider theme={themeForDatePicker}>
                            <DatePickerInput
                              label={`${context.t(
                                `['receipt/detail/id'].payments.dateInputLabel`
                              )} / ${index + 1}. taksit`}
                              value={
                                new Date(
                                  moment(
                                    checkout_data.installment[key]
                                      .installment_date
                                  )
                                    .local()
                                    .toISOString()
                                )
                              }
                              onChange={(installment_date) => {
                                setCheckoutData({
                                  ...checkout_data,
                                  installment: {
                                    ...checkout_data.installment,
                                    [key]: {
                                      ...checkout_data.installment[key],
                                      installment_date,
                                    },
                                  },
                                });
                              }}
                            />
                          </MuiThemeProvider>
                        </Column>

                        <Column className="xs-12 sm-12 md-12 lg-3">
                          <Select
                            label={"Ödeme Durumu"}
                            items={[
                              {
                                label: "Bekleniyor",
                                value: "0",
                              },
                              {
                                label: "Ödendi",
                                value: "1",
                              },
                              {
                                label: "Ödenmedi",
                                value: "2",
                              },
                            ]}
                            selected={`${checkout_data.installment[key].installment_status}`}
                            labelKey="label"
                            valueKey="value"
                            handler={(installment_status) => {
                              setCheckoutData({
                                ...checkout_data,
                                installment: {
                                  ...checkout_data.installment,
                                  [key]: {
                                    ...checkout_data.installment[key],
                                    installment_status:
                                      parseInt(installment_status),
                                  },
                                },
                              });
                              setSelectedInstallmentStatus(
                                parseInt(installment_status)
                              );
                            }}
                          />
                        </Column>

                        <Column className="xs-12 sm-12 md-12 lg-3">
                          <Select
                            label={context.t(
                              `['receipt/detail/id'].payments.typeInputLabel`
                            )}
                            items={[
                              {
                                label: context.t(
                                  `['receipt/detail/id'].payments.typeCASH`
                                ),
                                value: "0",
                              },
                              {
                                label: context.t(
                                  `['receipt/detail/id'].payments.typeCARD`
                                ),
                                value: "1",
                              },
                              {
                                label: context.t(
                                  `['receipt/detail/id'].payments.typeEFT`
                                ),
                                value: "2",
                              },
                              // {
                              //   label: context.t(
                              //     `['receipt/detail/id'].payments.typePOINT`
                              //   ),
                              //   value: "3",
                              // },
                            ]}
                            selected={`${checkout_data.installment[key].installment_type}`}
                            labelKey="label"
                            valueKey="value"
                            handler={(installment_type) => {
                              setCheckoutData({
                                ...checkout_data,
                                installment: {
                                  ...checkout_data.installment,
                                  [key]: {
                                    ...checkout_data.installment[key],
                                    installment_type:
                                      parseInt(installment_type),
                                  },
                                },
                              });
                            }}
                          />
                        </Column>

                        <Column className="xs-12 sm-12 md-12 lg-3">
                          <MaskedInput
                            label={
                              checkout_data.installment[key]
                                .installment_type === 3
                                ? context.t(
                                    `['receipt/detail/id'].payments.pointInputLabel`
                                  )
                                : context.t(
                                    `['receipt/detail/id'].payments.amountInputLabel`
                                  ) +
                                  (context.state.currency
                                    ? "(" + context.state.currency + ")"
                                    : "₺")
                            }
                            mask="9999999999999"
                            maskChar=""
                            value={
                              checkout_data.installment[key].installment_amount
                                ? parseFloat(
                                    checkout_data.installment[key]
                                      .installment_amount
                                  )
                                : ""
                            }
                            onChange={(e) => {
                              setCheckoutData({
                                ...checkout_data,
                                installment: {
                                  ...checkout_data.installment,
                                  [key]: {
                                    ...checkout_data.installment[key],
                                    installment_amount:
                                      e.target.value &&
                                      /[0-9,.]+/.test(e.target.value)
                                        ? parseFloat(e.target.value)
                                        : null,
                                  },
                                },
                              });
                            }}
                          />
                        </Column>
                      </Grid>
                    )
                )
              ) : (
                <NoDataContainer style={{ height: "90%" }}>
                  <img
                    style={{ height: "80%" }}
                    src={require("../../../../assets/images/manypixels/online_payment_.svg")}
                    alt="Malesef bulunamadı!"
                  />
                  <p>
                    {context.t(`['receipt/detail/id'].payments.noDataText`)}
                  </p>
                </NoDataContainer>
              )}
            </>
          )}
        </ListSection>
      </SectionBox>
    </>
  );
};

// Randevu notu gösterildiği düzenlendiği alan.

export const _AppNotesBuilder = ({
  receipt_sender,
  setAddSmsDialog,
  setMessage,
  customer_note,
}) => {
  const context = useContext(AppContext);
  return (
    <SectionBox style={{ height: "auto" }}>
      <Grid className="no-gutters-row">
        <Column
          className={`sx-12 sm-12 md-12 lg-${
            receipt_sender !== null ? "7" : "12"
          }`}
        >
          <SectionHeading
            style={{
              backgroundColor: "rgba(140,15,180, 0.1)",
              color: "#8c0fb4",
              border: "1px solid #8c0fb4",
              padding: "10px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Comment />
              <h3 style={{ marginLeft: "15px" }}>
                {context.t(`['receipt/detail/id'].notes.customer`)}
              </h3>
            </div>
          </SectionHeading>
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-5">
          {receipt_sender !== null ? (
            <SectionHeading
              onClick={() => {
                setAddSmsDialog(true);
                setMessage(
                  // "Sayın |ad soyad|, |ödenen tutar| ₺ ödemeniz için teşekkür ederiz. Kalan borcunuz |kalan borc| ₺'dir. Sağlıklı günler dileriz.",
                  ` Sayın |ad soyad|, |ödenen tutar| ${
                    context.state.currency ? context.state.currency : "₺"
                  } ödemeniz için teşekkür ederiz. Kalan borcunuz |kalan borc| ${
                    context.state.currency ? context.state.currency : "₺"
                  }'dir. Sağlıklı günler dileriz.`
                );
              }}
              style={{
                cursor: "pointer",
                backgroundColor: "rgba(255,165,0, 0.1)",
                color: "orange",
                border: "1px solid orange",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                whiteSpace: "nowrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Sms />
                <h3 style={{ marginLeft: "15px" }}>
                  {context.t(`['receipt/detail/id'].sms.addSmsButtonTitle`)}
                </h3>
              </div>
            </SectionHeading>
          ) : (
            <></>
          )}
        </Column>
        <Column className="xs-12 sm-auto">
          <Input
            disabled
            multiline
            rows={8}
            // label={context.t(`['receipt/detail/id'].notes.customer`)}
            value={customer_note ?? null}
          />
        </Column>
      </Grid>
    </SectionBox>
  );
};

// Müşteri notlarının gösterildiği düzenlendiği alan.

export const _CusNotesBuilder = ({ receipt_note, setReceiptNote }) => {
  const context = useContext(AppContext);
  return (
    <SectionBox style={{ height: "auto" }}>
      <Grid className="no-gutters-row">
        <Column className="sx-12 sm-12 md-12 lg-12">
          <SectionHeading
            style={{
              backgroundColor: "rgba(140,15,180, 0.1)",
              color: "#8c0fb4",
              border: "1px solid #8c0fb4",
              padding: "10px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Receipt />
              <h3 style={{ marginLeft: "15px" }}>
                {context.t(`['receipt/detail/id'].notes.receipt`)}
              </h3>
            </div>
          </SectionHeading>
        </Column>
        <Column className="xs-12 sm-auto">
          <Input
            // label={context.t(`['receipt/detail/id'].notes.receipt`)}
            multiline
            inputProps={{ maxLength: 255 }}
            rows={7}
            value={receipt_note ?? null}
            onChange={(e) => {
              setReceiptNote(e.target.value);
            }}
            helperText={`Karakter limitiniz ${
              255 - parseInt(receipt_note.length)
            }`}
          />
        </Column>
      </Grid>

      {/* {appointment_note && (
        <Column className="xs-12 sm-auto">
          <Input
            disabled
            multiline
            rows={3}
            label={context.t(`['receipt/detail/id'].notes.appointment`)}
            value={appointment_note ?? null}
          />
        </Column>
      )} */}
    </SectionBox>
  );
};

// Otomatik Randevu oluşturmak için açılan dialog.

export const _AutoAppointmentDialogBuilder = ({
  checkout_data_dialog,
  setCheckoutDataDialog,
  availableAppointmentExpanded,
  setAvailableAppointmentExpanded,
  selected_date,
  selected_staff,
  staffs,
  selected_service,
  services,
  selected_customer,
  send_sms,
  setSendSms,
  selected_description,
  setNote,
  getStaffUnavailableHours,
  setSelectedTime,
  hoursData,
  selected_time,
  setAdjSelectedDate,
  insertAppointment,
}) => {
  const context = useContext(AppContext);
  return (
    <FullScreenDialog
      className="appointment-add-dialog"
      maxWidth="md"
      fullWidth
      receiptOk={true}
      open={checkout_data_dialog}
      onClose={() => {
        setCheckoutDataDialog(false);
        setAvailableAppointmentExpanded(false);
      }}
      title={context.t(`appointments.create.title`)}
      // buttons={[
      //   {
      //     title: context.t(`appointments.create.completeButtonTitle`),
      //     icon: "check",
      //     backgroundColor: "primary-opacity",
      //     textColor: "primary",
      //     disabledHandleClose: true,
      //     onClick: () => {
      //       setTimeout(() => {
      //         insertAppointment();
      //       }, 1000);
      //     },
      //   },
      //   {
      //     title: context.t(`appointments.create.discardButtonTitle`),
      //     icon: "close",
      //     textColor: "grey",
      //     onClick: () => {
      //       this.setState({ availableAppointmentExpanded: false });
      //     },
      //   },
      // ]}
    >
      <Grid style={{ marginTop: "20px" }}>
        <Column className="xs-6 sm-6">
          <Column className="xs-12 sm-12 md-auto">
            <div>
              <h5
                style={{
                  background: "#eff6fe",
                  color: "#4293ed",
                  padding: "15px",
                  fontSize: "20px",
                }}
              >
                {moment(selected_date).format("DD MMMM dddd YYYY")}
              </h5>
            </div>
          </Column>
          <Column className="xs-12 sm-12 md-auto">
            <Input
              className="mb-1"
              readOnly
              label={context.t(`appointments.create.selectedStaffInputLabel`)}
              value={
                selected_staff
                  ? staffs.filter((staff) => staff.id === selected_staff).length
                    ? staffs.filter((staff) => staff.id === selected_staff)[0]
                        .full_name
                    : "Silinmiş Hizmet"
                  : "Silinmiş Hizmet"
              }
            />
          </Column>
          <Column className="xs-12 sm-12 md-auto">
            <Input
              className="mb-1"
              readOnly
              label={context.t(`appointments.create.selectedServiceInputLabel`)}
              value={
                selected_service
                  ? services.filter(
                      (service) => service.id === selected_service
                    ).length
                    ? services.filter(
                        (service) => service.id === selected_service
                      )[0].name
                    : "Silinmiş Hizmet"
                  : "Silinmiş Hizmet"
              }
            />
          </Column>
          <Column className="xs-12 sm-12 md-auto">
            <Input
              className="mb-1"
              readOnly
              label={context.t(
                `appointments.create.selectedCustomerInputLabel`
              )}
              value={selected_customer}
            />
          </Column>
        </Column>
        <Column className="xs-6 sm-6">
          <Column className="xs-12 sm-12 md-auto">
            <CSwitch
              label={context.t(`appointments.create.sendSmsInputLabel`)}
              checked={send_sms}
              color="primary"
              disabled={
                selected_customer && selected_customer.send_sms === false
              }
              onChange={(checked) => {
                setSendSms(checked.target.checked);
              }}
            />
          </Column>
          <Column className="xs-12 sm-12 md-auto">
            <Input
              className="mt-2 mb-1"
              readOnly
              label={context.t(`appointments.create.description`)}
              value={selected_description}
            />
          </Column>
          <Column className="xs-12 sm-12 md-auto">
            <Input
              label={context.t(`appointments.create.noteInputLabel`)}
              multiline
              rows={1}
              onChange={(e) => setNote(e.target.value)}
            />
          </Column>
          <Column className="xs-12 sm-12 md-auto">
            <Button
              icon="event"
              backgroundColor="primary"
              fullWidth
              textColor="white"
              title={context.t(
                `['appointments/add'].availableHoursButtonTitle`
              )}
              onClick={() => {
                getStaffUnavailableHours();
                setSelectedTime(null);
              }}
            />
          </Column>
        </Column>
      </Grid>

      {/* Time Select */}
      {availableAppointmentExpanded === true ? (
        <>
          <Grid className="p-2" style={{ width: "fit-content" }}>
            {BetweenTimeGenerator(context.state.company_shift).map(
              (item, index) => {
                const time = item.split(":");
                const x = new Date(selected_date).setHours(
                  time[0],
                  time[1],
                  0,
                  0
                );
                return (
                  <div
                    key={index}
                    className="col xs-1 sm-1 md-1 lg-1 xl-1 mb-1 mx-3"
                    style={{
                      cursor: `${
                        hoursData.day_closed ||
                        hoursData.closed_hours.includes(item)
                          ? hoursData.trans_hours.includes(item)
                            ? "not-allowed"
                            : "pointer"
                          : "pointer"
                      }`,
                      margin: "5px 3px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "30px",
                      maxWidth: "120px",
                      borderRadius: "20px",
                      border: `${
                        hoursData.day_closed ||
                        hoursData.closed_hours.includes(item)
                          ? hoursData.trans_hours.includes(item)
                            ? "1px solid gray"
                            : "1px solid purple"
                          : hoursData.occupied_hours.includes(item)
                          ? hoursData.appointment_hours.includes(item)
                            ? "1px solid red"
                            : "1px solid purple"
                          : "1px solid green"
                      }`,
                      backgroundColor: `${
                        (hoursData.day_closed ||
                          hoursData.closed_hours.includes(item)) &&
                        selected_time === item
                          ? hoursData.trans_hours.includes(item)
                            ? "gray"
                            : "purple"
                          : hoursData.occupied_hours.includes(item) &&
                            selected_time === item
                          ? hoursData.appointment_hours.includes(item)
                            ? "red"
                            : "purple"
                          : selected_time === item
                          ? "green"
                          : "white"
                      }`,
                      color: `${
                        selected_time === item
                          ? "white"
                          : hoursData.day_closed ||
                            hoursData.closed_hours.includes(item)
                          ? hoursData.trans_hours.includes(item)
                            ? "gray"
                            : "purple"
                          : hoursData.occupied_hours.includes(item)
                          ? hoursData.appointment_hours.includes(item)
                            ? "red"
                            : "purple"
                          : "green"
                      }`,
                    }}
                    onClick={() => {
                      if (item.split(":")[0] < 6) {
                        setAdjSelectedDate(moment(selected_date).add(1, "day"));
                      } else {
                        setAdjSelectedDate(selected_date);
                      }
                      if (
                        !(
                          hoursData.day_closed ||
                          (hoursData.closed_hours.includes(item) &&
                            hoursData.trans_hours.includes(item))
                        )
                      ) {
                        setSelectedTime(item);
                      }
                    }}
                  >
                    {item}
                  </div>
                );
              }
            )}
          </Grid>
          <Grid>
            <MuiThemeProvider theme={theme}>
              <Tooltip
                style={{ backgroundColor: "white", marginLeft: "30px" }}
                title={
                  <Column
                    style={{ backgroundColor: "white" }}
                    className="xs-12 sm-12 p-2"
                  >
                    <Column className="xs-12 sm-12">
                      <ColorMean>
                        <div className="color" style={{ background: "green" }}>
                          <div className="overlay" />
                        </div>
                        <span>
                          Randevu alımına açık boş saatler yeşil renk ile
                          gösterilir.
                        </span>
                      </ColorMean>
                    </Column>
                    <Column className="xs-12 sm-12 mt-2">
                      <ColorMean>
                        <div className="color" style={{ background: "red" }}>
                          <div className="overlay" />
                        </div>
                        <span>
                          Daha önce randevu oluşturulmuş ya da çalışan
                          detayından çalışan saatleri kapatılmış saatler kırmızı
                          renk ile gösterilir.
                        </span>
                      </ColorMean>
                    </Column>
                    <Column className="xs-12 sm-12 mt-2">
                      <ColorMean>
                        <div className="color" style={{ background: "orange" }}>
                          <div className="overlay" />
                        </div>
                        <span>
                          Yetkili tarafından saat kapandığında turuncu renk ile
                          gösterilir
                        </span>
                      </ColorMean>
                    </Column>
                  </Column>
                }
                placement="right"
              >
                <IconButton>
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </MuiThemeProvider>
          </Grid>

          <Grid>
            {selected_time && (
              <Button
                style={{ margin: "-20px auto 15px" }}
                icon="check"
                title={context.t(
                  `['appointments/add'].confirmButtonHoursTitle`
                )}
                backgroundColor="primary"
                textColor="white"
                onClick={() => {
                  setAvailableAppointmentExpanded(false);
                  setTimeout(() => {
                    insertAppointment();
                  }, 500);
                }}
              />
            )}
          </Grid>
        </>
      ) : (
        <></>
      )}
    </FullScreenDialog>
  );
};

/**
 * Borç Bilgilendirme / Tahsilat mesajı Göndermek için açılan dialog.
 */
export const _DebtInformationDialogBuilder = ({
  message,
  setMessage,
  add_sms_dialog,
  setAddSmsDialog,
  setAlertMessageDialog,
}) => {
  const context = useContext(AppContext);
  return (
    <AlertDialog
      fullWidth
      maxWidth="sm"
      title={context.t(`['receipt/detail/id'].sms.smsButtonTitle`)}
      open={add_sms_dialog}
      closeHandler={() => setAddSmsDialog(false)}
      buttons={[
        {
          title: context.t(`['receipt/detail/id'].sms.sendButtonTitle`),
          icon: "check",
          backgroundColor: "primary-opacity",
          textColor: "primary",
          onClick: () => {
            setMessage(message);
            setAlertMessageDialog(true);
          },
        },
        {
          title: context.t(`['receipt/detail/id'].sms.discardButtonTitle`),
          icon: "close",
          textColor: "grey",
          onClick: () => {
            setMessage("");
          },
        },
      ]}
    >
      <Column className="xs-12 sm-12 md-12 lg-8">
        <Input
          label={context.t(`sms.send.messageInputLabel`)}
          multiline={true}
          rows={10}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          helperText={CalculateMessageCharCount(message, "1")}
        />
        <Button
          title={context.t(`sms.send.autoText.nameSurname`)}
          size="xs"
          textColor="primary"
          outlined
          onClick={() => {
            setMessage(`${message}|ad soyad|`);
          }}
        />
        <Button
          className="ml-1"
          title={context.t(`sms.send.autoText.amountPaid`)}
          size="xs"
          textColor="primary"
          outlined
          onClick={() => {
            setMessage(`${message}|ödenen tutar|`);
          }}
        />
        <Button
          className="ml-1"
          title={context.t(`sms.send.autoText.remainingDebt`)}
          size="xs"
          textColor="primary"
          outlined
          onClick={() => {
            setMessage(`${message}|kalan borc|`);
          }}
        />
        <Button
          className="ml-1"
          title={context.t(`sms.send.autoText.businessUrl`)}
          size="xs"
          textColor="primary"
          outlined
          onClick={() => {
            setMessage(`${message}|işletme url|`);
          }}
        />
      </Column>
    </AlertDialog>
  );
};

/**
 * İndirim uygulamak için açılan dialog.
 */
export const _DiscountDialogBuilder = ({
  discount_modal,
  setDiscountModal,
  applyDiscount,
  discount_type,
  setDiscountType,
  discount_value,
  setDiscountValue,
}) => {
  const context = useContext(AppContext);
  return (
    <AlertDialog
      fullWidth
      maxWidth="sm"
      title={context.t(`['receipt/detail/id'].applyDiscountButtonTitle`)}
      open={discount_modal}
      closeHandler={() => setDiscountModal(false)}
      buttons={[
        {
          title: context.t(`['receipt/detail/id'].applyDiscountButtonTitle`),
          icon: "check",
          backgroundColor: "primary-opacity",
          textColor: "primary",
          onClick: () =>
            applyDiscount({
              type: discount_type,
              value: discount_value,
            }),
        },
        {
          title: context.t(
            `['receipt/detail/id'].applyDiscount.discardButtonTitle`
          ),
          icon: "close",
          textColor: "grey",
        },
      ]}
    >
      <Select
        label={context.t(`['receipt/detail/id'].applyDiscount.typeInputLabel`)}
        items={[
          {
            label:
              (context.state.currency
                ? context.state.currency === "₺"
                  ? "TL"
                  : context.state.currency
                : "TL") +
              context.t(`['receipt/detail/id'].applyDiscount.typeCASH`),
            value: "TL",
          },
          {
            label: context.t(`['receipt/detail/id'].applyDiscount.typePERCENT`),
            value: "%",
          },
        ]}
        labelKey="label"
        valueKey="value"
        selected={discount_type}
        handler={(discountType) => {
          setDiscountType(discountType);
        }}
      />

      <Input
        label={`${context.t(
          `['receipt/detail/id'].applyDiscount.amountInputLabel`
        )} (${
          context.state.currency ? context.state.currency : discount_type
        })`}
        type="number"
        value={discount_value}
        onChange={(e) => {
          setDiscountValue(
            /[0-9,.]+/.test(e.target.value) ? Math.abs(e.target.value) : 0
          );
        }}
      />
    </AlertDialog>
  );
};

/**
 * Adisyonu silmek için açılan onay dialog'u.
 */
export const _ReceiptDeleteDialogBuilder = ({
  delete_confirm,
  setDeleteConfirm,
  delete_id,
  deleteSingleReceipt,
}) => {
  const context = useContext(AppContext);
  return (
    <AlertDialog
      open={delete_confirm}
      closeHandler={() => setDeleteConfirm(false)}
      title={context.t(`['receipt/detail/id'].delete.title`)}
      buttons={[
        {
          title: context.t(`['receipt/detail/id'].delete.confirmButtonTitle`),
          icon: "check",
          backgroundColor: "primary-opacity",
          textColor: "primary",
          timer: 5,
          onClick: () => deleteSingleReceipt(delete_id),
        },
        {
          title: context.t(`['receipt/detail/id'].delete.discardButtonTitle`),
          icon: "close",
          textColor: "grey",
        },
      ]}
    >
      <Alert severity="warning">
        {context.t(`['receipt/detail/id'].delete.alertText`)}
      </Alert>
    </AlertDialog>
  );
};

const SectionBox = styled.div`
  width: 100%;
  overflow-x: scroll;
  box-sizing: border-box;
  padding-top: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  color: var(--dark-primary);

  h3{font - size: 18px; font-weight: 600; flex: 1; margin: 0; padding: 0}
`;

const ListSection = styled.div`
  overflow-x: scroll;
  margin-bottom: auto;
  padding-left: 5px;
  padding-right: 5px;
`;

const PaymentSection = styled.div`
  width: 95%;
  height: 57px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
`;

const SubTotalText = styled.div`
  span {
    font-size: 13px;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
`;

const CustomerBoxDiv = styled.div`
  width: 100%;
  height: 57px;
  padding: 8px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 5px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    margin-top: 7px;
    margin-left: 10px;
  }
`;

const CustomerPointBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-top: 5px;

  h3 {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    color: #3c3c3c;
  }

  span {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;

    b {
      font-size: 13px;
      font-weight: 400;
      color: #aaa;
    }
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 32vh;
    object-fit: contain;
  }
  p {
    color: #407bff;
    font-size: 18px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;

const AdvancedType = styled.div`
  display: flex;
  width: 190px;
  height: 88px;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  cursor: pointer;
`;

const ColorMean = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  justify-content: center;

  &:nth-last-child(1) {
    margin: 0;
  }

  div.color {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    margin-right: 8px;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        -180deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  span {
    flex: 1;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    color: #000000;
  }
`;

const ReceiptProfile = styled.div`
  display: flex;
  flex-direction: column;
  height: 57px;
  width: 100%;
  border-radius: 5px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    padding: 2px;
  }
`;
