import React, { useState, useContext, useEffect, useRef } from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Axios from "axios";
import moment from "moment";
import AuthContainer from "../../../components/AuthContainer";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import Input from "../../../theme/CustomMUI/Input";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import ConvertTimeToZulu from "../../../functions/ConvertTimeToZulu";
import Loader from "../../../assets/images/loader.gif";
import { toast } from "react-toastify";
import Select from "../../../theme/CustomMUI/Select";

const LogsIndex = () => {
  const context = useContext(AppContext);

  const [typingTimeout, setTypingTimeout] = useState(1000);
  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => searchSystemLogs(page)
  });
  const [data, setData] = useState([]);

  // search
  const [filterExpanded, setFilterExpanded] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [search, setSearch] = useState({
    between: {
      end: ConvertTimeToZulu(moment().unix()),
      start: ConvertTimeToZulu(moment().subtract(30, "days").unix()),
    },
    offset: 30,
    is_between: false,
    text: "",
    // user_id: null, // nullable
  });

  // customer filter autocomplete
  const [specificCustomer, setSpecificCustomer] = useState({
    data: [],
    pagination: {
      total_page: 0,
      page: 0,
    },
  });
  const [categoryList, setCategoryList] = useState([
    {
      id: 1,
      name: "Tüm Kayıtlar",
      type: null,
    },
    {
      id: 2,
      name: "Müşteri Kayıtları",
      type: 1,
    },
    {
      id: 3,
      name: "Adisyon Kayıtları",
      type: 2,
    },
    {
      id: 4,
      name: "Çalışan Kayıtları",
      type: 3,
    },
    {
      id: 5,
      name: "Randevu Kayıtları",
      type: 4,
    },
    {
      id: 6,
      name: "SMS Ayarları Kayıtları",
      type: 5,
    },
  ]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const searchSystemLogs = async (page = 1) => {
    /*
      Between    struct {
        End   time.Time `json:"end"` //zulu
        Start time.Time `json:"start"` //zulu
      } `json:"between"`
      Offset    uint8          `json:"offset"`
      UserID    *uint          `json:"user_id"` // Null gönderilebilir. Spesifik olarak bi kullanıcı üzerinde arama yapılması gerekirse bu alanda gereken değer gönderilmeli.
      Text      string         `json:"text"`
    */

    setLoaded(false);
    let end_of = `${moment(search.between.end).format(
      "YYYY-MM-DD"
    )}T23:59:59.000Z`;
    Axios.post(`${context.api_endpoint}/company/logs?page=${page}`, {
      type: context.state?.log_type,
      vid: context.state?.log_id,
      ...(filterExpanded && {
        between: {
          end: end_of,
          start: search.between.start,
        },
        offset: search.offset,
        is_between: search.is_between,
        text: search.text,
      }),
    })

      .then((response) => {
        const _data = response.data.data;

        // console.log(_data);

        if (response.status === 200) {
          setLoaded(true);
          setData(
            _data.records.map((item) => {
              let message_detail = item.message
                ?.split("\n")
                .filter((_, index) => index !== 0)
                .map((item) => item)
                .join("\n");

              return {
                ...item,
                created_at: moment(item.created_at).format("LLL"),
                message: item.message?.split("\n")[0],
                message_detail,
              };
            })
          );
          setPagination({
            ...pagination,
            page: _data.page,
            total_page: _data.records.length,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
          setLoaded(true);
        }
      });
  };

  const updateSearchState = async (additional) => {
    return (
      ((setSearch({
        ...search,
        ...additional,
      })) &&
        true) ||
      false
    );
  };

  const searchCustomerByKey = async (page = 1, key) => {
    await Axios.get(
      `${context.api_endpoint}/company/customers?page=${page}`,
      {
        params: { key },
      }
    ).then(({ data }) => {
      setSpecificCustomer({
        data: data.data.records,
        pagination: {
          ...specificCustomer.pagination,
          total_page: data.data.records.length,
          page: data.data.page,
        },
      });
    });
  };

  useEffect(() => {
    // console.log("Token::");
    // console.log(Axios.defaults.headers.Authorization);
    searchSystemLogs();
    // getCustomers();
  }, []);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const prevState = usePrevious(selectedCategory);

  useEffect(() => {
    // Önceki durum ile mevcut durumu karşılaştırın
    if (selectedCategory !== prevState) {
      // customer_state değiştiğinde myFunc fonksiyonunu çağırın
      searchSystemLogs();
    }
  }, [selectedCategory]);

    return (
      <AuthContainer authorities={[1]} limited_permission="system">
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-auto">
            <FormControlLabel
              label={
                <FCLabel>
                  <i className="material-icons">filter_list</i>{" "}
                  <span>{context.t(`[logs/index].filter.title`)}</span>
                </FCLabel>
              }
              control={
                <Switch
                  color="primary"
                  checked={filterExpanded}
                  onChange={async (e) => {
                    setFilterExpanded(e.target.checked);
                    await searchSystemLogs();
                  }}
                />
              }
            />

            {filterExpanded && (
              <Grid className="pb-4">
                <Column className="xs-12 sm-12 md-12 lg-4">
                  <Input
                    className="mb-0"
                    label={context.t(
                      `[logs/index].filter.form.description`
                    )}
                    onChange={async (e) => {
                      const { value } = e.target;

                      // console.log('işlem açıklaması: ', value);

                      if (typingTimeout)
                        clearTimeout(typingTimeout);
                      value
                        ? await updateSearchState({
                            text: value.trim(),
                          })
                        : await updateSearchState({
                            text: "",
                          });
                      setTypingTimeout(
                        setTimeout(async () => {
                          await searchSystemLogs();
                        }, 500),
                      );
                      // await setLoadad(false);
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <Select
                    label="Kayıt Kategorileri"
                    items={categoryList}
                    labelKey="name"
                    valueKey="id"
                    selected={
                      selectedCategory
                        ? selectedCategory.id
                        : null
                    }
                    returnFullObject
                    handler={async (filterPicker) => {
                      setSelectedCategory(filterPicker);
                      context.dispatch({
                        type: "LOG_TYPE",
                        payload: { log_type: filterPicker.type },
                      });
                      context.dispatch({
                        type: "LOG_ID",
                        payload: {
                          log_id: null,
                        },
                      });
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DatePickerInput
                    label={context.t(
                      `['logs/index'].filter.form.startDate`
                    )}
                    disabled={!search.is_between}
                    value={
                      new Date(
                        moment(search.between.start).toISOString()
                      )
                    }
                    onChange={async (start_date) => {
                      await updateSearchState({
                        between: {
                          start: ConvertTimeToZulu(moment(start_date).unix()),
                          end: search.between.end,
                        },
                        // offset: OffsetFinder(start_date, search.between.end)
                      });
                      await searchSystemLogs();
                    }}
                  />
                </Column>

                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DatePickerInput
                    label={context.t(`['logs/index'].filter.form.endDate`)}
                    disabled={!search.is_between}
                    value={
                      new Date(
                        moment(search.between.end).toISOString()
                      )
                    }
                    onChange={async (end_date) => {
                      await updateSearchState({
                        between: {
                          start: search.between.start,
                          end: ConvertTimeToZulu(moment(end_date).unix()),
                        },
                        // offset: OffsetFinder(end_date, search.between.start)
                      });
                      await searchSystemLogs();
                      // await getFilterByAppointmentsStatistics();
                    }}
                  />
                </Column>

                <Column className="xs-12 sm-12 md-12 lg-2">
                  <FormControlLabel
                    className="mt-1"
                    label={
                      <span>
                        {context.t(`[logs/index].filter.form.allDate`)}
                      </span>
                    }
                    control={
                      <Switch
                        color="primary"
                        checked={!search.is_between}
                        onChange={async (e) => {
                          await updateSearchState({
                            is_between: !e.target.checked,
                          });
                          await searchSystemLogs();
                        }}
                      />
                    }
                  />
                </Column>
              </Grid>
            )}
          </Column>
        </Grid>

        <Grid>
          {!loaded ? (
            <div
              style={{
                textAlign: "center",
                marginTop: "5vh",
                width: "100%",
              }}
            >
              <img src={Loader} height={70} />
            </div>
          ) : (
            <Column className="xs-12 sm-12">
              <Table
                loaded={loaded}
                skeletonCount={10}
                headings={{
                  created_at: {
                    label: context.t(
                      `['settings/logs'].headings.createdAt`
                    ),
                    style: { width: 200 },
                  },
                  message: {
                    label: context.t(`['settings/logs'].headings.message`),
                    style: { width: 380 },
                  },
                  message_detail: {
                    label: context.t(`['settings/logs'].headings.detail`),
                    style: { whiteSpace: "pre-wrap" },
                  },
                  ip: {
                    label: context.t(`['settings/logs'].headings.ip`),
                  },
                }}
                pagination={pagination}
                rows={data}
              />
            </Column>
          )}
        </Grid>
      </AuthContainer>
    );
  
}

export default LogsIndex;
