import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";

import AuthContainer from "../../../components/AuthContainer";
import AppContext from "../../../context/store";

import FormDataGenerator from "../../../functions/FormDataGenerator";
import { Column, Grid } from "../../../theme/Grid";
import "../../../assets/style/views/Gallery.scss";
import { toast } from "react-toastify";
import Axios from "axios";
import CompressImage from "../../../functions/CompressImage";
import { Checkbox, CircularProgress } from "@material-ui/core";
import Button from "../../../theme/Button";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const GalleryIndex = () => {
  const context = useContext(AppContext);
  const imageRef = useRef();

  const [loaded, setLoaded] = useState(false); // Skeleton gösterim değeri
  const [photos, setPhotos] = useState([]); // İstekten gelen fotoğraflar

  const [accountings, setAccountings] = useState({
    1: 50, // premium photo limitation
    2: 20, // standart limitation
    3: 4, // free limitation
  });
  const [cropModal, setCropModal] = useState(false);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 70,
    height: 45,
  });

  const [deleteModal, setDeleteModal] = useState(false); // Silme işlemi onay kutusu
  const [selectedPhotos, setSelectedPhotos] = useState([]); // Silme işlemi için seçilen fotoğraflar

  const [openViewDialog, setOpenViewDialog] = useState(false);

  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const [business, setBusiness] = useState([]);

  const [fileUrl, setFileUrl] = useState();


  /**
   * Veri kaynağı üzerindne fotoğrafları getiren fonksiyon.
   */
  const getPhotos = () => {
    Axios.get(`${context.api_endpoint}/company/service/gallery/list`)
      .then((response) => {
        const { data } = response;
        if (response.status === 201) {
          setLoaded(true);
          setPhotos([...data.data]);
          setSelectedPhotos([]);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };
  const uploadStorePhoto = async (e) => {
    toast.success(context.t(`['platform/photo'].photoUploadToast`));

    Axios.post(
      `${context.api_endpoint}/company/service/gallery/insert`,
      FormDataGenerator({
        files: await CompressImage(e, {
          maxSizeMB: 2,
          fileType: "image/jpg",
        }),
      }),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`['platform/photo'].insertToast`));
          getPhotos();
          setCropModal(false);
          setSrc(null);
          setCrop({
            unit: "%",
            x: 0,
            y: 0,
            width: 70,
            height: 45,
          })
          setCroppedImageUrl(undefined);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
          setCropModal(false);
          setSrc(null);
          setCrop({
            unit: "%",
            x: 0,
            y: 0,
            width: 70,
            height: 45,
          })
          setCroppedImageUrl(undefined);
        } else {
          toast.error(
            context.t(`['platform/photo'].photoUploadErrorToast`)
          );
          setCropModal(false);
          setSrc(null);
          setCrop({
            unit: "%",
            x: 0,
            y: 0,
            width: 70,
            height: 45,
          })
          setCroppedImageUrl(undefined);
        }
      });
  }

  // Fotoğraf crop fonksiyonları
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      e.persist();

      let currentAccounting = context.state.company_license.accounting;
      const appointment_limitation =
        context.state.company_license.appointment_limitation;

      if (currentAccounting === 2 && appointment_limitation > 0) {
        currentAccounting = 3;
      }

      if (
        accountings[currentAccounting] <
        [...e.target.files].length + photos.length
      ) {
        toast.error("Mevcut paket için fotoğraf limitini aştınız.");
      } else {
        const reader = new FileReader();
        reader.addEventListener("load", () => setSrc(reader.result));
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    imageRef = image.target;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const CroppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        "newFile.jpeg"
      );
      setCroppedImageUrl(CroppedImageUrl);
    }
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }

        blob.name = fileName;
        window.URL.revokeObjectURL(fileUrl);
        setFileUrl(window.URL.createObjectURL(blob));
        resolve(blob);
      }, "image/jpeg");
    });
  }
  /**
   * Inputta seçimi yapılan fotoğrafları yükleyen fonksiyon.
   */
  const uploadMultiPhotos = async (e) => {
    e.persist();

    let currentAccounting = context.state.company_license.accounting;
    const appointment_limitation =
      context.state.company_license.appointment_limitation;

    if (currentAccounting === 2 && appointment_limitation > 0) {
      currentAccounting = 3;
    }

    if (
      accountings[currentAccounting] <
      [...e.target.files].length + photos.length
    ) {
      toast.error("Mevcut paket için fotoğraf limitini aştınız.");
    } else {
      const length = [...e.target.files].length;
      const Form = new FormData();

      setLoaded(false);

      for (let i = 0; i < length; i++) {
        Form.append(
          "files",
          await CompressImage(e.target.files[i], {
            maxSizeMB: 2,
            fileType: "image/jpg",
          })
        );
      }

      Axios.post(
        `${context.api_endpoint}/company/service/gallery/insert`,
        Form,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
        .then((response) => {
          if (response.status === 201) {
            toast.success(
              context.t(`['settings/service-gallery'].insertToast`)
            );
            getPhotos();
          }
        })
        .catch((e) => {
          setLoaded(true);
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    }

    if (
      currentAccounting === 2 &&
      business.license.appointment_limitation === 0
    ) {
      toast.error("Fotoğraf yüklemesi yapamazsınız.");
    } else {
    }
  };

  /**
   * Multi seçimin sonucunda silme işlemini yapan fonksiyondur.
   */
  const deleteMultiPhotos = () => {
    Axios.post(`${context.api_endpoint}/company/service/gallery/delete`, {
      photo_id: [...selectedPhotos],
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(
            context.t(`['settings/service-gallery'].deleteToast`)
          );
          getPhotos();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    getPhotos();
  }, []);


  const _GalleryBuilder = () => {
    return (
      <>
        {photos &&
          photos.map((item, index) => (
            <Column key={index} className="xs-12 sm-12 md-6 lg-4 xl-3 mb-4">
              <PhotoContainer
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedPhotos(
                    selectedPhotos.includes(
                      item.id
                    )
                      ? selectedPhotos.filter(
                        (id) => id !== item.id
                      )
                      : [...selectedPhotos, item.id]
                  );
                }}
              >
                <GalleryPhoto
                  src={`${context.api_endpoint.replace("api/v1", "")}${context.state.company_id
                    }/${item.path}`}
                />
                <PhotoProcesses>
                  <Checkbox
                    color="primary"
                    checked={selectedPhotos.includes(item.id)}
                  />
                </PhotoProcesses>
              </PhotoContainer>
            </Column>
          ))}
      </>
    );
  };

  const _LoaderBuilder = () => (
    <LoadingContainer>
      <CircularProgress variant="indeterminate" color="primary" />
    </LoadingContainer>
  );

  const GalleryPhotos = _GalleryBuilder;
  const LoaderContainer = _LoaderBuilder;

  return (
    <AuthContainer authorities={[1]} limited_permission="gallery">
      <Grid>
        <Column className="xs-12 sm-12 mb-12 mb-3">
          <Alert severity="warning" className="mt-2">
            <b>
              {context.t(
                `['settings/service-gallery'].service_photo_info`
              )}{" "}
              <Info onClick={() => setOpenViewDialog(true)}>
                {context.t(`['settings/service-gallery'].anexample`)}{" "}
              </Info>
            </b>
          </Alert>
          {openViewDialog && (
            <AlertDialog
              title={""}
              open={openViewDialog}
              fullWidth={true}
              maxWidth="lg"
              textType
              disableBackdropClick={false}
              closeHandler={() => setOpenViewDialog(false)}
              buttons={[
                {
                  //icon: "okay",
                  title: "Kapat",
                  textColor: "white",
                  backgroundColor: "primary",
                },
              ]}
            >
              <InfoPhoto
                src={require("../../../assets/images/service_gallery.png")}
              />
            </AlertDialog>
          )}
        </Column>
        <Column className="xs-12 sm-12 mb-3">
          <input
            id="imageUploadInput"
            type="file"
            multiple
            accept="image/*"
            style={{ display: "none" }}
            onClick={() => setCropModal(true)}
            onChange={(e) => {
              if (e.target.files.length > 1) {
                uploadMultiPhotos(e);
              } else if (e.target.files.length === 1) {
                onSelectFile(e);
              }
            }}
          />
          {cropModal && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "40%" }}>
                {src && (
                  <ReactCrop
                    className="ReactCrop__image"
                    aspect={16 / 9}
                    crop={crop}
                    ruleOfThirds
                    onComplete={onCropComplete}
                    onChange={onCropChange}
                  >
                    <img src={src} onLoad={onImageLoaded} />
                  </ReactCrop>
                )}
                {croppedImageUrl && (
                  <cimg
                    alt="Crop"
                    style={{ padding: "25px", maxWidth: "100%" }}
                    src={croppedImageUrl}
                  />
                )}
                {croppedImageUrl !== undefined &&
                  src !== null && (
                    <Button
                      className="mt-1"
                      icon="add"
                      title={"Yükle"}
                      textColor="white"
                      backgroundColor="blue"
                      fullWidth
                      onClick={() =>
                        uploadStorePhoto(croppedImageUrl)
                      }
                    />
                  )}
              </div>
            </div>
          )}
          {!src && (
            <ImageUploadBox htmlFor="imageUploadInput">
              <i className="material-icons">cloud_upload</i>{" "}
              {context.t(`['settings/gallery'].uploadButtonTitle`)}
            </ImageUploadBox>
          )}
        </Column>

        <Column className="xs-12 sm-12 mb-2">
          <Button
            className="mr-2"
            icon="check"
            title={context.t(
              `['settings/service-gallery'].checkAllButtonTitle`
            )}
            textColor="primary"
            backgroundColor="primary-opacity"
            transitionEffect
            onClick={() => setSelectedPhotos([...photos.map(item => item.id)])}
          />
          <Button
            icon="delete"
            title={context.t(
              `['settings/service-gallery'].deleteSelectedButtonTitle`,
              {
                count: selectedPhotos.length,
              }
            )}
            disabled={!selectedPhotos.length > 0}
            textColor="red"
            backgroundColor="red-opacity"
            transitionEffect
            onClick={() => setDeleteModal(true)}
          />

          {context.state.company_license.accounting && (
            <p style={{ display: "inline-block", marginLeft: "10px" }}>
              {context.t(`[settings/service-gallery].numberPhoto`)}:{" "}
              {photos ? photos.length : 0} /{" "}
              {
                accountings[
                context.state.company_license.accounting === 2 &&
                  context.state.company_license.appointment_limitation >
                  0
                  ? 3
                  : context.state.company_license.accounting
                ]
              }
            </p>
          )}
        </Column>

        {loaded ? <GalleryPhotos /> : <LoaderContainer />}

        <AlertDialog
          title={context.t(`['settings/service-gallery'].delete.title`)}
          maxWidth="sm"
          fullWidth
          open={deleteModal}
          closeHandler={() => setDeleteModal(false)}
          buttons={[
            {
              title: context.t(
                `['settings/service-gallery'].delete.confirmButtonTitle`
              ),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => deleteMultiPhotos(),
            },
            {
              title: context.t(
                `['settings/service-gallery'].delete.discardButtonTitle`
              ),
              icon: "close",
              textColor: "grey",
            },
          ]}
          textType
        >
          <b>
            {context.t(
              `['settings/service-gallery'].delete.alertBoldText`
            )}
          </b>
          <br />
          {context.t(`['settings/service-gallery'].delete.alertText`)}
        </AlertDialog>
      </Grid>
    </AuthContainer >
  );


}

export default GalleryIndex;

const LoadingContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PhotoContainer = styled.div`
  position: relative;
  height: 240px;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.04);
`;

const PhotoProcesses = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px;
  background: rgba(255, 255, 255, 1);
  z-index: 200;

  span.MuiCheckbox-root {
    padding: 0 !important;
  }
`;

const GalleryPhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageUploadBox = styled.label`
  display: flex;
  width: -webkit-fill-available;
  padding: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #fefefe;
  border: 2px dotted #eaeaea;
  border-radius: 5px;
  cursor: pointer;
  color: #808080;
  font-size: 15px;
  font-weight: 500;
  transition: all ease 0.35s;

  &:hover {
    border: 2px dotted #dadada;
    color: #000;
  }

  i.material-icons {
    font-size: 21px;
    margin-right: 8px;
  }
`;

const Info = styled.span`
  color: blue;
  cursor: pointer;
  margin-top: 15px;
`;

const InfoPhoto = styled.img`
  width: 100%;
`;
