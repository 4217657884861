import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import { Grid, Column } from "../../../theme/Grid";
import Axios from "axios";
import Table from "../../../theme/Table";
import { toast } from "react-toastify";
// import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import Input from "../../../theme/CustomMUI/Input";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";

import Loader from "../../../assets/images/loader.gif";

const PackageIndex = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [filter_expanded, setFilterExpanded] = useState(false);
  const [pagination, setPagination] = useState({
    total_page: null,
    page: null,
    onChange: (page) => getPackages(page),
  });
  const [data, setData] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [packets_name, setPacketsName] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [itemForDeleteId, setItemForDeleteId] = useState("");

  //Backend Bekleniyor
  const getPackages = useCallback((page) => {
    const urlPage = parseInt(
      new URLSearchParams(window.location.search).get("page")
    );
    setLoading(true);
    Axios.get(`${context.api_endpoint}/company/packets`, {
      params: {
        page: page ? page : urlPage ? urlPage : 1,
        name: packets_name ?? "",
      },
    })
      .then(({ data: { data } }) => {
        setLoading(false);
        setLoaded(true);
        setData([...data.packets.records]);
        setPagination({
          ...pagination,
          page: data.packets.page,
          total_page: data.packets.records.length,
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  });

  const deletePackage = () => {
    Axios.delete(
      `${context.api_endpoint}/company/packet/delete/${itemForDeleteId}`
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`['packages/detail/id'].deleteToast`));
          history.push("/packages/list");
          setItemForDeleteId("");
          getPackages();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    getPackages();
  }, [packets_name]);

  return (
    <AuthContainer
      makePlanUpgrade={[2]}
      authorities={[0, 1]}
      limited_permission="sales"
    >
      <Grid className="mb-2">
        <Column className={`xs-12 sm-12`}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={filter_expanded}
                onChange={async (e) => {
                  setFilterExpanded(e.target.checked);
                }}
              />
            }
            label={
              <FCLabel>
                <i className="material-icons">filter_list</i>{" "}
                <span>{context.t(`packages.filter.title`)} </span>
              </FCLabel>
            }
          />
        </Column>
        {filter_expanded && (
          <>
            <Column className="xs-12 sm-12 md-12 lg-6">
              <Input
                label={context.t(
                  `[packages/sales/add].searchPackegeInputLabel`
                )}
                placeholder={context.t(`[packages/sales/add].enterPackageName`)}
                style={{ width: "100%" }}
                fullWidth={true}
                onChange={(e) => {
                  if (typingTimeout) {
                    clearTimeout(typingTimeout);
                  }
                  setPacketsName(e.target.value);
                  setTypingTimeout(
                    setTimeout(() => {
                      getPackages();
                    }, 1500)
                  );
                }}
              />
            </Column>
          </>
        )}
      </Grid>
      <Grid>
        {loading ? (
          <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img src={Loader} width="80" height="80" alt="loading" />
          <>Paketler yükleniyor. Lütfen bekleyiniz...</>
        </div>
        ) : (
        <Column className="xs-12 sm-12">
          <Table
            headings={{
              name: {
                label: context.t(`['packages/list'].headings.name`),
              },
              all_amount: {
                label: context.t(`['packages/list'].headings.amount`),
                suffix: context.state.currency ? context.state.currency : "₺",
              },
              _: { label: context.t(`component.actionHeadingText`) },
            }}
            rows={data}
            loaded={loaded}
            pagination={pagination}
            buttons={[
              {
                title: context.t(`component.detailEditButtonTitle`),
                icon: "launch",
                textColor: "primary",
                transitionEffect: true,
                onClick: (row) => history.push(`/packages/detail/${row.id}`),
              },
              {
                title: context.t(
                  `['packages/detail/id'].deletePackageButtonTitle`
                ),
                icon: "delete_forever",
                backgroundColor: "red-opacity",
                textColor: "red",
                onClick: (row) => {
                  setDeleteModal(true);
                  setItemForDeleteId(row.id);
                },
              },
            ]}
          />
        </Column>
        )}
      </Grid>

      <AlertDialog
        title={context.t(`['packages/detail/id'].delete.title`)}
        open={deleteModal}
        closeHandler={() => setDeleteModal(false)}
        buttons={[
          {
            title: context.t(
              `['packages/detail/id'].delete.confirmButtonTitle`
            ),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            timer: 5,
            onClick: () => deletePackage(),
          },
          {
            title: context.t(
              `['packages/detail/id'].delete.discardButtonTitle`
            ),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          {context.t(`['packages/detail/id'].delete.alertText`)}
        </Alert>
      </AlertDialog>
    </AuthContainer>
  );
};

export default PackageIndex;
