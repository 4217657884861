import React from "react"; 
import TextField from "@material-ui/core/TextField"; 
import Autocomplete from "@material-ui/lab/Autocomplete"; 
 
function AutoCompleteInputWithoutEndPoint({ items, label, handlerPackageSelect, component }) { 
  const handlePackageChange = (_, __package) => { 
    component === "PackageAdd"
      ? handlerPackageSelect(__package)
      : handlerPackageSelect(_, __package);
  }; 
  return ( 
    <Autocomplete 
      id="combo-box-demo" 
      options={items} 
      getOptionLabel={(option) => option.name} 
      disableClearable
      onChange={handlePackageChange} 
      renderInput={(params) => ( 
        <TextField {...params} label={label} variant="outlined" /> 
      )} 
    /> 
  ); 
} 
 
export default AutoCompleteInputWithoutEndPoint;