import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Axios from "axios";
import Select from "../../../theme/CustomMUI/Select";
import { toast } from "react-toastify";
import Button from "../../../theme/Button";
import styled from "styled-components";
import {
  AppBar,
  Box,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
} from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import { Update } from "@material-ui/icons";
import WPSettings from "../../../views/Authenticated/Sms/WPSettings";
import Template from "../../../views/Authenticated/Sms/Send/Template";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function TabPanel(props) {
  const { children, value, index } = props;
  return value === index ? <Box p={3}>{children}</Box> : null;
}
const SmsSettings = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState(0);
  // randevudan önce gönderilecek mesajların kaç saat önce gideceği belirlenir.
  const [reminder, setReminder] = useState(0);
  // doğum günü hatırlatması
  const [reminder_birthday, setReminderBirthday] = useState(true);
  // müşteriye/çalışana randevu hatırlatması gitsin/gitmesin
  const [reminder_customer, setReminderCustomer] = useState(true);
  const [reminder_staff, setReminderStaff] = useState(true);
  // randevu tarihine 1den fazla gün var ise bir gün öncesinden mesaj gitsin/gitmesin
  const [reminder_day_ago_customer, setReminderDayAgoCustomer] = useState(true);
  const [reminder_day_ago_staff, setReminderDayAgoStaff] = useState(true);
  // randevu oluşturulduğu esnada sms gitsin/gitmesin
  const [on_appointment_customer, setOnAppointmentCustomer] = useState(true);
  const [on_appointment_staff, setOnAppointmentStaff] = useState(true);
  // dışarıdan yeni bir randevu talebi/isteği geldiğinde sms gitsin/gitmesin
  const [on_request_customer, setOnRequestCustomer] = useState(true);
  const [on_request_staff, setOnRequestStaff] = useState(true);
  // randevu isteği onaylandığında sms gitsin/gitmesin
  const [approve_appointment_customer, setApproveAppointmentCustomer] = useState(true);
  const [approve_appointment_staff, setApproveAppointmentStaff] = useState(true);
  // randevu isteği reddedildiğinde sms gitsin/gitmesin
  const [decline_appointment_customer, setDeclineAppointmentCustomer] = useState(true);
  const [decline_appointment_staff, setDeclineAppointmentStaff] = useState(true);
  // aktif bir randevu iptal edildiğinde sms gitsin /gitmesin
  const [cancel_appointment_customer, setCancelAppointmentCustomer] = useState(true);
  const [cancel_appointment_staff, setCancelAppointmentStaff] = useState(true);
  // müşteri kaydedildiğinde randevu linki gitsin/gitmesin
  const [on_customer_create, setOnCustomerCreate] = useState(true);
  // randevu sonrası gitsin/gitmesin
  const [after_appointment, setAfterAppointment] = useState(true);
  //Randevu Güncelleme Durumu
  const [update_appointment_customer, setUpdateAppointmentCustomer] = useState(true);
  const [update_appointment_staff, setUpdateAppointmentStaff] = useState(true);
  //SMS'den Randevu Katılımı İçin Link Gönderimi
  const [is_link_included_in_reminder_customer, setIsLinkIncludedInReminderCustomer] = useState(true);
  // Randevuya gelemeyen müşteriye 60. günde otomatik SMS hatırlatma
  const [reminder_noncomer_customer, setReminderNoncomerCustomer] = useState(true);
  // Randevu Sürükle Ve Bırak
  const [drag_and_drop_appointment_customer, setDragAndDropAppointmentCustomer] = useState(true);
  // Sms Kısa Kod
  const [sms_short_code, setSmsShortcode] = useState(true);
  // Online link seçeneği
  const [online_link, setOnlineLink] = useState(context.state.online_link);

  const states = {
    "selectedTab": selectedTab,
    "reminder":reminder, 
    "reminder_birthday":reminder_birthday, 
    "reminder_customer":reminder_customer,
    "reminder_staff":reminder_staff,
    "reminder_day_ago_customer":reminder_day_ago_customer,
    "reminder_day_ago_staff":reminder_day_ago_staff,
    "on_appointment_customer":on_appointment_customer,
    "on_appointment_staff":on_appointment_staff,
    "on_request_customer": on_request_customer,
    "on_request_staff":on_request_staff,
    "approve_appointment_customer":approve_appointment_customer,
    "approve_appointment_staff":approve_appointment_staff,
    "decline_appointment_customer":decline_appointment_customer,
    "decline_appointment_staff":decline_appointment_staff,
    "cancel_appointment_customer":cancel_appointment_customer,
    "cancel_appointment_staff":cancel_appointment_staff,
    "on_customer_create":on_customer_create,
    "after_appointment":after_appointment,
    "update_appointment_customer":update_appointment_customer,
    "update_appointment_staff":update_appointment_staff,
    "is_link_included_in_reminder_customer":is_link_included_in_reminder_customer,
    "reminder_noncomer_customer":reminder_noncomer_customer,
    "drag_and_drop_appointment_customer":drag_and_drop_appointment_customer,
    "sms_short_code":sms_short_code,
    "online_link": online_link,
  };

  const setStates = {
    selectedTab : setSelectedTab,
    reminder : setReminder,
    reminder_birthday : setReminderBirthday,
    reminder_customer : setReminderCustomer,
    reminder_staff : setReminderStaff,
    reminder_day_ago_customer : setReminderDayAgoCustomer,
    reminder_day_ago_staff : setReminderDayAgoStaff,
    on_appointment_customer : setOnAppointmentCustomer,
    on_appointment_staff : setOnAppointmentStaff,
    on_request_customer : setOnRequestCustomer,
    on_request_staff : setOnRequestStaff,
    approve_appointment_customer : setApproveAppointmentCustomer,
    approve_appointment_staff : setApproveAppointmentStaff,
    decline_appointment_customer : setDeclineAppointmentCustomer,
    decline_appointment_staff : setDeclineAppointmentStaff,
    cancel_appointment_customer : setCancelAppointmentCustomer,
    cancel_appointment_staff : setCancelAppointmentStaff,
    on_customer_create : setOnCustomerCreate,
    after_appointment : setAfterAppointment,
    update_appointment_customer : setUpdateAppointmentCustomer,
    update_appointment_staff : setUpdateAppointmentStaff,
    is_link_included_in_reminder_customer : setIsLinkIncludedInReminderCustomer,
    reminder_noncomer_customer : setReminderNoncomerCustomer,
    drag_and_drop_appointment_customer : setDragAndDropAppointmentCustomer,
    sms_short_code : setSmsShortcode,
    online_link : setOnlineLink,
  };

  const setState = (key, value) => {
    if (setStates[key]) {
      setStates[key](value);
    } else {
      console.warn(`No state updater found for key: ${key}`);
    }
  }

  const getDetails = () => {
    Axios.get(`${context.api_endpoint}/company/get/message/settings`).then(
      (response) => {
        const {
          data: { data },
        } = response;

        if (response.status === 200) {
          delete data.id;
          delete data.company_id;
          console.log(data);
          Object.entries(data).forEach(([key, value]) => {
            if (setStates[key]) {
              setState(key,value); // Update the specific state
            } else {
              console.warn(`No state updater found for key: ${key}`);
            }
          });
        }
      }
    );
  };

  const saveSmsSettings = () => {
    Axios.put(`${context.api_endpoint}/company/update/message/settings`, {
      ...states
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`['sms/settings'].updateToast`));
          getDetails();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(
            "Uzun süreli randevu hatırlatma 24 saat kala sms gönderimi yapıyor, hatırlatma saatiniz güncelleyiniz veya uzun süreli randevuyu kapatınız.",
            {
              autoClose: 3000,
            }
          );
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    getDetails();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const SettingsBoxBuilderWithNoPermission = ({
    children,
    boxProps,
    optionList,
  }) => {
    return (
      <Column className="xs-12 sm-12 md-4">
        <SettingsBox>
          <h3>{boxProps.title}</h3>
          {!Array.isArray(boxProps.description) ? (
            <p>{boxProps.description}</p>
          ) : (
            boxProps.description.map((descItem) => {
              return <p className="mb-0">{descItem}</p>;
            })
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                border: "1px solid rgba(255,193,0)",
                padding: "10px",
                backgroundColor: "rgba(255,193,0, 0.1)",
                borderRadius: "5px",
                color: "rgb(255,154,0)",
              }}
            >
              {context.t(`['sms/settings'].padiContent`)}
            </p>
          </div>
          {children}
        </SettingsBox>
      </Column>
    );
  };

  const SettingsBoxBuilder = ({ children, boxProps, optionList }) => {
    return (
      <Column className="xs-12 sm-12 md-4">
        <SettingsBox>
          <h3>{boxProps.title}</h3>
          {!Array.isArray(boxProps.description) ? (
            <p>{boxProps.description}</p>
          ) : (
            boxProps.description.map((descItem) => {
              return <p className="mb-0">{descItem}</p>;
            })
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
            }}
          >
            {optionList?.map((item) => (
              <FormControlLabel
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                label={<FCLabel>{item.label}</FCLabel>}
                control={
                  <Switch
                    color="primary"
                    checked={states[item.key]}
                    onChange={(e) => {
                      console.log(states[item.key]);
                      setState([item.key], !states[item.key])
                    }}
                  />
                }
              />
            ))}
          </div>
          {children}
        </SettingsBox>
      </Column>
    );
  };    

    const HOCSettingBox = SettingsBoxBuilder;
    const HOCSettingBoxWithNoPermission =
      SettingsBoxBuilderWithNoPermission;
    return (
      <AuthContainer limited_permission="sms">
        <AppBar
          style={{ marginBottom: "30px" }}
          position="sticky"
          color="default"
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              key={0}
              label={context.t(`menuItems.sms.children.settings`)}
            />
            <Tab
              key={1}
              label={context.t(`menuItems.sms.children.wpSettings`)}
            />
            <Tab
              key={2}
              label={context.t(`menuItems.sms.children.template`)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={selectedTab} index={0}>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            {" "}
            <Button
              onClick={() => {
                context.dispatch({
                  type: "LOG_TYPE",
                  payload: { log_type: 5 },
                });
                context.dispatch({
                  type: "LOG_ID",
                  payload: {
                    log_id: null,
                  },
                });
                history.push("/settings/logs");
              }}
              icon="receipt"
              title="SMS işlem kayıtlarına git "
            />
          </div>
          <Grid>
            <HOCSettingBox
              boxProps={{
                title: context.t(`['sms/settings'].reminder.title`),
                description: context.t(
                  `['sms/settings'].reminder.description`
                ),
              }}
              optionList={[
                {
                  label: context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "reminder_customer",
                },
                {
                  label: context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "reminder_staff",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: context.t(
                  `['sms/settings'].longTermReminder.title`
                ),
                description: context.t(
                  `['sms/settings'].longTermReminder.description`
                ),
              }}
              optionList={[
                {
                  label: context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "reminder_day_ago_customer",
                },
                {
                  label: context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "reminder_day_ago_staff",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: context.t(`['sms/settings'].requestInfo.title`),
                description: context.t(
                  `['sms/settings'].requestInfo.description`
                ),
              }}
              optionList={[
                {
                  label: context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "on_request_customer",
                },
                {
                  label: context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "on_request_staff",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: context.t(`['sms/settings'].whenApproved.title`),
                description: context.t(
                  `['sms/settings'].whenApproved.description`
                ),
              }}
              optionList={[
                {
                  label: context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "approve_appointment_customer",
                },
                {
                  label: context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "approve_appointment_staff",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: context.t(`['sms/settings'].whenRejected.title`),
                description: context.t(
                  `['sms/settings'].whenRejected.description`
                ),
              }}
              optionList={[
                {
                  label: context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "decline_appointment_customer",
                },
                {
                  label: context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "decline_appointment_staff",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: context.t(`['sms/settings'].whenCreated.title`),
                description: context.t(
                  `['sms/settings'].whenCreated.description`
                ),
              }}
              optionList={[
                {
                  label: context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "on_appointment_customer",
                },
                {
                  label: context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "on_appointment_staff",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: context.t(`['sms/settings'].whenCancelled.title`),
                description: context.t(
                  `['sms/settings'].whenCancelled.description`
                ),
              }}
              optionList={[
                {
                  label: context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "cancel_appointment_customer",
                },
                {
                  label: context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "cancel_appointment_staff",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: context.t(`['sms/settings'].whenBirthday.title`),
                description: context.t(
                  `['sms/settings'].whenBirthday.description`
                ),
              }}
              optionList={[
                {
                  label: context.t(`['sms/settings'].onOffSwitchTitle`),
                  key: "reminder_birthday",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: context.t(
                  `['sms/settings'].afterAppointment.title`
                ),
                description: context.t(
                  `['sms/settings'].afterAppointment.description`
                ),
              }}
              optionList={[
                {
                  label: context.t(`['sms/settings'].onOffSwitchTitle`),
                  key: "after_appointment",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: context.t(`['sms/settings'].dragAndDrop.title`),
                description: context.t(
                  `['sms/settings'].dragAndDrop.description`
                ),
              }}
              optionList={[
                {
                  label: context.t(`['sms/settings'].onOffSwitchTitle`),
                  key: "drag_and_drop_appointment_customer",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: context.t(
                  `['sms/settings'].appointmentUpdate.title`
                ),
                description: context.t(
                  `['sms/settings'].appointmentUpdate.description`
                ),
              }}
              optionList={[
                {
                  label: context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "update_appointment_customer",
                },
                {
                  label: context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "update_appointment_staff",
                },
              ]}
            />

            {(context.current_flag &
              context.state.FLAGS.SALON_RANDEVU && (
              <HOCSettingBox
                boxProps={{
                  title: context.t(
                    `['sms/settings'].reminderNoncomerCustomer.title`
                  ),
                  description: context.t(
                    `['sms/settings'].reminderNoncomerCustomer.description`
                  ),
                }}
                optionList={[
                  {
                    label: context.t(`['sms/settings'].onOffSwitchTitle`),
                    key: "reminder_noncomer_customer",
                  },
                ]}
              />
            )) ||
              null}

            {online_link ? (
              <HOCSettingBox
                boxProps={{
                  title: context.t(
                    `['sms/settings'].smsAttendedApproval.title`
                  ),
                  description: context.t(
                    `['sms/settings'].smsAttendedApproval.description`
                  ),
                }}
                optionList={[
                  {
                    label: context.t(`['sms/settings'].onOffSwitchTitle`),
                    key: "is_link_included_in_reminder_customer",
                  },
                ]}
              />
            ) : (
              <HOCSettingBoxWithNoPermission
                boxProps={{
                  title: context.t(
                    `['sms/settings'].smsAttendedApproval.title`
                  ),
                  description: context.t(
                    `['sms/settings'].smsAttendedApproval.description`
                  ),
                }}
                optionList={[
                  {
                    label: context.t(`['sms/settings'].onOffSwitchTitle`),
                    key: "is_link_included_in_reminder_customer",
                  },
                ]}
              />
            )}

            <HOCSettingBox
              boxProps={{
                title: context.t(
                  `['sms/settings'].whenCustomerCreate.title`
                ),
                description: context.t(
                  `['sms/settings'].whenCustomerCreate.description`
                ),
              }}
              optionList={[
                {
                  label: context.t(`['sms/settings'].onOffSwitchTitle`),
                  key: "on_customer_create",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: context.t(`['sms/settings'].smsShortcode.title`),
                description: context.t(
                  `['sms/settings'].smsShortcode.description`
                ),
              }}
              optionList={[
                {
                  label: context.t(`['sms/settings'].onOffSwitchTitle`),
                  key: "sms_short_code",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: context.t(`['sms/settings'].reminderHour.title`),
                description: context.t(
                  `['sms/settings'].reminderHour.descripton`
                ),
              }}
            >
              <Select
                className="mb-0"
                label={context.t(
                  `['sms/settings'].reminderHour.inputLabel`
                )}
                items={[
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[0]`
                    ),
                    value: "0",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[1]`
                    ),
                    value: "1",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[2]`
                    ),
                    value: "2",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[3]`
                    ),
                    value: "3",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[4]`
                    ),
                    value: "4",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[5]`
                    ),
                    value: "5",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[6]`
                    ),
                    value: "6",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[7]`
                    ),
                    value: "7",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[8]`
                    ),
                    value: "8",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[9]`
                    ),
                    value: "9",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[10]`
                    ),
                    value: "10",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[11]`
                    ),
                    value: "11",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[12]`
                    ),
                    value: "12",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[13]`
                    ),
                    value: "13",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[14]`
                    ),
                    value: "14",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[15]`
                    ),
                    value: "15",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[16]`
                    ),
                    value: "16",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[17]`
                    ),
                    value: "17",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[18]`
                    ),
                    value: "18",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[19]`
                    ),
                    value: "19",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[20]`
                    ),
                    value: "20",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[21]`
                    ),
                    value: "21",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[22]`
                    ),
                    value: "22",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[23]`
                    ),
                    value: "23",
                  },
                  {
                    label: context.t(
                      `['sms/settings'].reminderHour.options[24]`
                    ),
                    value: "24",
                  },
                ]}
                selected={`${reminder}`}
                labelKey="label"
                valueKey="value"
                handler={(reminder) => {
                  setReminder(parseInt(reminder));
                }}
              />
            </HOCSettingBox>

            <Column className="xs-12 sm-12">
              <Button
                iconComponent={Update}
                title={context.t(`['sms/settings'].updateButtonTitle`)}
                backgroundColor="primary"
                textColor="white"
                fullWidth
                onClick={() => saveSmsSettings()}
              />
            </Column>
          </Grid>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <WPSettings />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <Template />
        </TabPanel>
      </AuthContainer>
    );
  
}

export default SmsSettings;

const SettingsBox = styled.div`
  width: 100%;
  min-height: 147px;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;

  padding: 16px;
  margin: 0 0 16px;
  box-sizing: border-box;

  border-radius: 5px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.04);

  h3 {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    margin: 0 0 4px;
  }

  p {
    color: #808080;
    font-size: 12.6px;
    line-height: 1.614;
    margin: 0 0 8px;
  }
`;
